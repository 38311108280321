import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';

import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Alert,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  CardTitle,
  CardSubtitle,
  Spinner,
} from 'reactstrap';

import FileUploader from "react-firebase-file-uploader";

import { connect } from 'react-redux';

import {
  getCampaignsFiltered,
  updateFeaturedCampaign,
} from '../../../actions/admin';

class FeaturedCampaigns extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    fetchCampaignsFiltered: PropTypes.func.isRequired,
    changeFeaturedCampaign: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      enabled: false,
      deleteSettingModal: false,
      deleteSettingErr: null,
      settingToDelete: null,
      activeTab: '1',
      companyNotification: '',
      allChecked: true,
      checked: {},
      featuredCampaigns: [],
      featuredCampaignsIds: [],
      companyInformation: '',
    };
  }

  componentDidMount() {
    const { fetchCampaignsFiltered, user, isLoggedIn, history } = this.props;

    if (user && Object.keys(user).length > 0) {
      if (!user || !isLoggedIn || !user || !user.roleClass || user.roleClass !== 'admin') {
        history.push('/');
      } else {
        fetchCampaignsFiltered('created_at', 'descending', null, 'all', null, null).then((res) => {
          const { voucherCampaigns } = this.props;

          console.log('voucherCampaigns', voucherCampaigns);

          let featuredCampaigns = [];
          let featuredCampaignsIds = [];

          if (voucherCampaigns) {
            voucherCampaigns.map((voucher) => {
              const { featured } = voucher;

              if (featured) {
                featuredCampaigns.push({ ...voucher, key: voucher.id });
                featuredCampaignsIds.push(voucher.id);
              }
            });
          }

          console.log('featuredCampaigns', featuredCampaigns);

          this.setState({ featuredCampaigns, featuredCampaignsIds });
        });
      }
    } else {
      history.push('/');
    }
  }

  fetchPerks = () => {
    const { fetchPerks } = this.props;

    fetchPerks().then((res2) => {
      let perks = {};

      console.log('fetchPerks', res2);
      if (res2) {
        Object.keys(res2).map((key, index) => {
          perks[key] = res2[key];
          return null;
        });
      }

      this.setState({ perks });
    })
  }

  openEditPerk = (e, id) => {
    e.preventDefault();
    const { perks } = this.state;

    if (perks && perks[id]) {
      const perk = perks[id];

      console.log('perk', perk, id);

      // newName, newLink, newDescription, newSummary, newImage

      this.setState({
        editPerkModal: true,
        newName: perk.name,
        newLink: perk.link,
        newDescription: perk.description,
        newSummary: perk.summary,
        newImage: perk.image,
        editId: id,
      });
    }
  }

  goToLink = (link) => {
    const { history } = this.props;

    console.log('goToLink', link);

    window.open(link, '_blank').focus();
  }

  openChangeFeaturedCampaign = (currentFeaturedCampaignId) => {
    this.setState({ currentFeaturedCampaignId, openEditModal: true, newCampaignId: null });
  }

  closeChangeFeaturedCampaign = () => {
    this.setState({ currentFeaturedCampaignId: null, openEditModal: null, newCampaignId: null });
  }

  saveChangeFeaturedCampaign = async () => {
    const { changeFeaturedCampaign, fetchCampaignsFiltered } = this.props;
    const { currentFeaturedCampaignId, newCampaignId } = this.state;

    console.log('saveChangeFeaturedCampaign', currentFeaturedCampaignId, newCampaignId);

    if (currentFeaturedCampaignId) {
      await changeFeaturedCampaign(currentFeaturedCampaignId, null);
    }

    if (newCampaignId) {
      await changeFeaturedCampaign(newCampaignId, true);
    }
    

    fetchCampaignsFiltered('created_at', 'descending', null, 'all', null, null).then((res) => {
      const { voucherCampaigns } = this.props;

      let featuredCampaigns = [];
      let featuredCampaignsIds = [];

      if (voucherCampaigns) {
        voucherCampaigns.map((voucher) => {
          const { featured } = voucher;

          if (featured) {
            featuredCampaigns.push({ ...voucher, key: voucher.id });
            featuredCampaignsIds.push(voucher.id);
          }
        });
      }

      console.log('featuredCampaigns', featuredCampaigns);

      this.setState({ featuredCampaigns, featuredCampaignsIds, currentFeaturedCampaignId: null, openEditModal: null, newCampaignId: null });
    });

  }

  handleChangeCampaign = (e) => {
    const campaignId = e.target.value;

    if (campaignId) {
      this.setState({ newCampaignId: campaignId });
    } else {
      this.setState({ newCampaignId: null });
    }
  }

  render() {
    const { emailSettings, user, voucherCampaigns } = this.props;
    const {
      activeTab,
      error,
      newName,

      editSpinner,
      featuredCampaigns,
      featuredCampaignsIds,
      currentFeaturedCampaignId,
      openEditModal,
      newCampaignId,
    } = this.state;

    if (!user.role || user.roleClass !== 'admin') {
      return (
        <div>
          <Card>
            <CardBody>
              You need admin permissions to view Email settings
            </CardBody>
          </Card>
        </div>
      );
    }

    console.log('featuredCampaigns', featuredCampaigns, featuredCampaignsIds);

    return (
      <div>
        <TabContent activeTab="1">
          <TabPane tabId="1">
              <Row>
              <Col xs="3" style={{ height: 120, border: '1px solid #888' }}>
                  {(featuredCampaigns && featuredCampaigns[0]) ? (
                  <div onClick={() => this.openChangeFeaturedCampaign(featuredCampaigns[0].key)}>
                      <div>Name: {featuredCampaigns[0].name}</div>
                      <div>Company name: {featuredCampaigns[0].companyName}</div>
                      <div>Status: {featuredCampaigns[0].status === 1 ? 'Live' : 'Stopped'}</div>
                    </div>
                ) : (<div onClick={() => this.openChangeFeaturedCampaign(null)} style={{ width: '100%', height: '100%' }} />)}
              </Col>
              <Col xs="3" style={{ height: 120, border: '1px solid #888' }}>
                {(featuredCampaigns && featuredCampaigns[1]) ? (
                  <div onClick={() => this.openChangeFeaturedCampaign(featuredCampaigns[1].key)}>
                    <div>Name: {featuredCampaigns[1].name}</div>
                    <div>Company name: {featuredCampaigns[1].companyName}</div>
                    <div>Status: {featuredCampaigns[1].status === 1 ? 'Live' : 'Stopped'}</div>
                  </div>
                ) : (<div onClick={() => this.openChangeFeaturedCampaign(null)} style={{ width: '100%', height: '100%' }} />)}
              </Col>
              <Col xs="3" style={{ height: 120, border: '1px solid #888' }}>
                {(featuredCampaigns && featuredCampaigns[2]) ? (
                  <div onClick={() => this.openChangeFeaturedCampaign(featuredCampaigns[2].key)}>
                    <div>Name: {featuredCampaigns[2].name}</div>
                    <div>Company name: {featuredCampaigns[2].companyName}</div>
                    <div>Status: {featuredCampaigns[2].status === 1 ? 'Live' : 'Stopped'}</div>
                  </div>
                ) : (<div onClick={() => this.openChangeFeaturedCampaign(null)} style={{ width: '100%', height: '100%' }} />)}
              </Col>
              <Col xs="3" style={{ height: 120, border: '1px solid #888' }}>
                {(featuredCampaigns && featuredCampaigns[3]) ? (
                  <div onClick={() => this.openChangeFeaturedCampaign(featuredCampaigns[3].key)}>
                    <div>Name: {featuredCampaigns[3].name}</div>
                    <div>Company name: {featuredCampaigns[3].companyName}</div>
                    <div>Status: {featuredCampaigns[3].status === 1 ? 'Live' : 'Stopped'}</div>
                  </div>
                ) : (<div onClick={() => this.openChangeFeaturedCampaign(null)} style={{ width: '100%', height: '100%' }} />)}
              </Col>
              </Row>
          </TabPane>
        </TabContent>

        <Modal isOpen={openEditModal} toggle={this.closeChangeFeaturedCampaign}>
          <ModalHeader toggle={this.closeChangeFeaturedCampaign}>
            Create new perk
          </ModalHeader>
          <ModalBody>
            {(!!error) && <Alert color="danger">{error}</Alert>}
            <FormGroup>
              <Label for="companyId">
                Company
              </Label>
              <Input type="select" id="companyId" className="form-control" value={newCampaignId} onChange={(e) => this.handleChangeCampaign(e)}>
                <option value={null}>None</option>
                {voucherCampaigns.map((voucher) => {
                  if (voucher && voucher.status === 1 && !featuredCampaignsIds.includes(voucher.id)) {
                    return (
                      <option value={voucher.id}>{voucher.name}</option>
                    )
                  }
                })}
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.saveChangeFeaturedCampaign}>
              Update
              {editSpinner ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ) : (null)}
            </Button>
            <Button color="secondary" onClick={this.closeChangeFeaturedCampaign}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  emailSettings: state.notifications.emailSettings || {},
  adminsRoles: state.admin.adminsRoles || {},
  voucherCampaigns: state.admin.voucherCampaigns || [],
});

const mapDispatchToProps = {
  fetchCampaignsFiltered: getCampaignsFiltered,
  changeFeaturedCampaign: updateFeaturedCampaign,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeaturedCampaigns));
