import { combineReducers } from 'redux'
import userReducer from './user';
import adminReducer from './admin';
import usersReducer from './users';
import vouchersReducer from './vouchers';
import companyReducer from './company';
import resourceCenterReducer from './resourceCenter';
import contentManagementReducer from './contentManagement';
import notificationsReducer from './notifications';
import customerReducer from './customer';

export default combineReducers({
    user: userReducer,
    admin: adminReducer,
    users: usersReducer,
    vouchers: vouchersReducer,
    company: companyReducer,
    resourceCenter: resourceCenterReducer,
    contentManagement: contentManagementReducer,
  notifications: notificationsReducer,
  customer: customerReducer,
});
