import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Media, Button, Input, Label, Form, FormGroup, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

import { connect } from 'react-redux';

import { ImagePicker } from 'react-file-picker';

import {
  updateVoucherCategory,
  addVoucherCategory,
  editVoucherCategory,
  removeVoucherCategory,
  editRewardsUnlocked,
  saveAllVoucherCategories,
} from '../../../../actions/contentManagement';

import { storage } from '../../../../lib/firebase';

import notifications from '../../../../const/notifications';

class VoucherCategoriesTab extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    categories: PropTypes.shape(),
    rewardsUnlocked: PropTypes.shape(),
    updateCategory: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    categories: null,
    rewardsUnlocked: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      data: '',
      categories: {},
      categoriesOrdered: [],
      changeIcon: false,
    };
  }

  componentDidMount() {
    const { categories } = this.props;

    if (categories) {
      Object.keys(categories).map(key => {
        if (categories[key].icon) {
          storage
            .ref(categories[key].icon)
            .getDownloadURL()
            .then(url => {
              this.setState((prevState) => ({
                categories: {
                  ...prevState.categories,
                  [key]: { ...categories[key], url },
                },
                categoriesOrdered: [
                  ...prevState.categoriesOrdered,
                  { ...categories[key], key, url },
                ]
              }))
            });
        }
      })
    }
  }

  handleChange = (e) => {
    e.preventDefault();

    if (e.target) {
      const { name, value } = e.target;

      this.setState((prevState) => ({
        [name]: value,
        error: null,
        success: null,
      }))
    }
  };

  toggleChangeIcon = (e) => {
    e.preventDefault();

    if (e.target) {
      const { checked } = e.target;

      this.setState((prevState) => ({
        changeIcon: checked,
        error: null,
        success: null,
      }))
    }
  }

  onSelectFile = async(file) => {
    const imageSrc = file;
    const blob = await fetch(imageSrc).then((res) => res.blob());

    this.setState({
      icon: blob,
    });
  };

  updateData = () => {
    const { updateData } = this.props;
    const { data } = this.state;

    updateData(data).then((res) => {
      if (res === 'success') {
        this.setState({ success: true, error: null });
      } else if (res === 'error') {
        this.setState({ success: null, error: true });
      }
    });
  }

  toggle = (id) => {
    const { updateCategory, user } = this.props;
    const { categories } = this.state;

    const category = categories[id];

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    if (category) {
      let enabled = !category.enabled;
      updateCategory(id, enabled, adminName).then((res) => {
        if (res === 'success') {
          this.setState((prevState) => ({
            categories: {
              ...prevState.categories,
              [id]: { ...prevState.categories[id], enabled: enabled },
            }
          }))
        }
      });
    }
  }

  openAddModal = () => {
    this.setState({ addModal: true, editModal: false, deleteModal: false, name: '', icon: null, data: { name: '', icon: null } });
  }

  closeAddModal = () => {
    this.setState({ addError: null, addModal: false, data: { name: '' } });
  }

  addCategory = () => {
    const { addCategory, user } = this.props;
    const { name, icon } = this.state;

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    this.setState({ addSpinner: true });

    addCategory(name, icon, adminName).then((res) => {
      this.setState({ addSpinner: null });

      if (res && res.status === 'success') {
        if (res.id) {
          this.setState((prevState) => ({
            categories: {
              ...prevState.categories,
              [res.id]: res.data,
            }
          }))
        }
        this.setState({ success: true, error: false, addModal: false, icon: null, name: null });
      } else {
        this.setState({ success: false, addError: true, addModal: false, icon: null, name: null });
      }
    }).catch(err => {
      this.setState({ addSpinner: null });

      console.log(err);
      if (err === 'no name') {
        this.setState({ success: false, addError: notifications.admin.contentManagement.noName || 'No name' });
      } else if (err === 'no icon') {
        this.setState({ success: false, addError: notifications.admin.contentManagement.noIcon || 'No icon' });
      } else {
        this.setState({ success: false, addError: err });
      }

    });
  }

  openEditModal = (id, data) => {
    if (id === 'rewardsUnlocked') {
      this.openEditRewardsModal(data);
    } else {
      this.setState({
        editCategoryId: id,
        editModal: true,
        addModal: false,
        deleteModal: false,
        changeIcon: false,
        name: data.name,
        icon: null,
        oldIcon: data.url,
        data: { name: data.name, icon: null, oldIcon: data.icon }
      });
    }
  }

  openEditRewardsModal = (data) => {
    this.setState({
      editRewardsModal: true,
      editModal: false,
      addModal: false,
      deleteModal: false,
      changeIcon: false,
      name: data.name,
      icon: null,
      oldIcon: data.url,
      data: { name: data.name, icon: null, oldIcon: data.icon }
    });

  }

  closeEditModal = () => {
    this.setState({ addError: null, editCategoryId: null, editModal: false, data: { name: '' } });
  }

  closeEditRewardsModal = () => {
    this.setState({ addError: null, editRewardsModal: false, data: { name: '' } });
  }

  editCategory = () => {
    const { editCategory, user } = this.props;
    const { editCategoryId, name, icon, changeIcon, oldIcon } = this.state;

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    this.setState({ editSpinner: editCategoryId });

    editCategory(editCategoryId, name, icon, changeIcon, oldIcon, adminName).then((res) => {
      this.setState({ editSpinner: null });

      if (res && res.status === 'success') {
        if (res.id) {
          this.setState((prevState) => ({
            categories: {
              ...prevState.categories,
              [res.id]: res.data,
            }
          }))
        }
        this.setState({ success: true, error: false, editModal: false, icon: null, changeIcon: null, name: null });
      } else {
        this.setState({ success: false, error: true, editModal: false, icon: null, changeIcon: null, name: null });
      }
    }).catch(err => {
      this.setState({ editSpinner: null });

      console.log(err);
      if (err === 'no name') {
        this.setState({ success: false, addError: notifications.admin.contentManagement.noName || 'No name' });
      } else if (err === 'no icon') {
        this.setState({ success: false, addError: notifications.admin.contentManagement.noIcon || 'No icon' });
      } else {
        this.setState({ success: false, addError: err });
      }

    });
  }

  editRewardsUnlocked = () => {
    const { editRewardsU } = this.props;
    const { icon, oldIcon } = this.state;

    editRewardsU(icon, oldIcon).then((res) => {
      if (res && res.status === 'success') {
        this.setState({ success: true, error: false, editRewardsModal: false, icon: null, changeIcon: null, name: null });
      } else {
        this.setState({ success: false, error: true, editRewardsModal: false, icon: null, changeIcon: null, name: null });
      }
    }).catch(err => {
      console.log(err);
      if (err === 'no name') {
        this.setState({ success: false, addError: notifications.admin.contentManagement.noName || 'No name' });
      } else if (err === 'no icon') {
        this.setState({ success: false, addError: notifications.admin.contentManagement.noIcon || 'No icon' });
      } else {
        this.setState({ success: false, addError: err });
      }

    });
  }

  removeCategory = (id) => {
    const { removeCategory } = this.props;

    this.setState({ removeSpinner: id });

    removeCategory(id).then((res) => {
      let newCategories = { ...this.state.categories };

      this.setState({ removeSpinner: null });

      delete newCategories[id];

      this.setState({ categories: newCategories });
    });
  }

  handleDrag = (ev) => {
    this.setState({ dragId: parseInt(ev.currentTarget.id, 10) });
  };

  handleDrop = (ev) => {
    const { saveAllCategories } = this.props;
    const { categoriesOrdered, dragId } = this.state;

    console.log('categoriesOrdered', { categoriesOrdered });

    const dragBox = categoriesOrdered.find((box) => box.order === dragId);
    const dropBox = categoriesOrdered.find((box) => box.order === parseInt(ev.currentTarget.id, 10));

    if (dragBox && dropBox) {
      const dragBoxOrder = dragBox.order;
      const dropBoxOrder = dropBox.order;

      console.log('dragBoxOrder', { dragBoxOrder });
      console.log('dropBoxOrder', { dropBoxOrder });

      const newBoxState = categoriesOrdered.map((box) => {
        if (box.order === dragId) {
          box.order = dropBoxOrder;
        } else if (box.order === parseInt(ev.currentTarget.id, 10)) {
          box.order = dragBoxOrder;
        }
        return box;
      });

      console.log('newBoxState', { newBoxState });

      this.setState({ categoriesOrdered: newBoxState });

      saveAllCategories(newBoxState);
    }
  };

  render() {
    const { rewardsUnlocked, showNoPermission, categories } = this.props;
    const {
      error,
      addError,
      success,
      addModal,
      editModal,
      editRewardsModal,
      name,
      icon,
      changeIcon,
      oldIcon,
      categoriesOrdered,
      addSpinner,
      editSpinner,
      removeSpinner,
    } = this.state;

    console.log('categories', categories);

    return (
      <div>
        {(!!success) && <Alert color="success">{notifications.admin.contentManagement.updateSuccessful}</Alert>}
        {(!!error) && <Alert color="danger">{notifications.admin.contentManagement.updateFailed}</Alert>}

        <Row>
          <Col xs="1">
            Order
          </Col>
          <Col xs="3">
            Name
          </Col>
          <Col xs="2">
            Icon
          </Col>
          <Col>
            Toggle
          </Col>
          <Col>
            Edit
          </Col>
          <Col>
            Delete
          </Col>
        </Row>

        <Row style={{ marginBottom: 10 }}>
          <Col sm="1" />
          <Col sm="3">
            Rewards unlocked
          </Col>
          <Col sm="2">
            <Media style={{ maxHeight: 40 }} src={rewardsUnlocked ? rewardsUnlocked.url : ''} />
          </Col>
          <Col>
            <Button disabled color={'secondary'}>
              Enabled
            </Button>
          </Col>
          <Col>
            <Button disabled={showNoPermission} onClick={() => this.openEditRewardsModal('rewardsUnlocked', rewardsUnlocked)}>
              Edit
            </Button>
          </Col>
          <Col>
            <Button  disabled>
              Remove
            </Button>
          </Col>
        </Row>

        <Button disabled={showNoPermission} onClick={() => this.openAddModal()}>
          Add category
        </Button>

        {categoriesOrdered ? categoriesOrdered.sort((a, b) => a.order - b.order).map((category) => {

          if (category.name === 'Rewards unlocked') {
            return null;
          }
          return(
          <Row
            style={{ marginBottom: 10 }}
            draggable={true}
            id={category.order}
            onDragOver={(ev) => ev.preventDefault()}
            onDragStart={this.handleDrag}
            onDrop={this.handleDrop}
          >
            <Col sm="1">
              <Button style={{ padding: '0 6px 0 6px' }}>
                +
              </Button>
            </Col>
            <Col sm="3">
              {category.name}
            </Col>
            <Col sm="2">
              <Media style={{ maxHeight: 40 }} src={category.url} />
            </Col>
            <Col>
              <Button
                disabled={(showNoPermission || category.name === 'Rewards unlocked') ? true : false}
                color={(category.enabled === false || category.name === 'Rewards unlocked') ? 'secondary' : 'success'}
                onClick={() => this.toggle(category.key)}
              >
                {category.enabled === false ? (
                  'Disabled'
                ):(
                  'Enabled'
                )}
              </Button>
            </Col>
            <Col>
              <Button disabled={showNoPermission} onClick={() => this.openEditModal(category.key, category)}>
                Edit
              </Button>
            </Col>
            <Col>
              <Button  disabled={(showNoPermission || category.name === 'Rewards unlocked') ? true : false}  onClick={() => this.removeCategory(category.key)}>
                  Remove
                  {removeSpinner === category.key ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)}
              </Button>
            </Col>
          </Row>
        )}) : (null)}

        <Modal isOpen={addModal} toggle={this.closeAddModal}>
          <ModalHeader toggle={this.closeAddModal}>
            Add Category
          </ModalHeader>
          <ModalBody>
            <Form>
              {!!addError ? (<Alert color="danger"> {addError} </Alert>) : (null)}
              <FormGroup>
                <Label>Name</Label>
                <Input
                  type="text"
                  value={name}
                  name="name"
                  onChange={this.handleChange}
                />
                <Label>Icon</Label>
                <ImagePicker
                  extensions={['svg']}
                  dims={{ minWidth: 10, maxWidth: 55, minHeight: 27, maxHeight: 37 }}
                  onChange={(FileObject) => this.onSelectFile(FileObject)}
                  onError={errMsg => { this.setState({ error: errMsg }) }}
                >
                  <Button>
                    Upload Image
                  </Button>
                </ImagePicker>
                {icon && (
                  <>
                    <div> {icon.name} </div>
                    <Media src={URL.createObjectURL(icon)} />
                  </>
                )}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.addCategory}>
              Save
              {addSpinner ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ) : (null)}
            </Button>
            <Button color="secondary" onClick={this.closeAddModal}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={editModal} toggle={this.closeEditModal}>
          <ModalHeader toggle={this.closeEditModal}>
            Edit Category
          </ModalHeader>
          <ModalBody>
            <Form>
              {!!addError ? (<Alert color="danger"> {addError} </Alert>) : (null)}
              <FormGroup>
              <Row>
                <Col sm="12">
                <Label>Name</Label>
                <Input
                  type="text"
                  value={name}
                  name="name"
                  onChange={this.handleChange}
                />
                </Col>
                  <Col sm="12">
                <Label>Change Icon</Label>
                <Input
                  style={{ marginLeft: 0, marginTop: 6 }}
                  type="checkbox"
                  checked={changeIcon}
                  key={Math.random()}
                  name="changeIcon"
                  onChange={this.toggleChangeIcon}
                />
                </Col>
                <Col sm="12">
                {changeIcon ? (
                  <>
                    <Label>Icon</Label>
                    <ImagePicker
                      extensions={['svg']}
                      dims={{ minWidth: 10, maxWidth: 55, minHeight: 27, maxHeight: 37 }}
                      onChange={(FileObject) => this.onSelectFile(FileObject)}
                      onError={errMsg => { this.setState({ error: errMsg }) }}
                    >
                      <Button>
                        Upload Image
                      </Button>
                    </ImagePicker>
                    {icon && (
                      <>
                        <div> {icon.name} </div>
                        <Media src={URL.createObjectURL(icon)} />
                      </>
                    )}
                  </>
                ):(
                  <>
                    {oldIcon ? (
                      <Media  style={{ maxHeight: 40 }} src={oldIcon} />
                    ):(null)}
                  </>
                )}
                </Col>
                </Row>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.editCategory}>
              Save
              {editSpinner ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ) : (null)}
            </Button>
            <Button color="secondary" onClick={this.closeEditModal}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={editRewardsModal} toggle={this.closeEditRewardsModal}>
          <ModalHeader toggle={this.closeEditRewardsModal}>
            Edit Rewards Unlocked
          </ModalHeader>
          <ModalBody>
            <Form>
              {!!addError ? (<Alert color="danger"> {addError} </Alert>) : (null)}
              <FormGroup>
              <Row>
                  <>
                    <Label>Icon</Label>
                    <ImagePicker
                      extensions={['svg']}
                      dims={{ minWidth: 10, maxWidth: 55, minHeight: 27, maxHeight: 37 }}
                      onChange={(FileObject) => this.onSelectFile(FileObject)}
                      onError={errMsg => { this.setState({ error: errMsg }) }}
                    >
                      <Button>
                        Upload Image
                      </Button>
                    </ImagePicker>
                    {icon && (
                      <>
                        <div> {icon.name} </div>
                        <Media src={URL.createObjectURL(icon)} />
                      </>
                    )}
                  </>
                </Row>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.editRewardsUnlocked}>Save</Button>
            <Button color="secondary" onClick={this.closeEditRewardsModal}>Close</Button>
          </ModalFooter>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
});

const mapDispatchToProps = {
  updateCategory: updateVoucherCategory,
  addCategory: addVoucherCategory,
  editCategory: editVoucherCategory,
  editRewardsU: editRewardsUnlocked,
  removeCategory: removeVoucherCategory,
  saveAllCategories: saveAllVoucherCategories,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VoucherCategoriesTab));
