import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import {
  useJsonToCsv
} from 'react-json-csv';

import { Row, Col, Card, CardBody, Button, Input, Label, Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Spinner } from 'reactstrap';

import { connect } from 'react-redux';
import Moment from 'react-moment';

import CustomerModal from './CustomerModal';
import Toggle from '../../../components/Toggle/Toggle';

import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

import {
  getAppCustomersFiltered,
  getAppCustomersFilteredNextPage,
  changeCustomerStatus,
  changeCustomerTester,
  getAppCustomerData,
  deleteUser,
  sendAppVerificationEmailAsAdmin,
} from '../../../../actions/admin';

import {
  getUserData,
} from '../../../../actions/user';

class Customers extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    getUsers: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    createU: PropTypes.func.isRequired,
    changeRole: PropTypes.func.isRequired,
    deleteU: PropTypes.func.isRequired,
    fetchCompanyMembers: PropTypes.func.isRequired,
    changeStatus: PropTypes.func.isRequired,
    changeTesterStatus: PropTypes.func.isRequired,
    fetchAppCustomerData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      changeModalOpen: false,
      changeId: null,
      status: null,
      showNoPermission: false,
      dontShowPage: false,
      deleteUserModal: false,
      deleteUserErr: null,
      includeTesters: false,
      sortType: 'desc',
      sortColumn: 'dateCreated',
    };
  }

  componentDidMount() {
    const { user, isLoggedIn, history, fetchDataFiltered } = this.props;
    const { includeTesters } = this.state;

    if (user && Object.keys(user).length > 0) {
      if (!user || !isLoggedIn || !user || !user.roleClass || user.roleClass !== 'admin') {
        history.push('/');
      } else {
      //  fetchDataFiltered('customers', null, null, null);
        fetchDataFiltered('customers', 'dateCreated', 'descending', null, null, includeTesters);

        if (user.role === 'admin') {
          this.setState({ showNoPermission: false, dontShowPage: false });
        } else {
          const { adminsRoles } = this.props;

          if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.customers) {
            if (adminsRoles[user.role].permissions.customers === 2) {
              this.setState({ showNoPermission: false, dontShowPage: false });
            } else {
              this.setState({ showNoPermission: true, dontShowPage: false });
            }
          } else {
            this.setState({ showNoPermission: true, dontShowPage: true });
          }
        }
      }
    } else {
      history.push('/');
    }
  }

  handleChange = (e) => {
    if (this.state.typingTimeout) {
       clearTimeout(this.state.typingTimeout);
    }

    this.setState({
       [e.target.name]: e.target.value,
       typingTimeout: setTimeout(() => {
         this.filter();
       }, 900)
    });
  }

  toggleDeleteUser = (e, uid, role) => {
    e.stopPropagation();

    const { deleteUserModal } = this.state;
    if (deleteUserModal) {
      this.setState({ deleteUserModal: false, userToDelete: null, userToDeleteRole: null, deleteUserErr: null });
    } else {
      this.setState({ deleteUserModal: true, userToDelete: uid, userToDeleteRole: role, deleteUserErr: null });
    }
  }

  closeDeleteUser = () => {
    this.setState({ deleteUserModal: false, userToDelete: null, userToDeleteRole: null, deleteUserErr: null });
  }

  deleteUser = (e, uid) => {
    e.stopPropagation();
    e.preventDefault();
    const { deleteU } = this.props;

    deleteU(uid, 'customer').then(res => {
    }).catch(err => {
      console.log(err);
    });
  }

  approveUser = (e, uid) => {
    e.stopPropagation();
    e.preventDefault();
    const { approveUser } = this.props;

    approveUser(uid).then(res => {
    }).catch(err => {
      console.log(err);
    });
  }

  selectFilterType = (e) => {
    const type = e.target.value;

    if (type === '-') {
      this.setState({ filterType: null, filterValue: null });
    } else {
      this.setState({ filterType: type, filterValue: null });
    }
  }

  filter = () => {
    const { fetchDataFiltered } = this.props;
    const { filterValue, includeTesters } = this.state;

    fetchDataFiltered('customers', null, null, filterValue, null, includeTesters);
  }

  fetchDataFiltered = (e, orderId) => {
    e.stopPropagation();
    const { fetchDataFiltered } = this.props;
    const { sortType, sortColumn, filterValue, includeTesters } = this.state; //currentOrdering, isAscending

    let ordering = 'ascending';
    let isAscending = true;
    if (sortType === 'desc') {
      ordering = 'descending';
      isAscending = false;
    }

    if (sortColumn !== orderId) {
      fetchDataFiltered('customers', sortColumn, ordering, filterValue, null, includeTesters);
    } else {
      if (isAscending) {
        fetchDataFiltered('customers', sortColumn, ordering, filterValue, null, includeTesters);
      } else {
        fetchDataFiltered('customers', sortColumn, ordering, filterValue, null, includeTesters);
      }
    }
  }

  fetchDataFilteredNextPage = (limit) => {
    const { fetchDataFilteredNextPage } = this.props;
    const { sortType, sortColumn, filterValue } = this.state;

    let ordering = 'ascending';
    if (sortType === 'desc') {
      ordering = 'descending';
    }

    let orderId;
    if (sortColumn === 'dateCreated') {
      orderId = 'dateCreated';
    } else if (sortColumn === 'name') {
      orderId = 'nameLower';
    } else if (sortColumn === 'email') {
      orderId = 'emailLower';
    } else if (sortColumn === 'lastActiveSort') {
      orderId = 'lastActive';
    } else {
      orderId = sortColumn;
    }

    if (limit === 10) {
      this.setState({ loadMoreSpinner: 1 });
    } else if (limit === 100) {
      this.setState({ loadMoreSpinner: 2 });
    } else if (limit === 'all') {
      this.setState({ loadMoreSpinner: 3 });
    }

    fetchDataFilteredNextPage('customers', orderId, ordering, filterValue, limit).then(() => {
      this.setState({ loadMoreSpinner: null });
    })
  }

  sort = (sortColumn, sortType) => {
    const { fetchDataFiltered } = this.props;
    const { filterValue, includeTesters } = this.state;

    let ordering = 'ascending';
    if (sortType === 'desc') {
      ordering = 'descending';
    }

    let orderId;
    if (sortColumn === 'dateCreated') {
      orderId = 'dateCreated';
    } else if (sortColumn === 'name') {
      orderId = 'nameLower';
    } else if (sortColumn === 'email') {
      orderId = 'emailLower';
    } else if (sortColumn === 'lastActiveSort') {
      orderId = 'lastActive';
    } else {
      orderId = sortColumn;
    }


    fetchDataFiltered('customers', orderId, ordering, filterValue, null, includeTesters).then(() => {
      this.setState({ sortType, sortColumn });
    });
  }

  openChangeModal = (e, id, status) => {
    e.preventDefault();
    if (id) {
      this.setState({ changeModalOpen: true, changeId: id, status: status || 'approved' });
    }
  }

  closeChangeModal = () => {
    this.setState({ changeModalOpen: false, changeId: null, status: null });
  }

  changeSelectedStatus = (e) => {
    this.setState({ status: e.target.value });
  }

  changeStatus = () => {
    const { changeStatus } = this.props;
    const { changeId, status } = this.state;

    changeStatus(changeId, status).then(() => {
      this.setState({ changeModalOpen: false, changeId: null, status: null });
    });
  }

  fetchAppCustomerData = (id) => {
    const { fetchAppCustomerData } = this.props;

    fetchAppCustomerData(id, null, null, null).then((res) => {
      let companiesPurchasedCount = 0;
      let campaignsPurchasedCount = 0;

      if (res.stripeData) {
        const { stripeData } = res;

        let companiesPurchased = {};
        let campaignsPurchased = {};

        Object.keys(stripeData).map((key) => {
          const { companyId, voucherId } = stripeData[key];
          if (!companiesPurchased[companyId]) {
            companiesPurchased[companyId] = { companyId };
          }
          if (!campaignsPurchased[voucherId]) {
            campaignsPurchased[voucherId] = { voucherId };
          }
        });

         companiesPurchasedCount = Object.keys(companiesPurchased).length;
         campaignsPurchasedCount = Object.keys(campaignsPurchased).length;
      }

      if (res && res.clientData) {
        this.setState({ customerName: res.clientData.name || null, customerModalOpen: true, customerModalId: id, customerModalData: { companiesPurchasedCount, campaignsPurchasedCount, stripeData: res.stripeData || [], clientData: res.clientData || {} } });
      } else {
        this.setState({ customerName: null, customerModalOpen: true, customerModalId: id, customerModalData: { companiesPurchasedCount, campaignsPurchasedCount, stripeData: [], clientData: {} } });
      }
    });
  }

  closeCustomerModal = () => {
    this.setState({ customerModalOpen: null, customerModalId: null, customerModalData: null });
  }

  changeCustomerStatus = (uid, type, index, prevValue) => {
    const { changeStatus } = this.props;

    changeStatus(uid, prevValue, type, index).then(() => {
      this.setState({ changeModalOpen: false, changeId: null, status: null });
    });

  }

  changeCustomerTester = (uid, index, prevValue) => {
    const { changeTesterStatus, user } = this.props;

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    changeTesterStatus(uid, prevValue, index, adminName).then(() => {
      this.setState({ changeModalOpen: false, changeId: null, status: null });
    });

  }

  downloadData = async (data) => {
    const { saveAsCsv } = useJsonToCsv();

    this.setState({ downloadSpinner: true });

      const dataFiltered = [];

      const fields = {
        name: 'Name',
        email: 'Email',
        status: "Status",
        accountStatus: 'Account Status',
        buyingStatus: 'Buying Status',
        redemptionsStatus: 'Redemptions Status',
        dateCreated: 'Date Created',
        lastActive: 'last Active',
      }

      data.map((row) => {
        let dateCreated = new Date(0);
        let lastActive = new Date(0);

        if (row.dateCreated) {
          dateCreated.setUTCSeconds(row.dateCreated / 1000);
          dateCreated = dateCreated.toISOString().substring(0, 16).replace('T', ' ');
        } else {
          dateCreated = null;
        }

        if (row.lastActive && row.lastActive.seconds) {
          lastActive.setUTCSeconds(row.lastActive.seconds);
          lastActive = lastActive.toISOString().substring(0, 16).replace('T', ' ');
        } else {
          lastActive = null;
        }

        dataFiltered.push({
          name: row.name,
          email: row.email,
          status: row.closed ? ('Deleted') : (row.emailVerified ? 'Live' : 'Unverified'),
          accountStatus: !(row.status === 'denied'),
          buyingStatus: row.buyingStatus || false,
          redemptionsStatus: row.redemptionsStatus || false,
          dateCreated: dateCreated,
          lastActive: lastActive,
        });

        return null;
      })

    await saveAsCsv({ data: dataFiltered, fields, filename: 'Customers' });

    this.setState({ downloadSpinner: false });
  }

  resendVerificationEmail = (uid) => {
    const { sendVerificationEmail } = this.props;

    console.log('resendVerificationEmail', uid);

    this.setState({ resendEmailSpinner: uid });

    sendVerificationEmail(uid).then((res) => {
      console.log(res);
      this.setState({ resendEmailSpinner: null });
    }).catch(err => {
      console.log(err);
      this.setState({ resendEmailSpinner: null });
    });
  }

  toggleIncludeTesters = () => {
    const { fetchDataFiltered } = this.props;
    const { sortType, sortColumn, filterValue, includeTesters } = this.state;

    this.setState({ includeTesters: !includeTesters });

    let ordering = 'ascending';
    let isAscending = true;
    if (sortType === 'desc') {
      ordering = 'descending';
      isAscending = false;
    }

    if (isAscending) {
      fetchDataFiltered('customers', sortColumn, ordering, filterValue, null, !includeTesters);
    } else {
      fetchDataFiltered('customers', sortColumn, ordering, filterValue, null, !includeTesters);
    }
  }

  render() {
    const { users, user, fetchAppCustomerData } = this.props;
    const {
      filterValue,
      sortType,
      sortColumn,
      changeModalOpen,
      status,
      customerModalOpen,
      customerModalId,
      customerModalData,
      customerName,
      showNoPermission,
      dontShowPage,
      deleteUserModal,
      deleteUserErr,
      downloadSpinner,
      resendEmailSpinner,
      loadMoreSpinner,
      includeTesters,
     } = this.state;

    console.log('users', users, includeTesters);

    if (!user.role || user.roleClass !== 'admin') {
      return (
        <div>
          <Card>
            <CardBody>
              You need admin permissions to view all customers
            </CardBody>
          </Card>
        </div>
      );
    }

    if (dontShowPage) {
      return (
        <div>
          <Card>
            <CardBody>
              You don't have permissions to view all customers
            </CardBody>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Card>
            <CardBody>
            <Row className="body-filters">
              <Col sm="5">
                <Input
                  type="text"
                  name="filterValue"
                  id="filterValue"
                  value={filterValue}
                  onChange={this.handleChange}
                />
              </Col>
              <Col xs="6">
                <div style={{ float: 'right', paddingRight: 15 }}>
                  <span style={{ paddingRight: 7 }}>
                    {`Show testers`}
                  </span>
                  <Toggle disabled={showNoPermission} checked={includeTesters} onChange={this.toggleIncludeTesters} />
                </div>
              </Col>
            </Row>
            <div class="table-responsive">
              <Table
                style={{ fontSize: 14 }}
                sortType={sortType}
                sortColumn={sortColumn}
                data={users}
                virtualized
                minHeight={590}
                height={590}
                onSortColumn={this.sort}
                shouldUpdateScroll={false}
                rowKey='uid'
                rowHeight={46}
                rowClassName={(rowData) => {
                  return (`${`${(rowData && rowData.newUser) ? ' admin-table-new-user' : ''}`}`)
                }}
                onRowClick={(data, e) => {
                  if (e.target && e.target.type && e.target.type === 'button') {
                    console.log('ignore click');
                  } else {
                  }
                }}
              >
                <Column width={160} sortable resizable>
                  <HeaderCell>Name</HeaderCell>
                  <Cell dataKey="name">
                    {(rowData, rowIndex) => {
                      return (
                        <Button color="link" onClick={(e) => this.fetchAppCustomerData(rowData.uid)}>
                          <div>
                            {rowData.name || ''}
                          </div>
                        </Button>
                      )
                    }}
                  </Cell>
                </Column>

                <Column width={260} sortable resizable>
                  <HeaderCell>Email</HeaderCell>
                  <Cell dataKey="email" />
                </Column>

                <Column width={150} sortable resizable>
                  <HeaderCell>Date Created</HeaderCell>
                  <Cell dataKey="dateCreated">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {(rowData.dateCreated) ? (
                            <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                              {rowData.dateCreated}
                            </Moment>
                          ) : (null)}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>

                <Column width={140} sortable resizable>
                  <HeaderCell>Last active</HeaderCell>
                  <Cell dataKey="lastActiveSort">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {rowData.lastActive ? (
                            <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                              {rowData.lastActive.seconds * 1000}
                            </Moment>
                          ) : (null)}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>

                <Column width={130} sortable resizable>
                  <HeaderCell>Status</HeaderCell>
                  <Cell dataKey="closed">
                    {(rowData, rowIndex) => (
                      <div>
                        {rowData.closed ? (
                          'Deleted'
                        ) : (rowData.emailVerified ? 'Live' : 'Unverified')}
                      </div>
                    )}
                  </Cell>
                </Column>

                <Column width={120} resizable>
                  <HeaderCell>Account status</HeaderCell>
                  <Cell dataKey="button">
                    {(rowData, rowIndex) => (
                      <label class="switch">
                        <input disabled={showNoPermission} type="checkbox" checked={!(rowData.status === 'denied')} onChange={() => this.changeCustomerStatus(rowData.uid, 'account', rowIndex, !(rowData.status === 'denied'))} />
                        <span class="slider round"></span>
                      </label>
                    )}
                  </Cell>
                </Column>

                <Column width={120} resizable>
                  <HeaderCell>Buying status</HeaderCell>
                  <Cell dataKey="button">
                    {(rowData, rowIndex) => (
                      <label class="switch">
                        <input disabled={showNoPermission} type="checkbox" checked={rowData.buyingStatus} onChange={() => this.changeCustomerStatus(rowData.uid, 'buying', rowIndex, !rowData.buyingStatus)} />
                        <span class="slider round"></span>
                      </label>
                    )}
                  </Cell>
                </Column>

                <Column width={120} resizable>
                  <HeaderCell>Tester</HeaderCell>
                  <Cell dataKey="button">
                    {(rowData, rowIndex) => (
                      <label class="switch">
                        <input disabled={showNoPermission} type="checkbox" checked={rowData.tester} onChange={() => this.changeCustomerTester(rowData.uid, rowIndex, !rowData.tester)} />
                        <span class="slider round"></span>
                      </label>
                    )}
                  </Cell>
                </Column>


                <Column width={200} resizable>
                  <HeaderCell>Send verification email</HeaderCell>
                  <Cell dataKey="emailVerified">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {!rowData.emailVerified ? (
                            <Button
                              color="link"
                              disabled={showNoPermission}
                              className="basic-information-edit-button"
                              style={{ float: 'left' }}
                              onClick={(e) => this.resendVerificationEmail(rowData.uid)}
                            >
                              Send Verification email
                              {resendEmailSpinner === rowData.uid ? (
                                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                              ) : (null)}
                            </Button>
                          ) : null}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>

                {/*  <Column width={130} resizable>
                    <HeaderCell>Delete</HeaderCell>
                    <Cell dataKey="delete button">
                      {(rowData, rowIndex) => (

                        <Button color="link" style={{ paddingLeft: 15, paddingRight: 15 }} disabled={showNoPermission} onClick={(e) => this.toggleDeleteUser(e, rowData.uid, rowData.role)}>
                          -
                        </Button>
                      )}
                    </Cell>
                  </Column>  */}
                </Table>
                <Button className="admin-table-more-button" onClick={(e) => this.fetchDataFilteredNextPage(10)}>
                Get 10 more
                {loadMoreSpinner === 1 ? (
                  <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                ) : (null)}
                </Button>
                <Button className="admin-table-more-button" onClick={(e) => this.fetchDataFilteredNextPage(100)}>
                Get 100 more
                {loadMoreSpinner === 2 ? (
                  <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                ) : (null)}
                </Button>
                <Button className="admin-table-more-button" onClick={(e) => this.fetchDataFilteredNextPage('all')}>
                Get all
                {loadMoreSpinner === 3 ? (
                  <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                ) : (null)}
                </Button>
                <Button className="admin-table-more-button" color="primary" onClick={() => this.downloadData(users)}>
                Download Data
                {downloadSpinner ? (
                  <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                ) : (null)}
                </Button>
                </div>
            </CardBody>
        </Card>

        <Modal isOpen={changeModalOpen} toggle={this.closeChangeModal}>
          <ModalHeader toggle={this.closeChangeModal}>
            Are you sure you want to change this status?
          </ModalHeader>
          <ModalBody>
            <Input id="status" type="select" className="form-control" value={status} onChange={this.changeSelectedStatus}>
              <option value={'approved'}>Approved</option>
              <option value={'denied'}>Denied</option>
            </Input>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.changeStatus}>Change</Button>
            <Button color="secondary" onClick={this.closeChangeModal}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal size="xl" isOpen={customerModalOpen} toggle={this.closeCustomerModal}>
          <ModalHeader toggle={this.closeCustomerModal}>
            {customerName}
          </ModalHeader>
          <ModalBody>
            <CustomerModal fetchAppCustomerData={fetchAppCustomerData} customerModalId={customerModalId} customerModalData={customerModalData} />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.closeCustomerModal}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={deleteUserModal} toggle={this.closeDeleteUser}>
          <ModalHeader toggle={this.closeDeleteUser}>
            Are you sure?
          </ModalHeader>
          <ModalBody>
            {(!!deleteUserErr) && <Alert color="danger">{deleteUserErr}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deleteUser}>Delete User</Button>
            <Button color="secondary" onClick={this.closeDeleteUser}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  users: state.admin.appCustomers || [],
  adminsRoles: state.admin.adminsRoles || {},
});

const mapDispatchToProps = {
  deleteU: deleteUser,
  getUser: getUserData,
  fetchDataFiltered: getAppCustomersFiltered,
  fetchDataFilteredNextPage: getAppCustomersFilteredNextPage,
  changeStatus: changeCustomerStatus,
  changeTesterStatus: changeCustomerTester,
  fetchAppCustomerData: getAppCustomerData,
  sendVerificationEmail: sendAppVerificationEmailAsAdmin,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Customers));

// export default Dashboard;
