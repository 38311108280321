import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Card, CardBody, Table, Button, Input, Spinner, Alert } from 'reactstrap';

import { connect } from 'react-redux';

import {
  getCompanyInfoData,
  updateCompanyInfoData,
} from '../../../../../actions/contentManagement';

class CompanyInfo extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    getCompanyInfo: PropTypes.func.isRequired,
    updateCompanyInfo: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      tooltips: {},
    };
  }

  componentDidMount() {
    const { fetchCompanyInfo, admin, user, isLoggedIn, history } = this.props;

    fetchCompanyInfo().then((res) => {
      console.log('fetchCompanyInfo', { res });
      if (res && res.tooltips) {
        this.setState({ tooltips: res.tooltips });
      }
    });
  }

  handleChange = (e) => {
    e.preventDefault();

    console.log('target', { target: e.target });
    if (e.target) {
      const { name, value } = e.target;
      console.log(e.target.name);
      this.setState((prevState) => ({
        tooltips: {
          ...prevState.tooltips,
          [name]: {
            ...prevState.tooltips[name],
            value,
          },
        },
        error: null,
        success: null,
      }))
    }
  };

  updateTooltips = () => {
    const { updateCompanyInfo, user } = this.props;
    const { tooltips } = this.state;

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    this.setState({ saveSpinner: true });

    updateCompanyInfo(tooltips, adminName).then((res) => {
      if (res === 'success') {
        this.setState({ success: true, error: null, saveSpinner: null });
      } else if (res === 'error') {
        this.setState({ success: null, error: true, saveSpinner: null });
      }
    });
  }

  render() {
    const { user } = this.props;
    const {
      error,
      success,
      name,
      tooltips,
      saveSpinner,
    } = this.state;

    console.log('tooltips', tooltips);

    return (
      <div>
        {(!!success) && <Alert color="success">Update successful</Alert>}
        {(!!error) && <Alert color="danger">Update failed, please try again</Alert>}
        {tooltips ? Object.keys(tooltips).sort((a, b) => (tooltips[a].index - tooltips[b].index)).map((key) => (
          <Row>
            <Col sm="6">
              {tooltips[key].name}
            </Col>
            <Col sm="6">
              <Input
                type="textarea"
                name={key}
                id={`data-${key}`}
                value={tooltips[key].value}
                onChange={(e) => this.handleChange(e)}
              />
            </Col>
          </Row>
        )) : (null)}
        <Button onClick={this.updateTooltips}>
          Save
          {saveSpinner ? (
            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
          ) : (null)}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
});

const mapDispatchToProps = {
  fetchCompanyInfo: getCompanyInfoData,
  updateCompanyInfo: updateCompanyInfoData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyInfo));
