import { storage, firestore, functions, firebase, auth } from '../../lib/firebase';
import { GET_VOUCHER_CATEGORIES, GET_VOUCHER_COMPANIES, GET_VOUCHER_LIST, GET_COMPANY_VOUCHERS, SORT_COMPANY_VOUCHERS, GET_VOUCHER_SALES_DATA } from '../index';
import { DB_TABLE_NAME } from '../../const/firebase';

import moment from 'moment';
import { uuid } from '../../lib/uuid';

let unsubscribe;

/*
export const getCompanyVouchers = (companyId) => dispatch => {

    const vouchers = firestore.collection('companyVouchers').doc(companyId);
    vouchers.get().then((querySnapshot) => {
        let payload = {};

        console.log(querySnapshot.data());

        querySnapshot.forEach(function(doc) {
          payload = {...payload, [doc.id]: doc.data()}
        });

        dispatch({
          type: GET_COMPANY_VOUCHERS,
          payload
        });
    })
}; */

/* export const getCompanyVouchers = (companyId) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - getAllAdmins');
  //  const vouchersRef = firestore.collection('companyVouchers').doc(companyId);
    const vouchersRef = firestore.collection('companyData').doc(companyId).collection('vouchers');
    const snapshot = await vouchersRef.get();

    if (!snapshot.docs) {
      console.log('No such documents!');
    } else {
      const vouchers = {};

      snapshot.docs.map(doc => {
        console.log(doc.id);
        vouchers[doc.id] = doc.data();
      })

      console.log(vouchers);

      dispatch({
          type: GET_COMPANY_VOUCHERS,
          payload: vouchers,
      })

      return resolve('success');
    }
}); */

export const unsubscribeVouchers = () => dispatch => new Promise((resolve, reject) => {
    if (unsubscribe) {
      unsubscribe();
    }

    return resolve();
});

export const getCompanyVouchers = (companyId) => (dispatch) => new Promise(async (resolve, reject) => {
  const user = auth.currentUser;


  if (user) {
  //  const vouchersRef = firestore.collection('companyData').doc(companyId).collection('vouchers');

    const companyRef = firestore.collection('companies').doc(companyId);
    const vouchersRef = firestore.collection('vouchers').where('company', '==', companyRef);

  //  unsubscribe = vouchersRef.onSnapshot(docSnapshot => {

      const docSnapshot = await vouchersRef.get();

      if (!docSnapshot.docs) {
        return resolve();
      } else {
        const vouchers = {};
        let promises2 = [];

        let companyPendingChanges = null;

        const companyPendingRef = firestore.collection('companyPending').doc(companyId);

        const companyPendingSnapshot = await companyPendingRef.get();

        if (!companyPendingSnapshot.exists) {
          companyPendingChanges = null;
        } else {
          companyPendingChanges = companyPendingSnapshot.data();
        }

        console.log('companyPendingChanges', companyPendingChanges);

        docSnapshot.docs.map(async (doc) => {
          let voucherPendingChanges = null;

          const data = doc.data();

          promises2.push(new Promise(async (resolve3, reject3) => {
            if (data.hasPendingChanges) {
              let voucherPendingRef = firestore.collection('vouchersPending').doc(doc.id);

              voucherPendingRef.get().then((res2) => {
                voucherPendingChanges = res2.data();

              //  console.log('getCompanyVouchers voucherPendingChanges', { voucherPendingChanges });

                vouchers[doc.id] = { ...data, voucherPendingChanges, companyPendingChanges };

                return resolve3();
              }).catch((err2) => {
                voucherPendingChanges = null;

              //  console.log('getCompanyVouchers voucherPendingChanges', { voucherPendingChanges });

                vouchers[doc.id] = { ...data, voucherPendingChanges, companyPendingChanges };
                return resolve3();
              });
            } else {
            //  console.log('getCompanyVouchers voucherPendingChanges', { voucherPendingChanges });

              vouchers[doc.id] = { ...data, voucherPendingChanges, companyPendingChanges };

              return resolve3();
            }
          }));

        //  console.log('getCompanyVouchers voucherPendingChanges', { voucherPendingChanges });

        //  vouchers[doc.id] = { ...data, voucherPendingChanges };
        })

      //  console.log('getCompanyVouchers promises length', { length: promises2.length });

        Promise.all(promises2).then(() => {
        //  console.log('getCompanyVouchers voucherPendingChanges 2', { vouchers });

          dispatch({
              type: GET_COMPANY_VOUCHERS,
              payload: vouchers,
          });

          return resolve('success');
        }).catch(err => {
          dispatch({
              type: GET_COMPANY_VOUCHERS,
              payload: vouchers,
          });

          return resolve('success');
        })
      }
  //  }, err => {
  //    console.log(`Encountered error: ${err}`);
  //  });
  }
});




export const sortCompanyVouchers = (sortColumn, sortType) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - sortCompanyVouchers', { sortColumn, sortType });

  return resolve(dispatch({
    type: SORT_COMPANY_VOUCHERS,
    sortColumn,
    sortType,
  }));
});


export const cancelCampaignRequest = (campaignId) => ((dispatch) => new Promise(async (resolve, reject) => {

  const cancelCampaignRequestFunction = functions.httpsCallable('cancelCampaignRequest');

  cancelCampaignRequestFunction({
    campaignId,
  }).then((result) => {
    console.log('cancelCampaignRequest result', { result });

    if (result && result.data && result.data.status === 'error') {
      return reject(result.data.data);
    }

    return resolve('success');
  }).catch((err) => {
    console.log(err);
    return reject(err);
  })
}));

export const getVoucherCategories = () => dispatch => {
    const vouchers = firestore.collection('categories');
    vouchers.get().then(function(querySnapshot) {
        let payload = {};
        querySnapshot.forEach(function(doc) {
            payload = {...payload, [doc.id]: doc.data()}
         });
        dispatch({
            type: GET_VOUCHER_CATEGORIES,
            payload
        });
    })
};

export const createVoucher = (companyId, data) => ((dispatch) => new Promise(async (resolve, reject) => {
  const { availableVouchers, name, totalValue, discount, startDate, endDate, expiry, testMode } = data;

  console.log('createVoucher', { data });

  if (!availableVouchers || Object.keys(availableVouchers).length !== 3) {
    return reject('Missing availableVouchers');
  }

  if (!name) {
    return reject('Missing Name');
  }

  let totalValueVar = totalValue;

  if (!totalValue) {
    totalValueVar = 99999999
    // return reject('Missing Total value');
  }

  if (!discount || Object.keys(discount).length !== 3) {
    return reject('Missing discounts');
  }

    let availableVouchersArr = [];
    Object.keys(availableVouchers).map(key => {
      availableVouchersArr.push(availableVouchers[key]);
    })

  const companyRef = firestore.collection('companies').doc(companyId);
  const ownerRef = firestore.collection('owners').doc(companyId);


  const docSnapshot = await companyRef.get();
  const doc2Snapshot = await ownerRef.get();

  let companyStripeId;
  let ownerData;

  if (doc2Snapshot.exists) {
    ownerData = doc2Snapshot.data();

    companyStripeId = ownerData.companyStripeId;
  }

  console.log('ownerData', { ownerData });

//  console.log('companyStripeId', { companyStripeId });

  if (!docSnapshot.exists) {
    return reject('Error with company data');
  } else {
    const company = docSnapshot.data();

    const voucherFiles = [];

    const { primaryVoucherImage, voucherImages, description, category1, logo, website } = company;
    let backupPrimaryVoucherImage = null;

  //  console.log('ownerData', { company, ownerData });

  //  if (!voucherImages || !voucherImages.length) {
  //    return reject('Missing company images');
  //  }

  //  if (ownerData.liveVouchersCount && ownerData.liveVouchersCount > 3) {
  //    return reject('Camapign already has 3 active campaigns');
  //  }

    if (!primaryVoucherImage) {
      return reject('Missing primary voucher image');
    }

    if (!category1) {
      return reject('Missing company category');
    }

    if (!website) {
      return reject('Missing website');
    }

    if (!description) {
      return reject('Missing description');
    }

    if (!logo) {
      return reject('Missing logo image');
    }

  //  if (!companyStripeId) {
  //    return reject('Connect stripe account');
  //  }

    if (voucherImages) {
      voucherImages.map((image, index) => {
        voucherFiles.push({ file: image });
        if (index === 0) {
          backupPrimaryVoucherImage = image;
        }
      });
    }

  /*  setTimeout(() => {
      console.log('This will run after 1 second!')

      return resolve('success');
    }, 2000); */


  /*  const end = new Date(endDate);
  //  const startDateParsed = moment(customStartDate);

  //  const endDateParsed = moment(customEndDate);
    const validDates = [];
    for (let d = new Date(startDate); d <= end; d.setDate(d.getDate() + 1)) {
      const date = new Date(d);

      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();

      if (day < 10) {
        day = '0' + day;
      }

      if (month < 10) {
        month = '0' + month;
      }

      validDates.push(`${year}-${month}-${day}`);
    }

    console.log('validDates', { validDates }); */

    const createVoucherFunction = functions.httpsCallable('createVoucher');

    const dateParsed = Date.parse(startDate);

  //  console.log('data', { startDate2: startDate, availableVouchers: availableVouchersArr, name, totalValue, discount, startDate: dateParsed, companyId, expiry });

      createVoucherFunction({
        availableVouchers: availableVouchersArr, name, totalValue: totalValueVar, discount, startDate: dateParsed, companyId, expiry, testMode,
      }).then((result) => {
        console.log('createVoucher result', { result });

        if (result && result.data && result.data.status === 'error') {
          return reject(result.data.data);
        }

        if (result && result.data && result.data.data === 'Missing compliance data') {
          return reject('Missing compliance data');
        }

        if (result && result.data && result.data.data === 'Missing data') {
          return reject('Missing data');
        }

        return resolve('success');
      }).catch((err) => {
        console.log(err);
        return reject(err);
      })

  /*  const vouchersRef = firestore.collection('vouchers');

    const vouchersDoc = await vouchersRef.add({
      availableVouchers: availableVouchersArr,
      company: firestore.doc('companies/' + companyId),
      category: firestore.doc('categories/' + category1),
      description: description || '',
      name: name || '',
      totalValue: totalValue || null,
      discount: discount,
      image: primaryVoucherImage || backupPrimaryVoucherImage,
      images: voucherFiles || null,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      status: 1,
      isNew: true,
      isActive: false,
    //  campaignDates: validDates || null,
      startDate: startDate ? firebase.firestore.Timestamp.fromDate(startDate) : null,
    //  endDate: endDate ? firebase.firestore.Timestamp.fromDate(endDate) : null,
  });
} */

  /*  const companyVouchersRef = firestore.collection('companyData').doc(companyId).collection('vouchers').doc(vouchersDoc.id);

    await companyVouchersRef.set({
      availableVouchers: availableVouchersArr,
    //  company: `/companies/${companyId}`,
    //  category: `/categories/${category}`,
      company: firestore.doc('companies/' + companyId),
      category: firestore.doc('categories/' + category1),
      description: description || '',
      name: name || '',
      totalValue: totalValue || null,
      discount: discount,
      image: primaryVoucherImage || backupPrimaryVoucherImage,
      images: voucherFiles || null,
    //  image: imagesPaths[0].file,
    //  images: imagesPaths,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      status: 'requesting',
      isActive: true,
    }); */

  //  return resolve('success');
  }
//  } else {
//    return reject('Missing images');
//  }
}));


export const updateVoucher = (companyId, voucherId, data) => ((dispatch) => new Promise(async (resolve, reject) => {

  const { availableVouchers, name, discount } = data;

  if (!availableVouchers || Object.keys(availableVouchers).length !== 3) {
    return resolve('missing availableVouchers');
  }

  if (!discount || Object.keys(discount).length !== 3) {
    return resolve('missing discounts');
  }

    let availableVouchersArr = [];
    Object.keys(availableVouchers).map(key => {
      availableVouchersArr.push(availableVouchers[key]);
    })

    const vouchersPendingRef = firestore.collection('vouchersPending').doc(voucherId);

    await vouchersPendingRef.set({
      availableVouchers: availableVouchersArr,
      name: name || '',
      discount: discount,
    });

    return resolve('success');

}));

export const deleteVoucher = (companyId, voucherId) => ((dispatch) => new Promise(async (resolve) => {
  if (companyId && voucherId) {
  //  const companyVouchersRef = firestore.collection('companyData').doc(companyId).collection('vouchers').doc(voucherId);
  //  const vouchersRef = firestore.collection('vouchers').doc(voucherId);

  //  companyVouchersRef.delete();
  //  vouchersRef.delete();

    return resolve('success');
  }

  return resolve('missing id');
}));


export const requestCampaignStatusChange = (campaignId, status) => ((dispatch) => new Promise(async (resolve, reject) => {

  if (!campaignId) return resolve('Missing campaign id');
  if (!status) return resolve('Missing status');

  const requestCampaignStatusChangeFunction = functions.httpsCallable('requestCampaignStatusChange');

  requestCampaignStatusChangeFunction({
    campaignId,
    status,
  }).then((result) => {
    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  })
}));

export const cancelRequestStop = (campaignId) => ((dispatch) => new Promise(async (resolve, reject) => {

  if (!campaignId) return resolve('Missing campaign id');

  const cancelRequestStopFunction = functions.httpsCallable('cancelRequestStop');

  cancelRequestStopFunction({
    campaignId,
  }).then((result) => {
    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  })
}));



export const requestCampaignEdit = (campaignId, value) => ((dispatch) => new Promise(async (resolve, reject) => {
  console.log('requestCampaignEdit', { campaignId, value });

  if (!campaignId) return resolve('Missing campaign id');
  if (!value) return resolve('Missing value');

  const requestCampaignEditFunction = functions.httpsCallable('requestCampaignEdit');

  requestCampaignEditFunction({
    campaignId,
    value,
  }).then((result) => {
    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  })
}));

export const getVoucherSalesOld = (companyId, voucherId, timeFrame, customStartDate, customEndDate) => ((dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('getVoucherSales', { companyId, voucherId, timeFrame, customStartDate, customEndDate });

  let dateLength = 1;
  let startDate;
  let endDate;

  if (timeFrame === 'custom') {
    startDate = moment(customStartDate);

    endDate = moment(customEndDate);
  } else {
    if (timeFrame === '4weeks') {
      dateLength = 30;
    } else if (timeFrame === '1day') {
      dateLength = 1;
    } else if (timeFrame === '1week') {
      dateLength = 7;
    } else if (timeFrame === '2weeks') {
      dateLength = 14;
    } else if (timeFrame === '6months') {
      dateLength = 183;
    }

    startDate = moment().subtract(dateLength, 'days');

    endDate = moment().add(1, 'days');
  }

  const startDateSplit = startDate.format("DD-MM-YYYY").split("-");
  const endDateSplit = endDate.format("DD-MM-YYYY").split("-");

  const startDay = startDateSplit[0];
  const startMonth = startDateSplit[1];
  const startYear = startDateSplit[2];

  const endDay = endDateSplit[0];
  const endMonth = endDateSplit[1];
  const endYear = endDateSplit[2];

  const ordersRef = firestore.collection('ordersFinished');

  const { vouchers } = getState();

  const companyVouchers = vouchers.vouchers;

  if (companyId) {
    const allDocuments = await ordersRef
      .where('company', '==', firestore.collection('companies').doc(companyId))
      .where('paymentDate', ">=", new Date(`${startYear}/${startMonth}/${startDay}`))
      .where('paymentDate', "<", new Date(`${endYear}/${endMonth}/${parseInt(endDay)}`))
      .get();

    if (!allDocuments.docs) {
      return resolve();
    } else {
      const sales = {};

      let vouchersCount = 0;
      let vouchersValue = 0;
      let voucherTotalFee = 0;
      let vouchersUsedCount = 0;
      let vouchersByDate = {};
      let vouchersUsedByDate = {};
      let vouchersValueByDate = {};

      allDocuments.docs.map(doc => {
        const data = doc.data();
        const id = doc.id;

        console.log('data', { data });

        const { application_fee_amount, paymentDate, voucherStep, voucherId, used, voucher } = data;
        const splitPath = voucher.path.split("/");

        const splitVoucherId = splitPath[1];

        const paymentDateObj = new Date(paymentDate.seconds * 1000);

        let date = paymentDateObj.getDate();
        let month = paymentDateObj.getMonth() + 1;
        const year = paymentDateObj.getFullYear();

        if (date < 10) date = `0${date}`;
        if (month < 10) month = `0${month}`;

        let dateKey = `${date}-${month}-${year}`;

        if (timeFrame !== 'custom' && timeFrame !== '6months') {
          dateKey = `${date}-${month}`;
        }

        console.log('dateKey', dateKey);

        vouchersByDate[dateKey] = vouchersByDate[dateKey] ? (vouchersByDate[dateKey] + 1) : 1;
      //  vouchersByDate[`${date}-${month}-${year}`] = vouchersByDate[`${date}-${month}-${year}`] ? (vouchersByDate[`${date}-${month}-${year}`] + 1) : 1;

        vouchersCount = vouchersCount + 1;

        if (used) {
          vouchersUsedCount = vouchersUsedCount + 1;

          vouchersUsedByDate[dateKey] = vouchersUsedByDate[dateKey] ? (vouchersUsedByDate[dateKey] + 1) : 1;
        //  vouchersUsedByDate[`${date}-${month}-${year}`] = vouchersUsedByDate[`${date}-${month}-${year}`] ? (vouchersUsedByDate[`${date}-${month}-${year}`] + 1) : 1;
        }

      //  console.log('companyVouchers', { companyVouchers });

        if (companyVouchers && companyVouchers[splitVoucherId]) {
          if (voucherStep === 1) {
            vouchersValue = vouchersValue + companyVouchers[splitVoucherId].availableVouchers[0];

            vouchersValueByDate[dateKey] = vouchersValueByDate[dateKey] ? (vouchersValueByDate[dateKey] + companyVouchers[splitVoucherId].availableVouchers[0]) : companyVouchers[splitVoucherId].availableVouchers[0];
          //  vouchersValueByDate[`${date}-${month}-${year}`] = vouchersValueByDate[`${date}-${month}-${year}`] ? (vouchersValueByDate[`${date}-${month}-${year}`] + companyVouchers[splitVoucherId].availableVouchers[0]) : companyVouchers[splitVoucherId].availableVouchers[0];
          } else if (voucherStep === 2) {
            vouchersValue = vouchersValue + companyVouchers[splitVoucherId].availableVouchers[1];

            vouchersValueByDate[dateKey] = vouchersValueByDate[dateKey] ? (vouchersValueByDate[dateKey] + companyVouchers[splitVoucherId].availableVouchers[1]) : companyVouchers[splitVoucherId].availableVouchers[1];
          //  vouchersValueByDate[`${date}-${month}-${year}`] = vouchersValueByDate[`${date}-${month}-${year}`] ? (vouchersValueByDate[`${date}-${month}-${year}`] + companyVouchers[splitVoucherId].availableVouchers[1]) : companyVouchers[splitVoucherId].availableVouchers[1];
          } else if (voucherStep === 3) {
            vouchersValue = vouchersValue + companyVouchers[splitVoucherId].availableVouchers[2];

            vouchersValueByDate[dateKey] = vouchersValueByDate[dateKey] ? (vouchersValueByDate[dateKey] + companyVouchers[splitVoucherId].availableVouchers[2]) : companyVouchers[splitVoucherId].availableVouchers[2];
          //  vouchersValueByDate[`${date}-${month}-${year}`] = vouchersValueByDate[`${date}-${month}-${year}`] ? (vouchersValueByDate[`${date}-${month}-${year}`] + companyVouchers[splitVoucherId].availableVouchers[2]) : companyVouchers[splitVoucherId].availableVouchers[2];
          }

          if (application_fee_amount) {

            voucherTotalFee = voucherTotalFee + application_fee_amount;
          }
        }

        sales[doc.id] = doc.data();
      });

      console.log('vouchersByDate', { vouchersByDate, vouchersValueByDate, vouchersUsedByDate });

      let dates = [];

      let vouchersByDateNew = {};

      let currDate = startDate.clone().startOf("day");
      let lastDate = endDate.clone().startOf("day");

      do {
      //  const dateKey = currDate.clone().format("DD-MM-YYYY");
      //  console.log('dateKey', { dateKey });

      vouchersByDateNew[currDate.clone().format("DD-MM-YYYY")] = { vouchersCount,  }

       dates.push({
         key: currDate.clone().format("DD-MM-YYYY"),
         day: currDate.clone().toDate(),
         value: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
         vouchersCount: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
         vouchersUsed: vouchersUsedByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
         vouchersValue: vouchersValueByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
       });
      } while (currDate.add(1, "days").diff(lastDate) < -(60*60*24*1000*2));

    //  const dateKey = currDate.clone().format("DD-MM-YYYY");
    //  dates.push({ key: currDate.clone().format("DD-MM-YYYY"), day: currDate.clone().toDate(), value: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] });

      dates.push({
        key: currDate.clone().format("DD-MM-YYYY"),
        day: currDate.clone().toDate(),
        value: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
        vouchersCount: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
        vouchersUsed: vouchersUsedByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
        vouchersValue: vouchersValueByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
      });

      console.log('GET_VOUCHER_SALES_DATA call', {
        vouchersCount,
        vouchersUsedCount,
        vouchersValue,
        voucherTotalFee,
        dates,
      });

      dispatch({
          type: GET_VOUCHER_SALES_DATA,
          vouchersCount,
          vouchersUsedCount,
          vouchersValue,
          voucherTotalFee,
          vouchersByDate: dates,
      });

      return resolve();
    }
  } else if (voucherId) {
    console.log('HAS VOUCHER ID', { voucherId });

    let voucherData = {};

    if (companyVouchers[voucherId]) {
      voucherData = companyVouchers[voucherId];
    }

  //  const voucherRef = firestore.collection('vouchers').doc('TzKInvGur3uvRquk39EK');
    const voucherRef = firestore.collection('vouchers').doc(voucherId);

  //  const voucherSnapshot = await voucherRef.get();

  //  if (!voucherSnapshot.exists) {
  //    console.log('No voucher document!');
  //    return reject('Error with voucher data');
  //  } else {
  //    const voucherData = voucherSnapshot.data();

      const allDocuments = await ordersRef
        .where('voucher', '==', voucherRef)
        .where('paymentDate', ">=", new Date(`${startYear}/${startMonth}/${startDay}`))
        .where('paymentDate', "<", new Date(`${endYear}/${endMonth}/${parseInt(endDay)}`))
        .get();

      if (!allDocuments.docs) {
        return resolve();
      } else {
        const sales = {};

        let vouchersCount = 0;
        let vouchersValue = 0;
        let vouchersUsedCount = 0;
        let vouchersByDate = {};

        allDocuments.docs.map(doc => {
          const data = doc.data();

          const { paymentDate, voucherStep, used, voucher } = data;
          const splitPath = voucher.path.split("/");

          const splitVoucherId = splitPath[1];

          const paymentDateObj = new Date(paymentDate.seconds * 1000);

          let date = paymentDateObj.getDate();
          let month = paymentDateObj.getMonth() + 1;
          const year = paymentDateObj.getFullYear();

          if (date < 10) date = `0${date}`;
          if (month < 10) month = `0${month}`;

          let dateKey = `${date}-${month}-${year}`;

          if (timeFrame !== 'custom' && timeFrame !== '6months') {
            dateKey = `${date}-${month}`;
          }

          console.log('dateKey', dateKey);

          vouchersByDate[dateKey] = vouchersByDate[dateKey] ? (vouchersByDate[dateKey] + 1) : 1;
        //  vouchersByDate[`${date}-${month}-${year}`] = vouchersByDate[`${date}-${month}-${year}`] ? (vouchersByDate[`${date}-${month}-${year}`] + 1) : 1;

          vouchersCount = vouchersCount + 1;

          if (used) {
            vouchersUsedCount = vouchersUsedCount + 1;
          }

          console.log('voucherData', { voucherData });

          if (voucherStep === 1) {
            vouchersValue = vouchersValue + voucherData.availableVouchers[0];
          } else if (voucherStep === 2) {
            vouchersValue = vouchersValue + voucherData.availableVouchers[1];
          } else if (voucherStep === 3) {
            vouchersValue = vouchersValue + voucherData.availableVouchers[2];
          }

          sales[doc.id] = doc.data();
        });

        let dates = [];

        let currDate = startDate.clone().startOf("day");
        let lastDate = endDate.clone().startOf("day");

        do {
        //  const dateKey = currDate.clone().format("DD-MM-YYYY");
        //  console.log('dateKey', { dateKey });

         dates.push({ key: currDate.clone().format("DD-MM-YYYY"), day: currDate.clone().toDate(), value: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] });
       } while (currDate.add(1, "days").diff(lastDate) < -(60*60*24*1000*2));

      //  const dateKey = currDate.clone().format("DD-MM-YYYY");
        dates.push({ key: currDate.clone().format("DD-MM-YYYY"), day: currDate.clone().toDate(), value: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] });

        dispatch({
            type: GET_VOUCHER_SALES_DATA,
            vouchersCount,
            vouchersUsedCount,
            vouchersValue,
            vouchersByDate: dates,
        });

        return resolve();
      }
  //  }
  }
}))



export const getVoucherSales = (companyId, voucherId, timeFrame, customStartDate, customEndDate) => ((dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('getVoucherSales', { companyId, voucherId, timeFrame, customStartDate, customEndDate });

  let dateLength = 1;
  let startDate;
  let endDate;

  if (timeFrame === 'custom') {
    startDate = moment(customStartDate);

    endDate = moment(customEndDate).add(1, 'days');
  } else if (timeFrame === 'allTime') {

    let allTimeStartDate = customStartDate;
    let allTimeEndDate = new Date();

    startDate = moment(allTimeStartDate);
    endDate = moment(allTimeEndDate).add(1, 'days');
  } else {
    if (timeFrame === 'day') {
      dateLength = 1;
    } else if (timeFrame === 'week') {
      dateLength = 7;
    } else if (timeFrame === 'month') {
      dateLength = 30;
    } else if (timeFrame === 'year') {
      dateLength = 265;
    }

    startDate = moment().subtract(dateLength, 'days');

    endDate = moment().add(1, 'days');
  }

  console.log('startDate', startDate);

  const startDateSplit = startDate.format("DD-MM-YYYY").split("-");
  const endDateSplit = endDate.format("DD-MM-YYYY").split("-");

  let startDay = startDateSplit[0];
  let startMonth = startDateSplit[1];
  const startYear = startDateSplit[2];

  console.log('startDay', startDay, startMonth, startYear);

  const endDay = endDateSplit[0];
  const endMonth = endDateSplit[1];
  const endYear = endDateSplit[2];

//  if (startDay < 10) startDay = `0${startDay}`;
//  if (startMonth < 10) startMonth = `0${startMonth}`;

  const ordersRef = firestore.collection('ordersFinished');

  const { vouchers } = getState();

  const companyVouchers = vouchers.vouchers;

  let allDocuments;

  console.log('startDay', startDay, startMonth, startYear);

  if (companyId) {
    console.log('has companyId', companyId);
    allDocuments = await firestore.collection('ordersPerCompany').doc(companyId).collection('totals')
    //  .where(firebase.firestore.FieldPath.documentId(), ">=", `${startYear}-${startMonth}-${startDay}`)
      .get();

    if (!allDocuments.docs) {
      console.log('no allDocuments');
      return resolve();
    } else {
      console.log('has allDocuments');

      let vouchersCount = 0;
      let vouchersValue = 0;
      let voucherTotalFee = 0;
      let vouchersUsedCount = 0;
      let vouchersByDate = {};
      let vouchersUsedByDate = {};
      let vouchersValueByDate = {};

      allDocuments.docs.map(doc => {
        const data = doc.data();
        const id = doc.id;

        console.log(`ordersPerCompany data ${id}`, { data });

        const splitId = id.split("-");
        let reversedId = `${splitId[2]}-${splitId[1]}-${splitId[0]}`;

        vouchersCount = vouchersCount + (data.salesCount || 0);
        vouchersByDate[reversedId] = (data.salesCount || 0);

        vouchersValue = vouchersValue + (data.faceValue || 0);
        vouchersValueByDate[reversedId] = (data.faceValue || 0);


        vouchersUsedCount = vouchersUsedCount + (data.usedCount || 0);
        vouchersUsedByDate[reversedId] = (data.usedCount || 0);

        console.log('vouchersUsedByDate', vouchersUsedByDate);
        console.log('vouchersUsedCount', vouchersUsedCount);

        voucherTotalFee = voucherTotalFee + data.commission;
      });

      console.log('vouchersByDate', { vouchersByDate, vouchersValueByDate, vouchersUsedByDate });

      let dates = [];

      let vouchersByDateNew = {};

      console.log('startDate', startDate, endDate);

      let currDate = startDate.clone().startOf("day");
      let lastDate = endDate.clone()//.startOf("day");
      const todayDate = moment();

      console.log('lastDate', lastDate);

    //  let keyFormat = "DD-MM-YYYY";
      let keyFormat = "DD MMM";

    //  if (timeFrame !== 'custom' && timeFrame !== '6months') {
    //    keyFormat = "DD MMM";
    //  }

    //  console.log('currDate', currDate);
    //  console.log('lastDate', lastDate);

      do {
        console.log('currDate do', currDate);
        vouchersByDateNew[currDate.clone().format("DD-MM-YYYY")] = { vouchersCount }


        if (todayDate.clone().format(keyFormat) !== currDate.clone().format(keyFormat)) {
          console.log('dates push', currDate.clone().format(keyFormat));
         dates.push({
           key: currDate.clone().format(keyFormat),
           day: currDate.clone().toDate(),
           value: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
           vouchersCount: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
           vouchersUsed: vouchersUsedByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
           vouchersValue: vouchersValueByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
         });
       }

     } while (currDate.add(1, "days").diff(lastDate) < -(60*60*24*1000*2));

     console.log('dates 1', dates.length, currDate);

      console.log('GET_VOUCHER_SALES_DATA call 1', {
        vouchersCount,
        vouchersUsedCount,
        vouchersValue,
        voucherTotalFee,
        dates,
      });

      console.log('todayDate.clone().format(keyFormat)', todayDate.clone().format(keyFormat) , currDate.clone().format(keyFormat) , lastDate.clone().format(keyFormat) );

       if (todayDate.clone().format(keyFormat) !== currDate.clone().format(keyFormat) && (currDate.clone().format(keyFormat) !== lastDate.clone().format(keyFormat))) {
         console.log('dates push', currDate.clone().format(keyFormat));

         dates.push({
           key: currDate.clone().format(keyFormat),
           day: currDate.clone().toDate(),
           value: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
           vouchersCount: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
           vouchersUsed: vouchersUsedByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
           vouchersValue: vouchersValueByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
         });
       }

       console.log('lastDate todayDate', todayDate.clone().startOf("day").format("DD-MM-YYYY HH:mm:ss"), lastDate.clone().subtract(1, 'days').startOf("day").format("DD-MM-YYYY HH:mm:ss"));

      if (timeFrame !== 'custom' || (timeFrame === 'custom' && lastDate.clone().subtract(1, 'days').startOf("day").format("DD-MM-YYYY HH:mm:ss") === todayDate.clone().startOf("day").format("DD-MM-YYYY HH:mm:ss"))) {
      const todayDateSplit = todayDate.format("DD-MM-YYYY").split("-");

      const todayDay = todayDateSplit[0];
      const todayMonth = todayDateSplit[1];
      const todayYear = todayDateSplit[2];

      const todayDocuments = await ordersRef
      //  .where('company', '==', firestore.collection('companies').doc(companyId))
        .where('companyId', '==', companyId)
        .where('paymentDate', ">=", new Date(`${todayYear}/${todayMonth}/${parseInt(todayDay)}`))
        .get();

      const todayUsedDocuments = await ordersRef
       // .where('company', '==', firestore.collection('companies').doc(companyId))
        .where('companyId', '==', companyId)
        .where('usedDate', ">=", new Date(`${todayYear}/${todayMonth}/${parseInt(todayDay)}`))
        .get();

      if (!todayDocuments.docs && !todayUsedDocuments.docs) {
        console.log('has no todayDocuments');
        return resolve();
      } else {
        console.log('todayDocuments', todayDocuments.docs, companyId, new Date(`${todayYear}/${todayMonth}/${parseInt(todayDay)}`));
        console.log('todayUsedDocuments', todayUsedDocuments.docs);

        console.log('companyVouchers', companyVouchers);

      //  const month = date.toLocaleString('default', { month: 'long' });

        let date = todayDay;
        let month = todayMonth;
        const year = todayYear;

        if (todayDocuments) {
          todayDocuments.docs.map(doc => {
            const data = doc.data();
            const id = doc.id;

            console.log(`today data 1 ${id}`, { data });

            const { application_fee_amount, voucherStep, voucherId, used, voucher, faceValue } = data;

          //  const splitPath = voucher.path.split("/");
          //  const splitVoucherId = splitPath[1];

            const splitVoucherId = voucherId;

            let dateKeyLong = `${date}-${month}-${year}`;
            let dateKey = `${date}-${month}`;

          //  if (timeFrame !== 'custom' && timeFrame !== '6months') {
            //  dateKey = `${date}-${month}`;
          //  }

            console.log('dateKey', dateKey, splitVoucherId, voucherStep);

            vouchersByDate[dateKeyLong] = vouchersByDate[dateKeyLong] ? (vouchersByDate[dateKeyLong] + 1) : 1;
          //  vouchersByDate[`${date}-${month}-${year}`] = vouchersByDate[`${date}-${month}-${year}`] ? (vouchersByDate[`${date}-${month}-${year}`] + 1) : 1;

            vouchersCount = vouchersCount + 1;

          //  if (used) {
          //    vouchersUsedCount = vouchersUsedCount + 1;
          //    vouchersUsedByDate[`${date}-${month}-${year}`] = vouchersUsedByDate[`${date}-${month}-${year}`] ? (vouchersUsedByDate[`${date}-${month}-${year}`] + 1) : 1;
          //  }

            vouchersValue = vouchersValue + (faceValue || 0);
            vouchersValueByDate[dateKeyLong] = vouchersValueByDate[dateKeyLong] ? (vouchersValueByDate[dateKeyLong] + (faceValue || 0)) : (faceValue || 0);

            /* if (companyVouchers && companyVouchers[splitVoucherId]) {
              console.log('has companyVouchers[splitVoucherId]', voucherStep);
              if (parseInt(voucherStep, 10) === 0) { //voucherStep === 1
                vouchersValue = vouchersValue + companyVouchers[splitVoucherId].availableVouchers[0];
                vouchersValueByDate[dateKeyLong] = vouchersValueByDate[dateKeyLong] ? (vouchersValueByDate[dateKeyLong] + companyVouchers[splitVoucherId].availableVouchers[0]) : companyVouchers[splitVoucherId].availableVouchers[0];
              } else if (parseInt(voucherStep, 10) === 1) { //voucherStep === 2
                vouchersValue = vouchersValue + companyVouchers[splitVoucherId].availableVouchers[1];
                vouchersValueByDate[dateKeyLong] = vouchersValueByDate[dateKeyLong] ? (vouchersValueByDate[dateKeyLong] + companyVouchers[splitVoucherId].availableVouchers[1]) : companyVouchers[splitVoucherId].availableVouchers[1];
              } else if (parseInt(voucherStep, 10) === 2) { //voucherStep === 3
                vouchersValue = vouchersValue + companyVouchers[splitVoucherId].availableVouchers[2];
                vouchersValueByDate[dateKeyLong] = vouchersValueByDate[dateKeyLong] ? (vouchersValueByDate[dateKeyLong] + companyVouchers[splitVoucherId].availableVouchers[2]) : companyVouchers[splitVoucherId].availableVouchers[2];
              }
            } */

            console.log('vouchersValueByDate', vouchersValueByDate);

            if (application_fee_amount) {
              voucherTotalFee = voucherTotalFee + application_fee_amount;
            }
          });
        }

        if (todayUsedDocuments) {
          todayUsedDocuments.docs.map(doc => {
            const data = doc.data();
            const id = doc.id;

            console.log(`today used data 2 ${id}`, { data });

            const { used } = data;

        //    let date = todayDay;
          //  let month = todayMonth;
          //  const year = todayYear;

            if (used) {
              vouchersUsedCount = vouchersUsedCount + 1;

              let dateKeyLong = `${date}-${month}-${year}`;
              let dateKey = `${date}-${month}`;

            //  if (timeFrame !== 'custom' && timeFrame !== '6months') {
            //    dateKey = `${date}-${month}`;
            //  }

              console.log('dateKey', dateKey);

              vouchersUsedByDate[dateKeyLong] = vouchersUsedByDate[dateKeyLong] ? (vouchersUsedByDate[dateKeyLong] + 1) : 1;
            //  vouchersUsedByDate[`${date}-${month}-${year}`] = vouchersUsedByDate[`${date}-${month}-${year}`] ? (vouchersUsedByDate[`${date}-${month}-${year}`] + 1) : 1;
            }
          });
        }

        console.log('vouchersByDate 2', { vouchersByDate, vouchersValueByDate, vouchersUsedByDate });

        let currDate = todayDate.clone().startOf("day");

      //  let keyFormat = "DD-MM-YYYY";
        let keyFormat = "DD MMM";

      //  if (timeFrame !== 'custom' && timeFrame !== '6months') {
      //    keyFormat = "DD MMM";
      //  }

      //  console.log('push dates 3', currDate);
      //  console.log('dates push', currDate.clone().format(keyFormat));

        dates.push({
          key: currDate.clone().format(keyFormat),
          day: currDate.clone().toDate(),
          value: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
          vouchersCount: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
          vouchersUsed: vouchersUsedByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
          vouchersValue: vouchersValueByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
        });
      }
    }

      console.log('GET_VOUCHER_SALES_DATA call 2', {
        vouchersCount,
        vouchersUsedCount,
        vouchersValue,
        voucherTotalFee,
        dates,
      });

      dispatch({
          type: GET_VOUCHER_SALES_DATA,
          vouchersCount,
          vouchersUsedCount,
          vouchersValue,
          voucherTotalFee,
          vouchersByDate: dates,
      });

      return resolve();
    }
  } else if (voucherId) {
    console.log('HAS VOUCHER ID', { voucherId });

    let voucherData = {};

    if (companyVouchers[voucherId]) {
      voucherData = companyVouchers[voucherId];
    }

    const voucherRef = firestore.collection('vouchers').doc(voucherId);

    allDocuments = await firestore.collection('ordersPerCampaign').doc(voucherId).collection('totals')
    //  .where(firebase.firestore.FieldPath.documentId(), ">=", `${startYear}-${startMonth}-${startDay}`)
      .get();

    if (!allDocuments.docs) {
      console.log('no allDocuments');
      return resolve();
    } else {
      console.log('has allDocuments');

      let vouchersCount = 0;
      let vouchersValue = 0;
      let voucherTotalFee = 0;
      let vouchersUsedCount = 0;
      let vouchersByDate = {};
      let vouchersUsedByDate = {};
      let vouchersValueByDate = {};

      allDocuments.docs.map(doc => {
        const data = doc.data();
        const id = doc.id;

        console.log(`data ${id}`, { data });

        const splitId = id.split("-");
        let reversedId = `${splitId[2]}-${splitId[1]}-${splitId[0]}`;

        vouchersCount = vouchersCount + (data.salesCount || 0);
        vouchersByDate[reversedId] = (data.salesCount || 0);

        vouchersValue = vouchersValue + (data.faceValue || 0);
        vouchersValueByDate[reversedId] = (data.faceValue || 0);


        vouchersUsedCount = vouchersUsedCount + (data.usedCount || 0);
        vouchersUsedByDate[reversedId] = (data.usedCount || 0);

        voucherTotalFee = voucherTotalFee + data.commission;
      });

      console.log('vouchersByDate', { vouchersByDate, vouchersValueByDate, vouchersUsedByDate });

      let dates = [];

      let vouchersByDateNew = {};

      let currDate = startDate.clone().startOf("day");
      let lastDate = endDate.clone().startOf("day");

    //  let keyFormat = "DD-MM-YYYY";
     // let keyFormat = "DD-MM";

    //  if (timeFrame !== 'custom' && timeFrame !== '6months') {
    //    keyFormat = "DD-MM";
    //  }

      let keyFormat = "DD MMM";

      do {
      vouchersByDateNew[currDate.clone().format("DD-MM-YYYY")] = { vouchersCount,  }

       dates.push({
         key: currDate.clone().format(keyFormat),
         day: currDate.clone().toDate(),
         value: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
         vouchersCount: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
         vouchersUsed: vouchersUsedByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
         vouchersValue: vouchersValueByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
       });
      } while (currDate.add(1, "days").diff(lastDate) < -(60*60*24*1000*2));

    /*  dates.push({
        key: currDate.clone().format("DD-MM-YYYY"),
        day: currDate.clone().toDate(),
        value: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
        vouchersCount: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
        vouchersUsed: vouchersUsedByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
        vouchersValue: vouchersValueByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
      }); */

      console.log('GET_VOUCHER_SALES_DATA call 1', {
        vouchersCount,
        vouchersUsedCount,
        vouchersValue,
        voucherTotalFee,
        dates,
      });

      const todayDate = moment();

      const todayDateSplit = todayDate.format("DD-MM-YYYY").split("-");

      const todayDay = todayDateSplit[0];
      const todayMonth = todayDateSplit[1];
      const todayYear = todayDateSplit[2];

      if (todayDate.clone().format(keyFormat) !== currDate.clone().format(keyFormat) && (currDate.clone().format(keyFormat) !== lastDate.clone().format(keyFormat))) {
        console.log('dates push', currDate.clone().format(keyFormat));

        dates.push({
          key: currDate.clone().format(keyFormat),
          day: currDate.clone().toDate(),
          value: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
          vouchersCount: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
          vouchersUsed: vouchersUsedByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
          vouchersValue: vouchersValueByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
        });
      }

    //  allDocuments = await ordersRef
    //    .where('voucher', '==', voucherRef)
    //    .where('paymentDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
    //    .where('paymentDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
    //  .get();

      const todayDocuments = await ordersRef
       // .where('voucher', '==', voucherRef)
        .where('voucherId', '==', voucherId)
        .where('paymentDate', ">=", new Date(`${todayYear}/${todayMonth}/${parseInt(todayDay)}`))
      //  .where('paymentDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
        .get();

      const todayUsedDocuments = await ordersRef
       // .where('voucher', '==', voucherRef)
        .where('voucherId', '==', voucherId)
        .where('usedDate', ">=", new Date(`${todayYear}/${todayMonth}/${parseInt(todayDay)}`))
        .get();

      if (!todayDocuments.docs && !todayUsedDocuments.docs) {
        console.log('has no todayDocuments');
        return resolve();
      } else {
        console.log('todayDocuments', todayDocuments.docs);

        let date = todayDay;
        let month = todayMonth;
        const year = todayYear;

        let dateKeyLong = `${date}-${month}-${year}`;

        if (todayDocuments) {
          todayDocuments.docs.map(doc => {
            const data = doc.data();
            const id = doc.id;

            console.log(`today data 2 ${id}`, { data });

            const { application_fee_amount, voucherId, voucherStep } = data;

          //  let dateKey = `${date}-${month}-${year}`;

          //  if (timeFrame !== 'custom' && timeFrame !== '6months') {
          //    dateKey = `${date}-${month}`;
          //  }

          //  console.log('dateKey', dateKeyLong);

            vouchersByDate[dateKeyLong] = vouchersByDate[dateKeyLong] ? (vouchersByDate[dateKeyLong] + 1) : 1;
          //  vouchersByDate[`${date}-${month}-${year}`] = vouchersByDate[`${date}-${month}-${year}`] ? (vouchersByDate[`${date}-${month}-${year}`] + 1) : 1;

            vouchersCount = vouchersCount + 1;

            if (parseInt(voucherStep, 10) || parseInt(voucherStep, 10) === 0) {
              vouchersValue = vouchersValue + voucherData.availableVouchers[parseInt(voucherStep, 10)];
              vouchersValueByDate[dateKeyLong] = vouchersValueByDate[dateKeyLong] ? (vouchersValueByDate[dateKeyLong] + voucherData.availableVouchers[parseInt(voucherStep, 10)]) : voucherData.availableVouchers[parseInt(voucherStep, 10)];
            }
          /*  if (voucherId === 0) {
              vouchersValue = vouchersValue + voucherData.availableVouchers[0];
              vouchersValueByDate[dateKeyLong] = vouchersValueByDate[dateKeyLong] ? (vouchersValueByDate[dateKeyLong] + voucherData.availableVouchers[0]) : voucherData.availableVouchers[0];
            //  vouchersValueByDate[`${date}-${month}-${year}`] = vouchersValueByDate[`${date}-${month}-${year}`] ? (vouchersValueByDate[`${date}-${month}-${year}`] + voucherData.availableVouchers[0]) : voucherData.availableVouchers[0];
            } else if (voucherId === 1) {
              vouchersValue = vouchersValue + voucherData.availableVouchers[1];
              vouchersValueByDate[dateKeyLong] = vouchersValueByDate[dateKeyLong] ? (vouchersValueByDate[dateKeyLong] + voucherData.availableVouchers[1]) : voucherData.availableVouchers[1];
            //  vouchersValueByDate[`${date}-${month}-${year}`] = vouchersValueByDate[`${date}-${month}-${year}`] ? (vouchersValueByDate[`${date}-${month}-${year}`] + voucherData.availableVouchers[1]) : voucherData.availableVouchers[1];
            } else if (voucherId === 2) {
              vouchersValue = vouchersValue + voucherData.availableVouchers[2];
              vouchersValueByDate[dateKeyLong] = vouchersValueByDate[dateKeyLong] ? (vouchersValueByDate[dateKeyLong] + voucherData.availableVouchers[2]) : voucherData.availableVouchers[2];
            //  vouchersValueByDate[`${date}-${month}-${year}`] = vouchersValueByDate[`${date}-${month}-${year}`] ? (vouchersValueByDate[`${date}-${month}-${year}`] + voucherData.availableVouchers[2]) : voucherData.availableVouchers[2];
          } */

            if (application_fee_amount) {
              voucherTotalFee = voucherTotalFee + application_fee_amount;
            }
          });
        }

        if (todayUsedDocuments.docs) {
          todayUsedDocuments.docs.map(doc => {
            const data = doc.data();
            const id = doc.id;

            console.log(`today used data 2 ${id}`, { data });

            const { used } = data;

          //  let date = todayDay;
          //  let month = todayMonth;
          //  const year = todayYear;

            if (used) {
              vouchersUsedCount = vouchersUsedCount + 1;

           //   let dateKey = `${date}-${month}-${year}`;

            //  if (timeFrame !== 'custom' && timeFrame !== '6months') {
            //    dateKey = `${date}-${month}`;
            //  }

            //  console.log('dateKey', dateKey);

              vouchersUsedByDate[dateKeyLong] = vouchersUsedByDate[dateKeyLong] ? (vouchersUsedByDate[dateKeyLong] + 1) : 1;
            //  vouchersUsedByDate[`${date}-${month}-${year}`] = vouchersUsedByDate[`${date}-${month}-${year}`] ? (vouchersUsedByDate[`${date}-${month}-${year}`] + 1) : 1;
            }
          });
        }

        console.log('vouchersByDate 2', { vouchersByDate, vouchersValueByDate, vouchersUsedByDate });

        let currDate = todayDate.clone().startOf("day");

      //  let keyFormat = "DD-MM-YYYY";
      //  let keyFormat = "DD-MM";

       // if (timeFrame !== 'custom' && timeFrame !== '6months') {
       //   keyFormat = "DD-MM";
       // }

        let keyFormat = "DD MMM";

        dates.push({
          key: currDate.clone().format(keyFormat),
          day: currDate.clone().toDate(),
          value: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
          vouchersCount: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
          vouchersUsed: vouchersUsedByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
          vouchersValue: vouchersValueByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
        });
      }

      console.log('GET_VOUCHER_SALES_DATA call 2', {
        vouchersCount,
        vouchersUsedCount,
        vouchersValue,
        voucherTotalFee,
        dates,
      });

      dispatch({
          type: GET_VOUCHER_SALES_DATA,
          vouchersCount,
          vouchersUsedCount,
          vouchersValue,
          voucherTotalFee,
          vouchersByDate: dates,
      });

      return resolve();
    }
  }

  /*
  if (allDocuments) {
    if (!allDocuments.docs) {
      return resolve();
    } else {
      const sales = {};

      let vouchersCount = 0;
      let vouchersValue = 0;
      let voucherTotalFee = 0;
      let vouchersUsedCount = 0;
      let vouchersByDate = {};
      let vouchersUsedByDate = {};
      let vouchersValueByDate = {};

      allDocuments.docs.map(doc => {
        const data = doc.data();
        const id = doc.id;

        console.log('data', { data });

        const { application_fee_amount, paymentDate, voucherStep, voucherId, used, voucher } = data;
        const splitPath = voucher.path.split("/");

        const splitVoucherId = splitPath[1];

        const paymentDateObj = new Date(paymentDate.seconds * 1000);

        let date = paymentDateObj.getDate();
        let month = paymentDateObj.getMonth() + 1;
        const year = paymentDateObj.getFullYear();

        if (date < 10) date = `0${date}`;
        if (month < 10) month = `0${month}`;

        vouchersByDate[`${date}-${month}-${year}`] = vouchersByDate[`${date}-${month}-${year}`] ? (vouchersByDate[`${date}-${month}-${year}`] + 1) : 1;

        vouchersCount = vouchersCount + 1;

        if (used) {
          vouchersUsedCount = vouchersUsedCount + 1;

          vouchersUsedByDate[`${date}-${month}-${year}`] = vouchersUsedByDate[`${date}-${month}-${year}`] ? (vouchersUsedByDate[`${date}-${month}-${year}`] + 1) : 1;
        }

      //  console.log('companyVouchers', { companyVouchers });

        if (companyVouchers && companyVouchers[splitVoucherId]) {
          if (voucherStep === 1) {
            vouchersValue = vouchersValue + companyVouchers[splitVoucherId].availableVouchers[0];

            vouchersValueByDate[`${date}-${month}-${year}`] = vouchersValueByDate[`${date}-${month}-${year}`] ? (vouchersValueByDate[`${date}-${month}-${year}`] + companyVouchers[splitVoucherId].availableVouchers[0]) : companyVouchers[splitVoucherId].availableVouchers[0];
          } else if (voucherStep === 2) {
            vouchersValue = vouchersValue + companyVouchers[splitVoucherId].availableVouchers[1];

            vouchersValueByDate[`${date}-${month}-${year}`] = vouchersValueByDate[`${date}-${month}-${year}`] ? (vouchersValueByDate[`${date}-${month}-${year}`] + companyVouchers[splitVoucherId].availableVouchers[1]) : companyVouchers[splitVoucherId].availableVouchers[1];
          } else if (voucherStep === 3) {
            vouchersValue = vouchersValue + companyVouchers[splitVoucherId].availableVouchers[2];

            vouchersValueByDate[`${date}-${month}-${year}`] = vouchersValueByDate[`${date}-${month}-${year}`] ? (vouchersValueByDate[`${date}-${month}-${year}`] + companyVouchers[splitVoucherId].availableVouchers[2]) : companyVouchers[splitVoucherId].availableVouchers[2];
          }

          if (application_fee_amount) {

            voucherTotalFee = voucherTotalFee + application_fee_amount;
          }
        }

        sales[doc.id] = doc.data();
      });

      console.log('vouchersByDate', { vouchersByDate, vouchersValueByDate, vouchersUsedByDate });

      let dates = [];

      let vouchersByDateNew = {};

      let currDate = startDate.clone().startOf("day");
      let lastDate = endDate.clone().startOf("day");

      do {
      vouchersByDateNew[currDate.clone().format("DD-MM-YYYY")] = { vouchersCount,  }

       dates.push({
         key: currDate.clone().format("DD-MM-YYYY"),
         day: currDate.clone().toDate(),
         value: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
         vouchersCount: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
         vouchersUsed: vouchersUsedByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
         vouchersValue: vouchersValueByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
       });
      } while (currDate.add(1, "days").diff(lastDate) < 0);

    //  const dateKey = currDate.clone().format("DD-MM-YYYY");
    //  dates.push({ key: currDate.clone().format("DD-MM-YYYY"), day: currDate.clone().toDate(), value: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] });

      dates.push({
        key: currDate.clone().format("DD-MM-YYYY"),
        day: currDate.clone().toDate(),
        value: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
        vouchersCount: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
        vouchersUsed: vouchersUsedByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
        vouchersValue: vouchersValueByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
      });

      console.log('GET_VOUCHER_SALES_DATA call', {
        vouchersCount,
        vouchersUsedCount,
        vouchersValue,
        voucherTotalFee,
        dates,
      });

      dispatch({
          type: GET_VOUCHER_SALES_DATA,
          vouchersCount,
          vouchersUsedCount,
          vouchersValue,
          voucherTotalFee,
          vouchersByDate: dates,
      });

      return resolve();
    }
  } */
}))


/* export const getVoucherCompanies = () => dispatch => {
    const vouchers = firestore.collection(DB_TABLE_NAME.companies);
    vouchers.get().then(function(querySnapshot) {
        let payload = {};
        querySnapshot.forEach(function(doc) {
            payload = {...payload, [doc.id]: doc.data()}
        });
        dispatch({
            type: GET_VOUCHER_COMPANIES,
            payload
        });
    })
};  */
