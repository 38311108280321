import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Check, X } from 'react-feather';

import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, Form, FormGroup, Alert, Media, Spinner } from 'reactstrap';

import { connect } from 'react-redux';
import Moment from 'react-moment';

import ReactTooltip from 'react-tooltip';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

import { storage } from '../../../../lib/firebase';

import {
  updateCompanyData,
  updateCompanyLogo,
  updateCompanyPrimaryVoucherImage,
  updateCompanyVoucherImages,
  getComplianceData,
  updateComplianceData,
} from '../../../../actions/company';

import {
  getCompanyInfoData,
} from '../../../../actions/contentManagement';

import notifications from '../../../../const/notifications';

import uploadIcon from '../../../../assets/images/upload2.png';

class Company extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    categories: PropTypes.shape(),
    company: PropTypes.shape(),
    updateCompanyValue: PropTypes.func.isRequired,
    updateLogo: PropTypes.func.isRequired,
    updatePrimaryVoucherImage: PropTypes.func.isRequired,
    updateVoucherImages: PropTypes.func.isRequired,
    getCompliance: PropTypes.func.isRequired,
    updateCompliance: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    categories: {},
    company: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      companyLogo: null,
      companyPrimaryVoucherImage: null,
      existingVoucherImages: [],
      deletedExistingImages: [],
      changes: {},
      complianceData: {},
      logoSrc: null,
      logoCrop: {
        unit: 'px',
        aspect: 1 / 1,
        width: 100,
        height: 100,
      },
      primaryVoucherImageSrc: null,
      primaryVoucherImageCrop: {
        unit: 'px',
        width: 540,
        height: 270,
      },
      voucherImageSrc: null,
      voucherImageCrop: {
        unit: 'px',
        width: 540,
        height: 270,
      },
      showNoPermission: false,
      categoriesOrdered: [],
    };
  }

  componentDidMount() {
    const { user, isLoggedIn, history, adminViewAsCompany, categories, getCompliance, fetchCompanyInfoData } = this.props;

    if (adminViewAsCompany) {
      this.setState({ showNoPermission: false });

      if (categories) {
        let categoriesOrdered = [];
        Object.keys(categories).map(key => {
          categoriesOrdered.push({ ...categories[key], key });
        });

        this.setState({ categoriesOrdered });
      }

      fetchCompanyInfoData().then((res) => {
        console.log('fetchCompanyInfoData res', res);
      });

      getCompliance(adminViewAsCompany).then((res) => {
        this.setState({ complianceData: res });
      });
    } else if (user && Object.keys(user).length > 0) {

      fetchCompanyInfoData().then((res) => {
        console.log('fetchCompanyInfoData res', res);
      });

      if (!user || !isLoggedIn || !user.company) {
        history.push('/');
      } else {
        if (categories) {
          let categoriesOrdered = [];
          Object.keys(categories).map(key => {
            categoriesOrdered.push({ ...categories[key], key });
          });

          this.setState({ categoriesOrdered });
        }

        getCompliance(user.company).then((res) => {
          this.setState({ complianceData: res });
        });

        if (user.role === 'teamOwner') {
          this.setState({ showNoPermission: false });
        } else {
          const { roles } = this.props;

          if (roles && roles[user.role] && roles[user.role].permissions && roles[user.role].permissions.manageCompany) {
            this.setState({ showNoPermission: false });
          } else {
            this.setState({ showNoPermission: true });
          }
        }
      }
    } else {
      history.push('/');
    }
  }

  componentDidUpdate() {
    const { company } = this.props;
    const { companyLogo, companyPrimaryVoucherImage, existingVoucherImages, voucherImagesUpdated, logoChanged, primaryVoucherChanged, voucherimagesChanged } = this.state;

    if (company.logo && !companyLogo && companyLogo !== '') {
      const logoReference = storage.ref(company.logo);

      logoReference.getMetadata().then((metadata) => {
        console.log('metadata', metadata);

        if (metadata && metadata.name) {
          this.setState({ companyLogoName: metadata.name });
        }
      });

      logoReference.getDownloadURL().then((url) => {
        //  const name = url.match(new RegExp('%2..*%2F(.*?)\?alt', 'g'));
        //  const name2 = name[0].replace('?alt', '');
        //  const name3 = name2.replace(new RegExp('%2F', 'g'), '/');

        //  console.log('companyLogoName', name, name2, name3);

        this.setState({ companyLogo: url });
      }).catch((err) => {
        this.setState({ companyLogo: '' });
      });
    }

    if (company.primaryVoucherImage && !companyPrimaryVoucherImage && companyPrimaryVoucherImage !== '') {
      const imgReference = storage.ref(company.primaryVoucherImage);

      imgReference.getMetadata().then((metadata) => {
        console.log('metadata', metadata);

        if (metadata && metadata.name) {
          this.setState({ companyPrimaryVoucherImageName: metadata.name });
        }
      });

      imgReference.getDownloadURL().then((url) => {
        this.setState({ companyPrimaryVoucherImage: url });
      }).catch((err) => {
        console.log(err);

        this.setState({ companyPrimaryVoucherImage: '' });
      });
    }

    /* if (company.voucherImages && company.voucherImages.length && (voucherImagesUpdated || !existingVoucherImages || existingVoucherImages.length === 0)) {
      let newExistingVoucherImages = [];
      let newExistingVoucherImagesNames = [];
      let promises = [];

      company.voucherImages.map((image, index) => {
        promises.push(new Promise(resolve => {
          const imgReference = storage.ref(image);

          imgReference.getMetadata().then((metadata) => {
            console.log('metadata', metadata);

            imgReference.getDownloadURL().then((url) => {
              newExistingVoucherImages[index] = { url, path: image, name: (metadata && metadata.name) ? metadata.name : '' };
              return resolve();
            }).catch((err) => {
              console.log(err);
              return resolve();
            });
          });
        }));
      });

      console.log('newExistingVoucherImages', newExistingVoucherImages);

      Promise.all(promises).then(() => {
        if (newExistingVoucherImages && newExistingVoucherImages.length > 0) {

          this.setState({ existingVoucherImages: newExistingVoucherImages, voucherImagesUpdated: null });
        }
      });
    } */
  }

  onSelectFile = (e, type) => {
    if (e.target.files && e.target.files.length > 0) {
      let name;
      if (type === 'logo') {
        name = 'logoSrc';
      } else if (type === 'primaryVoucherImage') {
        name = 'primaryVoucherImageSrc';
      } else if (type === 'voucherImage') {
        name = 'voucherImageSrc';
      }

      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ [name]: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image, type) => {
    console.log('onImageLoaded', image, type, image.width, image.height);

    console.log('onImageLoaded src', image.src);

    const srcCopy = image.src;

    const imageType = srcCopy.split(';')[0].split('/')[1];

    console.log('imageType', imageType);

    this.setState({ imageType });

    if (type !== 'logo' && (!image || ((image && image.width < 540) || (image.height < 270)))) {
      this.imageRef = null;
      if (type === 'primaryVoucherImage') {
        this.setState((prevState) => ({
          primaryCroppedImageUrl: null,
          imageType,
          changes: {
            ...prevState.changes,
            primaryVoucherImage: '',
          },
          //  primaryVoucherImageSrc: null,
          primaryVoucherImageCrop: {
            unit: 'px',
            width: 540,
            height: 270,
          },
          error: notifications.company.basicInfo.minPrimarySize || 'Minimum image size is 540x270px',
        }));
      } else if (type === 'voucherImage') {
        this.setState((prevState) => ({
          voucherCroppedImageUrl: null,
          imageType,
          changes: {
            ...prevState.changes,
            voucherImages: prevState.existingVoucherImages || [],
          },
          voucherImageSrc: null,
          //  voucherImageCrop: null,
          voucherImageCrop: {
            unit: 'px',
            width: 540,
            height: 270,
          },
          error: notifications.company.basicInfo.minVoucherImageSize || 'Minimum image size is 540x270px',
        }));
      }
    } else if (type === 'logo' && (!image || ((image && image.width < 100) || (image.height < 100)))) {
      this.imageRef = null;
      this.setState((prevState) => ({
        logoCroppedImageUrl: null,
        imageType,
        changes: {
          ...prevState.changes,
          logo: '',
        },
        logoSrc: null,
        // logoCrop: null,
        logoCrop: {
          unit: 'px',
          aspect: 1 / 1,
          width: 100,
          height: 100,
        },
        error: notifications.company.basicInfo.minLogoSize || 'Minimum image size is 100x100px',
      }));
    } else if (!image || ((image && image.width > 4096) || (image.height > 2160))) {
      this.imageRef = null;
      if (type === 'logo') {
        this.setState((prevState) => ({
          logoCroppedImageUrl: null,
          imageType,
          changes: {
            ...prevState.changes,
            logo: '',
          },
          logoSrc: null,
          //  logoCrop: null,
          logoCrop: {
            unit: 'px',
            aspect: 1 / 1,
            width: 100,
            height: 100,
          },
          error: notifications.company.basicInfo.maxLogoSize || 'Maximum image size is 4096x2160px',
        }));
      } else if (type === 'primaryVoucherImage') {
        this.setState((prevState) => ({
          primaryCroppedImageUrl: null,
          imageType,
          changes: {
            ...prevState.changes,
            primaryVoucherImage: '',
          },
          primaryVoucherImageSrc: null,
          //  primaryVoucherImageCrop: null,
          primaryVoucherImageCrop: {
            unit: 'px',
            width: 540,
            height: 270,
          },
          error: notifications.company.basicInfo.maxPrimarySize || 'Maximum image size is 4096x2160px',
        }));
      } else if (type === 'voucherImage') {
        this.setState((prevState) => ({
          voucherCroppedImageUrl: null,
          imageType,
          changes: {
            ...prevState.changes,
            voucherImages: '',
          },
          voucherImageSrc: null,
          //  voucherImageCrop: null,
          voucherImageCrop: {
            unit: 'px',
            width: 540,
            height: 270,
          },
          error: notifications.company.basicInfo.maxVoucherImageSize || 'Maximum image size is 4096x2160px',
        }));
      }
    } else {
      this.setState({ error: null, imageType });
      this.imageRef = image;
    }
  };

  onCropComplete = (crop, name) => {
    this.makeClientCrop(crop, name);
  };

  onCropChange = (crop, name) => {
    let cropName;

    if (name === 'logo') {
      cropName = 'logoCrop';
    } else if (name === 'primaryVoucherImage') {
      cropName = 'primaryVoucherImageCrop';
    } else if (name === 'voucherImage') {
      cropName = 'voucherImageCrop';
    }

    this.setState({ [cropName]: crop });
  };

  async makeClientCrop(crop, name) {
    console.log('makeClientCrop', crop, name, this.imageRef);
    const { imageType } = this.state;

    console.log('imageType', imageType);

    if (this.imageRef && crop.width && crop.height) {
      let newCrop = { ...crop };

      //  width: 540,
      //  height: 270,
      if (name === 'logo') {
        if (crop.width < 100) {
          newCrop.width = 100;
          newCrop.x = crop.x - (100 - crop.width);
        }
      } else if (name === 'primaryVoucherImage') {
        if (crop.width < 540) {
          newCrop.width = 540;
          newCrop.x = crop.x - (540 - crop.width);
        }

        newCrop.aspect = 2;
      } else {
        if (crop.width < 540) {
          newCrop.width = 540;
          newCrop.x = crop.x - (540 - crop.width);
        }

        newCrop.aspect = 2;
      }

      if (name === 'logo') {
        if (crop.height < 100) {
          newCrop.height = 100;
          newCrop.y = crop.y - (100 - crop.height);
        }
      } else if (name === 'primaryVoucherImage') {
        if (crop.height < 270) {
          newCrop.height = 270;
          newCrop.y = crop.y - (270 - crop.height);
        }

        newCrop.aspect = 2;
      } else {
        if (crop.height < 270) {
          newCrop.height = 270;
          newCrop.y = crop.y - (270 - crop.height);
        }

        newCrop.aspect = 2;
      }

      console.log('newCrop', newCrop);

      //  newCrop.aspect = 2;

      let newFileName = `newFile.png`;

      if (imageType) {
        newFileName = `newFile.${imageType}`;
      }

      console.log('newFileName', newFileName);

      const { croppedImageUrl, blob } = await this.getCroppedImg(
        this.imageRef,
        newCrop,
        newFileName,
      );

      if (name === 'voucherImage') {
        this.setState((prevState) => ({
          voucherCroppedImageUrl: croppedImageUrl,
          currentVoucherImage: blob,
          voucherImageCrop: newCrop,
        }));
      } else if (name === 'logo') {
        this.setState((prevState) => ({
          logoCroppedImageUrl: croppedImageUrl,
          changes: {
            ...prevState.changes,
            [name]: blob,
          },
          logoCrop: newCrop,
        }));
      } else if (name === 'primaryVoucherImage') {
        this.setState((prevState) => ({
          primaryCroppedImageUrl: croppedImageUrl,
          changes: {
            ...prevState.changes,
            [name]: blob,
          },
          primaryVoucherImageCrop: newCrop,
        }));
      } else {
        this.setState((prevState) => ({
          croppedImageUrl,
          changes: {
            ...prevState.changes,
            [name]: blob,
          },
        }));
      }
    }
  }

  getCroppedImg(image, crop, fileName) {
    const { imageType } = this.state;

    console.log('imageType', imageType);

    const canvas = document.createElement('canvas');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }

        blob.name = fileName;
        blob.lastModifiedDate = new Date();

        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({ croppedImageUrl: this.fileUrl, blob });
      }, 'image/png');
    });
  }

  saveChangeLogo = (e) => {
    e.stopPropagation();
    const { updateLogo, user, adminViewAsCompany } = this.props;
    const { changes } = this.state;

    if (changes.logo && user.company) {
      this.setState({ buttonPressed: 'logo' });

      updateLogo(changes.logo, user.company).then((res) => {
        console.log('updateVoucherImages', res);
        if (res.data && res.data.type === 'pending') {
          this.setState({ success: true, successPending: true, logoChanged: true, companyLogo: null, error: null, buttonPressed: null });
        } else {
          this.setState({ success: true, successPending: false, companyLogo: null, error: null, buttonPressed: null });
        }
        //  this.setState({ success: true, error: null, buttonPressed: null })
      }).catch(err => {
        console.log('err', err);
        if (err && err.message) {
          if (err.message === 'has pending') {
            this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
          } else {
            console.log(err);
            this.setState({ success: false, error: err.message, buttonPressed: null });
          }
        } else if (err === 'has pending') {
          this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
        } else {
          this.setState({ success: false, error: err, buttonPressed: null });
        }
      });

      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          logo: null,
        },
        error: null,
      }));
    } else if (adminViewAsCompany && changes.logo) {
      this.setState({ buttonPressed: 'logo' });

      updateLogo(changes.logo, adminViewAsCompany).then((res) => {
        console.log('updateVoucherImages', res);
        if (res.data && res.data.type === 'pending') {
          this.setState({ success: true, successPending: true, logoChanged: true, companyLogo: null, error: null, buttonPressed: null });
        } else {
          this.setState({ success: true, successPending: false, companyLogo: null, error: null, buttonPressed: null });
        }
        //  this.setState({ success: true, error: null, buttonPressed: null })
      }).catch(err => {
        if (err && err.message) {
          if (err.message === 'has pending') {
            this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
          } else if (err === 'has pending') {
            this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
          } else {
            console.log(err);
            this.setState({ success: false, error: err.message, buttonPressed: null });
          }
        } else {
          this.setState({ success: false, error: err, buttonPressed: null });
        }
      });

      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          logo: null,
        },
        error: null,
      }));
    } else {
      this.setState({ success: false, error: notifications.company.basicInfo.noImage || 'No image selected' });
    }
  }

  savePrimaryVoucherImage = (e) => {
    e.stopPropagation();
    const { updatePrimaryVoucherImage, user, adminViewAsCompany } = this.props;
    const { changes } = this.state;

    if (changes.primaryVoucherImage && user.company) {
      this.setState({ buttonPressed: 'primaryVoucherImage' });

      updatePrimaryVoucherImage(changes.primaryVoucherImage, user.company).then((res) => {
        console.log('updateVoucherImages', res);
        if (res.data && res.data.type === 'pending') {
          this.setState({ success: true, successPending: true, primaryVoucherChanged: true, companyPrimaryVoucherImage: null, error: null, buttonPressed: null });
        } else {
          this.setState({ success: true, successPending: false, companyPrimaryVoucherImage: null, error: null, buttonPressed: null });
        }
        //  this.setState({ success: true, error: null, buttonPressed: null })
      }).catch(err => {
        if (err && err.message) {
          if (err.message === 'has pending') {
            this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
          } else {
            console.log(err);
            this.setState({ success: false, error: err.message, buttonPressed: null });
          }
        } else if (err === 'has pending') {
          this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
        } else {
          this.setState({ success: false, error: err, buttonPressed: null });
        }
      });

      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          primaryVoucherImage: null,
        },
        error: null,
      }));
    } else if (changes.primaryVoucherImage && adminViewAsCompany) {
      this.setState({ buttonPressed: 'primaryVoucherImage' });

      updatePrimaryVoucherImage(changes.primaryVoucherImage, adminViewAsCompany).then((res) => {
        console.log('updateVoucherImages', res);
        if (res.data && res.data.type === 'pending') {
          this.setState({ success: true, successPending: true, primaryVoucherChanged: true, companyPrimaryVoucherImage: null, error: null, buttonPressed: null });
        } else {
          this.setState({ success: true, successPending: false, error: null, companyPrimaryVoucherImage: null, buttonPressed: null });
        }
        //  this.setState({ success: true, error: null, buttonPressed: null })
      }).catch(err => {
        if (err && err.message) {
          if (err.message === 'has pending') {
            this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
          } else if (err === 'has pending') {
            this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
          } else {
            console.log(err);
            this.setState({ success: false, error: err.message, buttonPressed: null });
          }
        } else {
          this.setState({ success: false, error: err, buttonPressed: null });
        }
      });

      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          primaryVoucherImage: null,
        },
        error: null,
      }));
    } else {
      this.setState({ success: false, error: notifications.company.basicInfo.noImage || 'No image selected' });
    }
  }

  saveVoucherImage = async (e) => {
    e.stopPropagation();
    const { changes, currentVoucherImage, voucherCroppedImageUrl, voucherImageUrls, crop, imageType } = this.state;

    console.log('imageType', imageType);

    if (voucherCroppedImageUrl) {
      let newVoucherImages;
      let newVoucherUrls;
      if (changes && changes.voucherImages) {
        newVoucherImages = [...changes.voucherImages, currentVoucherImage];
        newVoucherUrls = [...voucherImageUrls, voucherCroppedImageUrl];
      } else {
        newVoucherImages = [currentVoucherImage];
        newVoucherUrls = [voucherCroppedImageUrl];
      }

      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          voucherImages: newVoucherImages,
        },
        voucherImageUrls: newVoucherUrls,
        croppedImageUrl: null,
        voucherCroppedImageUrl: null,
        currentVoucherImage: null,
        voucherImageSrc: null,
        //  voucherImageCrop: null,
        voucherImageCrop: {
          unit: 'px',
          width: 540,
          height: 270,
        },
        error: null,
      }));
    } else {
      if (this.imageRef) {
        const fullCrop = { unit: 'px', width: this.imageRef.width, height: this.imageRef.height, x: 0, y: 0 };

        let newFileName = `newFile.png`;

        if (imageType) {
          newFileName = `newFile.${imageType}`;
        }

        const { croppedImageUrl: croppedImageUrlFull, blob } = await this.getCroppedImg(
          this.imageRef,
          fullCrop,
          newFileName,
        );

        let newVoucherImages;
        let newVoucherUrls;
        if (changes && changes.voucherImages) {
          newVoucherImages = [...changes.voucherImages, blob];
          newVoucherUrls = [...voucherImageUrls, croppedImageUrlFull];
        } else {
          newVoucherImages = [blob];
          newVoucherUrls = [croppedImageUrlFull];
        }

        this.setState((prevState) => ({
          changes: {
            ...prevState.changes,
            voucherImages: newVoucherImages,
          },
          voucherImageUrls: newVoucherUrls,
          croppedImageUrl: null,
          voucherCroppedImageUrl: null,
          currentVoucherImage: null,
          voucherImageSrc: null,
          // voucherImageCrop: null,
          voucherImageCrop: {
            unit: 'px',
            width: 540,
            height: 270,
          },
          error: null,
        }));
      }
    }
  }

  deleteVoucherImage = (e, index) => {
    const { changes, voucherImageUrls } = this.state;

    if (changes && changes.voucherImages) {
      const oldVoucherImages = [...changes.voucherImages];
      const oldVoucherUrls = [...voucherImageUrls];

      if (oldVoucherUrls[index] && oldVoucherImages[index]) {
        const deletedVoucherImages = oldVoucherImages.splice(index, 1);
        const deletedVoucherUrls = oldVoucherUrls.splice(index, 1);

        if (deletedVoucherImages[0].name && deletedVoucherImages[0].size) {
          this.setState((prevState) => ({
            changes: {
              ...prevState.changes,
              voucherImages: oldVoucherImages,
            },
            voucherImageUrls: oldVoucherUrls,
          }));
        } else {
          const deletedImage = deletedVoucherImages[0];

          this.setState((prevState) => ({
            changes: {
              ...prevState.changes,
              voucherImages: oldVoucherImages,
            },
            voucherImageUrls: oldVoucherUrls,
            deletedExistingImages: [
              ...prevState.deletedExistingImages,
              deletedImage,
            ],
          }));
        }
      }
    }
  }

  updateVoucherImages = (e) => {
    e.stopPropagation();
    const { updateVoucherImages, user, adminViewAsCompany } = this.props;
    const { changes, deletedExistingImages } = this.state;

    if (changes.voucherImages && user.company) {
      this.setState({ buttonPressed: 'voucherImages' });

      updateVoucherImages(changes.voucherImages, user.company, deletedExistingImages).then((res) => {
        console.log('updateVoucherImages', res);
        if (res.data && res.data.type === 'pending') {
          this.setState({ voucherImagesUpdated: true, success: true, successPending: true, voucherimagesChanged: true, existingVoucherImages: null, error: null, buttonPressed: null });
        } else {
          this.setState({ voucherImagesUpdated: true, success: true, successPending: false, existingVoucherImages: null, error: null, buttonPressed: null });
        }
        //  this.setState({ success: true, error: null, buttonPressed: null })
      }).catch(err => {
        if (err && err.message) {
          if (err.message === 'has pending') {
            this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
          } else {
            this.setState({ success: false, error: err.message, buttonPressed: null });
          }

        } else if (err === 'has pending') {
          this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
        } else {
          this.setState({ success: false, error: err, buttonPressed: null });
        }
      });

      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          voucherImages: null,
        },
      }));
    } else if (changes.voucherImages && adminViewAsCompany) {
      this.setState({ buttonPressed: 'voucherImages' });

      updateVoucherImages(changes.voucherImages, adminViewAsCompany, deletedExistingImages).then((res) => {
        console.log('updateVoucherImages', res);
        if (res.data && res.data.type === 'pending') {
          this.setState({ voucherImagesUpdated: true, success: true, successPending: true, voucherimagesChanged: true, existingVoucherImages: null, error: null, buttonPressed: null });
        } else {
          this.setState({ voucherImagesUpdated: true, success: true, successPending: false, existingVoucherImages: null, error: null, buttonPressed: null });
        }
        //  this.setState({ success: true, error: null, buttonPressed: null })
      }).catch(err => {
        if (err && err.message) {
          if (err.message === 'has pending') {
            this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
          } else {
            this.setState({ success: false, error: err.message, buttonPressed: null });
          }
        } else if (err === 'has pending') {
          this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
        } else {
          this.setState({ success: false, error: err, buttonPressed: null });
        }
      });

      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          voucherImages: null,
        },
      }));
    } else {
      this.setState({ success: false, error: 'No images selected' });
    }
  }

  handleChange = (e) => {
    if (e.target && e.target.name) {
      const { name, value } = e.target;

      if (name) {
        if (name === 'hasDisplayName') {
          const { checked } = e.target;

          this.setState((prevState) => ({
            changes: {
              ...prevState.changes,
              [name]: checked || false,
            },
          }));
          return null;
        } else if (name === 'description' && value.length > 500) {
          return null;
        } else {

          this.setState((prevState) => ({
            changes: {
              ...prevState.changes,
              [name]: value || '',
            },
          }));
        }
      }
    }
  };

  startChange = (e, id, value, value2) => {
    e.stopPropagation();

    console.log('startChange', value, value2);

    if ((id === 'category1' || id === 'category2') && !value) {
      const { categories } = this.props;

      if (Object.keys(categories) && Object.keys(categories).length > 0) {
        const firstKey = Object.keys(categories)[0];

        this.setState((prevState) => ({
          changes: {
            ...prevState.changes,
            [id]: firstKey,
          },
          error: null,
          success: null,
        }));
      }
    } else if (id === 'logo') {
      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          primaryVoucherImage: null,
          logo: '',
          voucherImages: null,
        },
        error: null,
        success: null,
      }));
    } else if (id === 'primaryVoucherImage') {
      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          logo: null,
          primaryVoucherImage: '',
          voucherImages: null,
        },
        error: null,
        success: null,
      }));
    } else if (id === 'voucherImages') {
      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          logo: null,
          primaryVoucherImage: null,
          voucherImages: prevState.existingVoucherImages || [],
        },
        voucherImageUrls: prevState.existingVoucherImages || [],
        deletedExistingImages: [],
        error: null,
        success: null,
      }));
    } else if (id === 'hasDisplayName') {
      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          [id]: value || false,
        },
        error: null,
        success: null,
      }));
    } else if (id === 'companyNumber') {
      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          companyNumber: value || '',
          //  companyType: value2 || ''
        },
        error: null,
        success: null,
      }));
    } else {
      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          [id]: value || '',
        },
        error: null,
        success: null,
      }));
    }
  }

  isValidHttpUrl = (string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    if (string.match(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/)) {
      return url.protocol === "http:" || url.protocol === "https:";
    } else {
      return false;
    }

    //  return url.protocol === "http:" || url.protocol === "https:";
  }

  saveChange = (e, id) => {
    const { user, adminViewAsCompany } = this.props;
    e.stopPropagation();
    const { updateCompanyValue } = this.props;
    const { changes } = this.state;

    if (id === 'hasDisplayName' && !(changes[id] === true || changes[id] === false)) {
      this.setState({ success: false, error: notifications.company.basicInfo.invalidHasDisplayName || 'Invalid hasDisplayName' });

      return null;
    } else if (id === 'website' && !this.isValidHttpUrl(changes[id])) {
      if (changes[id].startsWith("http://") || changes[id].startsWith("https://")) {
        this.setState({ success: false, error: notifications.company.basicInfo.invalidWebsite || 'Invalid website format' });
      } else {
        this.setState({ success: false, error: notifications.company.basicInfo.websiteStartWith || 'Website must start with http:// or https://' });
      }


      return null;
    }

    if (id && (changes[id] || changes[id] === false || (id === 'displayName' && changes[id] === '')) && user.company) {
      console.log('buttonPressed test');
      this.setState({ buttonPressed: id });

      updateCompanyValue(id, changes[id], user.company).then((res) => {
        console.log('updateCompanyValue res', res);
        if (id === 'hasDisplayName') {
          if (res.data && res.data.type === 'pending') {
            this.setState({ success: true, successPending: true, error: null, buttonPressed: null });
          } else {
            this.setState({ success: true, successPending: false, error: null, buttonPressed: null });
          }
        } else {
          if (res.data && res.data.type === 'pending') {
            this.setState({ success: true, successPending: true, error: null, buttonPressed: null });
          } else {
            this.setState({ success: true, successPending: false, error: null, buttonPressed: null });
          }
        }
      }).catch(err => {
        console.log('err', err);
        if (err && err.message) {
          if (err.message === 'has pending') {
            this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
          } else {
            this.setState({ success: false, error: err.message, buttonPressed: null });
          }
        } else {
          if (err === 'has pending') {
            this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
          } else {
            this.setState({ success: false, error: notifications.company.basicInfo.errorUpdating || 'Error updating data', buttonPressed: null });
          }
        }
      });

      console.log('reset changes', id);
      if (id === 'companyNumber') {
        this.setState((prevState) => ({
          changes: {
            ...prevState.changes,
            [id]: null,
            companyType: null,
          },
        }));
      } else {
        this.setState((prevState) => ({
          changes: {
            ...prevState.changes,
            [id]: null,
          },
        }));
      }
    } else if (adminViewAsCompany) {
      console.log('buttonPressed test');
      this.setState({ buttonPressed: id });

      updateCompanyValue(id, changes[id], adminViewAsCompany).then(() => this.setState({ success: true, error: null, buttonPressed: null })).catch(err => {
        if (err && err.message) {
          if (err.message === 'has pending') {
            this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
          } else {
            this.setState({ success: false, error: err.message, buttonPressed: null });
          }
        } else {
          this.setState({ success: false, error: err, buttonPressed: null });
        }
      });

      console.log('reset changes', id);
      if (id === 'companyNumber') {
        this.setState((prevState) => ({
          changes: {
            ...prevState.changes,
            [id]: null,
            companyType: null,
          },
        }));
      } else {
        this.setState((prevState) => ({
          changes: {
            ...prevState.changes,
            [id]: null,
          },
        }));
      }
    }
  }

  saveChangeDisplayName = (e) => {
    const { user, adminViewAsCompany, company } = this.props;
    e.stopPropagation();
    const { updateCompanyValue } = this.props;

  //  const val = e.target.value;
    const id = e.target.id;

    let val = false;

    if (id === 'hasDisplayNameYes') {
      val = true;
    }

    console.log('saveChangeDisplayName', val, id);

    if (company.hasDisplayName === val) {
      return null;
    }

    if (user.company) {
      this.setState({ buttonPressed: id });
      updateCompanyValue('hasDisplayName', val, user.company).then((res) => {
        console.log('updateCompanyValue res', res);
        if (res.data && res.data.type === 'pending') {
          this.setState({ success: true, successPending: true, error: null, buttonPressed: null });
        } else {
          this.setState({ success: false, successPending: false, error: null, buttonPressed: null });
        }
      }).catch(err => {
        console.log('updateCompanyValue err', err);
        if (err && err.message) {
          if (err.message === 'has pending') {
            this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
          } else {
            this.setState({ success: false, error: err.message, buttonPressed: null });
          }
        } else if (err === 'has pending') {
          this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
        } else {
          this.setState({ success: false, error: err, buttonPressed: null });
        }
      });

      /* updateCompanyValue('hasDisplayName', !company.hasDisplayName, user.company).then((res) => {
        //  this.setState({ success: true, error: null, buttonPressed: null });
        console.log('updateCompanyValue res', res);
        if (res.data && res.data.type === 'pending') {
          this.setState({ success: true, successPending: true, error: null, buttonPressed: null });
        } else {
          this.setState({ success: false, successPending: false, error: null, buttonPressed: null });
        }
      }).catch(err => {
        if (err && err.message) {
          if (err.message === 'has pending') {
            this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
          } else {
            this.setState({ success: false, error: err.message, buttonPressed: null });
          }
        } else if (err === 'has pending') {
          this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
        } else {
          this.setState({ success: false, error: err, buttonPressed: null });
        }
      }); */
    } else if (adminViewAsCompany) {
      this.setState({ buttonPressed: id });

      updateCompanyValue('hasDisplayName', val, adminViewAsCompany).then(() => this.setState({ success: true, error: null, buttonPressed: null })).catch(err => {
        if (err && err.message) {
          if (err.message === 'has pending') {
            this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
          } else {
            this.setState({ success: false, error: err.message, buttonPressed: null });
          }
        } else if (err === 'has pending') {
          this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
        } else {
          this.setState({ success: false, error: err, buttonPressed: null });
        }
      });

      /*
      updateCompanyValue('hasDisplayName', !company.hasDisplayName, adminViewAsCompany).then(() => this.setState({ success: true, error: null, buttonPressed: null })).catch(err => {
        if (err && err.message) {
          if (err.message === 'has pending') {
            this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
          } else {
            this.setState({ success: false, error: err.message, buttonPressed: null });
          }
        } else if (err === 'has pending') {
          this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
        } else {
          this.setState({ success: false, error: err, buttonPressed: null });
        }
      }); */
    }
  }

  closeChange = (e, id) => {
    e.stopPropagation();
    console.log('reset changes 2', id);

    if (id === 'companyNumber') {
      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          [id]: null,
          companyType: null,
        },
        error: null,
        success: null,
      }));
    } else {
      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          [id]: null,
        },
        error: null,
        success: null,
      }));
    }
  }


  updateComplianceData = async (e, clickedType) => {
    if (e.target && e.target.name) {
      const { name, value } = e.target;

      if (this.state[`typingTimeout${name}`]) {
        clearTimeout(this.state[`typingTimeout${name}`]);
      }

      let timeoutTime = 400;

      if (name === 'question1Description' || name === 'question2Description' || name === 'question3Description' || name === 'question5Description') {
        timeoutTime = 1700;
      }

      if (name === 'question1' || name === 'question2' || name === 'question3') {
        let checked = true;
        if (clickedType === 'no') {
          checked = false;
        }

        await this.setState((prevState) => ({
          complianceData: {
            ...prevState.complianceData,
            [name]: checked || false,
          },
        }));
      } else {
        await this.setState((prevState) => ({
          complianceData: {
            ...prevState.complianceData,
            [name]: value || '',
          },
        }));
      }

      this.setState({
        [`typingTimeout${name}`]: setTimeout(() => {
          this.saveComplianceData(name);
        }, timeoutTime)
      });

      //  this.saveComplianceData();
    }
  }

  saveComplianceData = (name) => {
    const { user, updateCompliance, adminViewAsCompany, savingComplianceData } = this.props;
    const { complianceData } = this.state;

    if (adminViewAsCompany) {
      //  updateCompliance(complianceData, adminViewAsCompany);
    } else {
      if (!savingComplianceData) {
        this.setState({ savingComplianceData: name });

        updateCompliance(name, complianceData[name], user.company).then(() => {
          this.setState({ savingComplianceData: null });
        }).catch(err => {
          this.setState({ savingComplianceData: null });
        });
      }
    }
  }

  handleChangeCompanyType = async (event) => {
    const { company } = this.props;
    const { changes } = this.state;

    console.log('handleChangeCompanyType', event);

    const val = event.target.value;

    console.log('handleChangeCompanyType val', val, company.companyType, changes.companyType, company.companyNumber);

    //  if (val === company.companyType && val === 'individual') {
    //    return null;
    //  }

    if (val === company.companyType) {
      if (val === 'individual' && changes.companyType !== 'company') {
        console.log('test 1111');
        return null;
      }

      if (val === 'company' && changes.companyType !== 'individual') {
        console.log('test 2222');
        return null;
      }

      // return null;
    }

    /*
    if (val === company.companyType && (!changes.companyType || changes.companyType)) {
      if (!changes.companyType) {
        return null;
      } else {
        await this.setState((prevState) => ({
          changes: {
            ...prevState.changes,
            companyType: null,
            companyNumber: null,
          },
        }));
        return null;
      }
    } */

    if (changes && changes.companyType) {
      console.log('test 1');
      await this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          companyType: val,
          companyNumber: val === 'company' ? (company.companyNumber || '') : null,
        },
      }));
    } else {
      console.log('test 2');
      await this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          companyType: val,
          companyNumber: company.companyNumber || '',
        },
      }));
    }

    // save change to company type if already has comapny number or changing to individual
    if ((val === 'company' && company.companyNumber) || val === 'individual') {
      console.log('test 333', val, company.companyNumber);
      this.saveChangeCompanyNumber(null, 'companyNumber');
    }


    // this.setState({ companyType: event.target.value });
  }

  handleChangeCompanyNumber = (e) => {
    if (e.target && e.target.name) {

      console.log('handleChangeCompanyNumber');
      const { name, value } = e.target;

      clearTimeout(this.timeoutID);

      if (name) {
        this.setState((prevState) => ({
          changes: {
            ...prevState.changes,
            companyNumber: value || '',
          },
        }));
      }

      this.timeoutID = setTimeout(() => {
        console.log('call timout');

        //  this.saveChangeCompanyNumber('companyNumber');
      }, 2000);
    }
  };


  saveChangeCompanyNumber = (e, id) => { // 
    const { user, adminViewAsCompany, company } = this.props;
    if (e) {
      e.stopPropagation();
    }
    const { updateCompanyValue } = this.props;
    const { changes } = this.state;

    console.log('saveChangeCompanyNumber', changes);

    if (user.company) { //id && (changes[id] || changes[id] === false && 
      console.log('buttonPressed test');
      this.setState({ buttonPressed: id });

      if (changes.companyType !== company.companyType) {
        console.log('test 12');
        updateCompanyValue('companyType', changes.companyType, user.company).then((res) => {
          if (changes.companyNumber === company.companyNumber) {
            if (res.data && res.data.type === 'pending') {
              this.setState({ success: true, successPending: true, error: null, buttonPressed: null });
            } else {
              this.setState({ success: true, successPending: false, error: null, buttonPressed: null });
            }
          }
        });
      }

      if (changes.companyNumber !== company.companyNumber && !(company.companyNumber && !changes.companyNumber)) {
        console.log('test 13');
        updateCompanyValue(id, changes[id], user.company).then((res) => {
          if (res.data && res.data.type === 'pending') {
            this.setState({ success: true, successPending: true, error: null, buttonPressed: null });
          } else {
            this.setState({ success: true, successPending: false, error: null, buttonPressed: null });
          }
        }).catch(err => {
          console.log('err', err);
          if (err && err.message) {
            if (err.message === 'has pending') {
              this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
            } else {
              this.setState({ success: false, error: err.message, buttonPressed: null });
            }
          } else {
            if (err === 'has pending') {
              this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
            } else {
              this.setState({ success: false, error: notifications.company.basicInfo.errorUpdating || 'Error updating data', buttonPressed: null });
            }
          }
        });
      }

      console.log('test 14');
      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          [id]: null,
        },
        buttonPressed: null,
      }));
    } else if (adminViewAsCompany) {
      console.log('buttonPressed test');
      this.setState({ buttonPressed: id });

      updateCompanyValue(id, changes[id], adminViewAsCompany).then(() => this.setState({ success: true, error: null, buttonPressed: null })).catch(err => {
        if (err && err.message) {
          if (err.message === 'has pending') {
            this.setState({ success: false, error: notifications.company.basicInfo.hasPending || 'Already has pending data, please cancel current request or wait for admin decision', buttonPressed: null });
          } else {
            this.setState({ success: false, error: err.message, buttonPressed: null });
          }
        } else {
          this.setState({ success: false, error: err, buttonPressed: null });
        }
      });

      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          [id]: null,
        },
        buttonPressed: null,
      }));
    }
  }

  openImagePreview = (type) => {
    const { companyLogo, companyPrimaryVoucherImage, existingVoucherImages } = this.state;

    this.setState({ imagePreviewModalOpen: true, imagePreviewModalType: type });

    /*
    if (type === 'companyLogo') {
      this.setState({ imagePreviewModalOpen: true, imagePreviewModalType: type });
    } else if (type === 'primaryVoucherImage') {
      this.setState({ imagePreviewModalOpen: true, imagePreviewModalImages: [companyPrimaryVoucherImage] });
    } else if (type === 'voucherImages') {
      this.setState({ imagePreviewModalOpen: true, imagePreviewModalImages: existingVoucherImages });
    } */
  }

  closeImagePreview = () => {
    this.setState({ imagePreviewModalOpen: false, imagePreviewModalType: null });
  }

  render() {
    const { company, categories, permissionLevel, adminViewAsCompany, showNoPermission, companyInfoTooltips } = this.props;
    const {
      changes,
      error,
      success,
      successPending,
      logoSrc,
      logoCrop,
      primaryVoucherImageSrc,
      primaryVoucherImageCrop,
      voucherImageSrc,
      voucherImageCrop,
      voucherCroppedImageUrl,
      logoCroppedImageUrl,
      primaryCroppedImageUrl,
      companyLogo,
      companyLogoName,
      companyPrimaryVoucherImage,
      companyPrimaryVoucherImageName,
      voucherImageUrls,
      existingVoucherImages,
      buttonPressed,
      categoriesOrdered,
      complianceData,
      savingComplianceData,
      imagePreviewModalOpen,
      imagePreviewModalType,
    } = this.state;

    console.log('company.companyType', company.companyType);
    console.log('changes.companyType', changes.companyType);

    console.log('buttonPressed', buttonPressed);

    return (
      <div>
        {companyInfoTooltips.description ? (
          <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='description-tooltip'>
            <span>{companyInfoTooltips.description ? companyInfoTooltips.description.value : ''}</span>
          </ReactTooltip>
        ) : (null)}

        {companyInfoTooltips.logo ? (
          <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='logo-tooltip'>
            <span>{companyInfoTooltips.logo ? companyInfoTooltips.logo.value : ''}</span>
          </ReactTooltip>
        ) : (null)}

        {companyInfoTooltips.category1 ? (
          <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='category-tooltip'>
            <span>{companyInfoTooltips.category1 ? companyInfoTooltips.category1.value : ''}</span>
          </ReactTooltip>
        ) : (null)}

        {companyInfoTooltips.primaryVoucherImage ? (
          <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='primary-image-tooltip'>
            <span>{companyInfoTooltips.primaryVoucherImage ? companyInfoTooltips.primaryVoucherImage.value : ''}</span>
          </ReactTooltip>
        ) : (null)}

        <Card style={{ marginBottom: 10 }}>
          <CardBody>
            <div class="basic-information">
              {(!error && !!success) && <Alert color="success">{successPending ? 'Pending' : 'Done'} </Alert>}
              {/*  || (changes.voucherImages || changes.voucherImages === '') */}
              {(!!error && (changes && !((changes.logo || changes.logo === '') || (changes.primaryVoucherImage || changes.primaryVoucherImage === '')))) && <Alert color="danger">{error}</Alert>}

              <div className="basic-information-row">
                <Label className="basic-information-label" for="name">Company name</Label>
                {(changes.name || changes.name === '') ? (
                  <>
                    <div className="basic-information-middle" onClick={(e) => e.stopPropagation()}>
                      <Input
                        name="name"
                        value={changes.name}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="basic-information-buttons">
                      <Button color="primary" disabled={buttonPressed === 'name' || showNoPermission} className="basic-information-check-button" onClick={(e) => this.saveChange(e, 'name')}>
                        <Check />
                        {buttonPressed === 'name' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                      </Button>
                      <Button color="primary" className="basic-information-x-button" onClick={(e) => this.closeChange(e, 'name')}>
                        <X />
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="basic-information-middle">
                      {company.companyName}
                    </div>
                    <div className="basic-information-buttons">
                      <Button
                        color="link"
                        disabled={buttonPressed === 'name' || showNoPermission} // || (adminViewAsCompany && !(permissionLevel === 'edit' || permissionLevel === 'full'))
                        className="basic-information-edit-button"
                        onClick={(e) => this.startChange(e, 'name', company.companyName)}
                      >
                        {buttonPressed === 'name' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                        <span>
                          <text name="-1" font="Arial" rotate="0" horizAnchor="middle" vertAnchor="middle" width="1" stroke="0x000000">&#x1F589;</text>
                        </span>
                      </Button>
                    </div>
                  </>
                )}
              </div>

              <div className="basic-information-row-hasDisplayName">
                <Label className="basic-information-label" for="hasDisplayName">Different trading name?</Label>
                <div className="basic-information-middle-companyType" style={{ display: 'inline-block', width: '40%' }}>
                  <Row>
                    <Col xs="6" style={{ paddingRight: 5 }}>
                      <Button
                        name="hasDisplayName"
                        id="hasDisplayNameYes"
                        color={company.hasDisplayName ? 'secondary' : 'secondary'}
                        value={true}
                        onClick={this.saveChangeDisplayName}
                        className={`basic-information-middle-companyType-button ${company.hasDisplayName ? 'basic-information-middle-companyType-button-active' : ''}`}
                      >
                        Yes
                        {buttonPressed === 'hasDisplayNameYes' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                      </Button>
                    </Col>
                    <Col xs="6" style={{ paddingLeft: 5 }}>
                      <Button
                        name="hasDisplayName"
                        id="hasDisplayNameNo"
                        color={company.hasDisplayName ? 'secondary' : 'secondary'}
                        value={false}
                        onClick={this.saveChangeDisplayName}
                        className={`basic-information-middle-companyType-button ${!company.hasDisplayName ? 'basic-information-middle-companyType-button-active' : ''}`}
                      >
                        No
                        {buttonPressed === 'hasDisplayNameNo' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                      </Button>
                    </Col>
                  </Row>
                  {/* <Input
                    style={{ width: 18, height: 18, position: 'relative' }}
                    name="hasDisplayName"
                    disabled={buttonPressed === 'hasDisplayName' || showNoPermission}
                    type="checkbox"
                    checked={company.hasDisplayName}
                    onChange={this.saveChangeDisplayName}
                  />
                  {buttonPressed === 'hasDisplayName' ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)} */}
                </div>
              </div>

              {(company.hasDisplayName && company.hasDisplayName === true) ? (
                <div className="basic-information-row">
                  <Label className="basic-information-label" for="hasDisplayName">Trading name</Label>
                  {(changes.displayName || changes.displayName === '') ? (
                    <>
                      <div className="basic-information-middle" onClick={(e) => e.stopPropagation()}>
                        <Input
                          name="displayName"
                          value={changes.displayName}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="basic-information-buttons">
                        <Button color="primary" disabled={buttonPressed === 'displayName' || showNoPermission} className="basic-information-check-button" onClick={(e) => this.saveChange(e, 'displayName')}>
                          <Check />
                          {buttonPressed === 'displayName' ? (
                            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                          ) : (null)}
                        </Button>
                        <Button color="primary" disabled={showNoPermission} className="basic-information-x-button" onClick={(e) => this.closeChange(e, 'displayName')}>
                          <X />
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="basic-information-middle">
                        {company.displayName}
                      </div>
                      <div className="basic-information-buttons">
                        <Button
                          color="link"
                          disabled={buttonPressed === 'displayName' || showNoPermission} // || (adminViewAsCompany && !(permissionLevel === 'edit' || permissionLevel === 'full'))
                          className="basic-information-edit-button"
                          onClick={(e) => this.startChange(e, 'displayName', company.displayName)}
                        >
                          {buttonPressed === 'displayName' ? (
                            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                          ) : (null)}
                          <span>
                            {/* {'\uD83D\uDD89'} */}
                            <text name="-1" font="Arial" rotate="0" horizAnchor="middle" vertAnchor="middle" width="1" stroke="0x000000">&#x1F589;</text>
                          </span>
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              ) : (null)}

              <div style={{ display: 'block' }}>
                <Label className="basic-information-label" for="companyNumber">Business type</Label>
                <div className="basic-information-middle-companyType" style={{ display: 'inline-block', width: '40%' }}>
                  {(changes.companyType) ? (
                    <>
                      <Row>
                        <Col xs="6" style={{ paddingRight: 5 }}>
                          <Button
                            name="companyType"
                            id="companyType2"
                            color={changes.companyType === 'individual' ? 'secondary' : 'secondary'}
                            value={'individual'}
                            onClick={this.handleChangeCompanyType}
                            className={`basic-information-middle-companyType-button ${changes.companyType === 'individual' ? 'basic-information-middle-companyType-button-active' : ''}`}
                          >
                            Sole trader
                          </Button>
                        </Col>
                        <Col xs="6" style={{ paddingLeft: 5 }}>
                          <Button
                            name="companyType"
                            id="companyType1"
                            color={changes.companyType === 'company' ? 'secondary' : 'secondary'}
                            value={'company'}
                            onClick={this.handleChangeCompanyType}
                            className={`basic-information-middle-companyType-button ${changes.companyType === 'company' ? 'basic-information-middle-companyType-button-active' : ''}`}
                          >
                            Limited company
                          </Button>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row>
                        <Col xs="6" style={{ paddingRight: 5 }}>
                          <Button
                            name="companyType"
                            id="companyType2"
                            color={company.companyType === 'individual' ? 'secondary' : 'secondary'}
                            value={'individual'}
                            onClick={this.handleChangeCompanyType}
                            className={`basic-information-middle-companyType-button ${company.companyType === 'individual' ? 'basic-information-middle-companyType-button-active' : ''}`}
                          >
                            Sole trader
                          </Button>
                        </Col>
                        <Col xs="6" style={{ paddingLeft: 5 }}>
                          <Button
                            name="companyType"
                            id="companyType1"
                            color={company.companyType === 'company' ? 'secondary' : 'secondary'}
                            value={'company'}
                            onClick={this.handleChangeCompanyType}
                            className={`basic-information-middle-companyType-button ${company.companyType === 'company' ? 'basic-information-middle-companyType-button-active' : ''}`}
                          >
                            Limited company
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </div>

              {console.log('changes.companyType', changes.companyType, company.companyType)}

              {(changes.companyType === 'company' || (!changes.companyType && company.companyType === 'company')) ? (
                <div className="basic-information-row">
                  <Label className="basic-information-label" for="companyNumber">Company number  (if limited company)</Label>
                  {(changes.companyNumber || changes.companyNumber === '') ? (
                    <>
                      <div className="basic-information-middle" onClick={(e) => e.stopPropagation()}>
                        <Input
                          name="companyNumber"
                          value={changes.companyNumber}
                          onChange={this.handleChangeCompanyNumber}
                        />
                      </div>
                      <div className="basic-information-buttons">
                        <Button color="primary" disabled={buttonPressed === 'companyNumber' || showNoPermission} className="basic-information-check-button" onClick={(e) => this.saveChangeCompanyNumber(e, 'companyNumber')}>
                          <Check />
                          {buttonPressed === 'companyNumber' ? (
                            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                          ) : (null)}
                        </Button>
                        <Button color="primary" className="basic-information-x-button" onClick={(e) => this.closeChange(e, 'companyNumber')}>
                          <X />
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="basic-information-middle">
                        {company.companyNumber ? company.companyNumber : ''}
                      </div>
                      <div className="basic-information-buttons">
                        <Button
                          color="link"
                          disabled={buttonPressed === 'companyNumber' || showNoPermission} // || (adminViewAsCompany && !(permissionLevel === 'edit' || permissionLevel === 'full'))
                          className="basic-information-edit-button"
                          onClick={(e) => this.startChange(e, 'companyNumber', company.companyNumber)}
                        >
                          {buttonPressed === 'companyNumber' ? (
                            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                          ) : (null)}
                          <span>
                            {/* {'\uD83D\uDD89'} */}
                            <text name="-1" font="Arial" rotate="0" horizAnchor="middle" vertAnchor="middle" width="1" stroke="0x000000">&#x1F589;</text>
                          </span>
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              ) : (null)}



              <div className="basic-information-row">
                <Label className="basic-information-label" for="website">Website</Label>
                {(changes.website || changes.website === '') ? (
                  <>
                    <div className="basic-information-middle" onClick={(e) => e.stopPropagation()}>
                      <Input
                        name="website"
                        value={changes.website}
                        placeholder="https://www.yourwebsite.com"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="basic-information-buttons">
                      <Button color="primary" disabled={buttonPressed === 'website' || showNoPermission} className="basic-information-check-button" onClick={(e) => this.saveChange(e, 'website')}>
                        <Check />
                        {buttonPressed === 'website' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                      </Button>
                      <Button color="primary" className="basic-information-x-button" onClick={(e) => this.closeChange(e, 'website')}>
                        <X />
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="basic-information-middle">
                      {company.website ? company.website : (<div className="basic-information-placeholder"> https://www.yourwebsite.com </div>)}
                    </div>
                    <div className="basic-information-buttons">
                      <Button
                        color="link"
                        disabled={buttonPressed === 'website' || showNoPermission} // || (adminViewAsCompany && !(permissionLevel === 'edit' || permissionLevel === 'full'))
                        className="basic-information-edit-button"
                        onClick={(e) => this.startChange(e, 'website', company.website)}
                      >
                        {buttonPressed === 'website' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                        <span>
                          {/* {'\uD83D\uDD89'} */}
                          <text name="-1" font="Arial" rotate="0" horizAnchor="middle" vertAnchor="middle" width="1" stroke="0x000000">&#x1F589;</text>
                        </span>
                      </Button>
                    </div>
                  </>
                )}
              </div>

              <div className="basic-information-row">
                <Label className="basic-information-label" for="description">
                  <span data-tip data-for='description-tooltip'>Company description</span>
                  <div data-tip data-for='description-tooltip' className="basic-information-info-icon">i</div>
                </Label>
                {(changes.description || changes.description === '') ? (
                  <>
                    <div className="basic-information-middle" onClick={(e) => e.stopPropagation()}>
                      <Input
                        name="description"
                        type="textarea"
                        style={{ height: 150 }}
                        value={changes.description}
                        placeholder="Our excellent quality..."
                        onChange={this.handleChange}
                      />
                      <div style={{ textAlign: 'right' }} className="text-length"> {`${changes.description ? changes.description.length : '0'}/500`} </div>
                    </div>
                    <div className="basic-information-buttons">
                      <Button color="primary" disabled={buttonPressed === 'description' || showNoPermission} className="basic-information-check-button" onClick={(e) => this.saveChange(e, 'description')}>
                        <Check />
                        {buttonPressed === 'description' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                      </Button>
                      <Button color="primary" className="basic-information-x-button" onClick={(e) => this.closeChange(e, 'description')}>
                        <X />
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="basic-information-middle">
                      {company.description ? company.description : (<div className="basic-information-placeholder"> Our excellent quality... </div>)}
                    </div>
                    <div className="basic-information-buttons">
                      <Button
                        color="link"
                        disabled={buttonPressed === 'description' || showNoPermission} // || (adminViewAsCompany && !(permissionLevel === 'edit' || permissionLevel === 'full'))
                        className="basic-information-edit-button"
                        onClick={(e) => this.startChange(e, 'description', company.description)}
                      >
                        {buttonPressed === 'description' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                        <span>
                          {/* {'\uD83D\uDD89'} */}
                          <text name="-1" font="Arial" rotate="0" horizAnchor="middle" vertAnchor="middle" width="1" stroke="0x000000">&#x1F589;</text>
                        </span>
                      </Button>
                    </div>
                  </>
                )}
              </div>

              <div className="basic-information-row">
                <Label className="basic-information-label" for="category1">
                  <span data-tip data-for='category-tooltip'>Category 1 for vouchers</span>
                  <div data-tip data-for='category-tooltip' className="basic-information-info-icon">i</div>
                </Label>
                {(changes.category1 || changes.category1 === '') ? (
                  <>
                    <div className="basic-information-middle" onClick={(e) => e.stopPropagation()}>
                      <Input name="category1" type="select" className="form-control" value={changes.category1} onChange={this.handleChange}>
                        {categoriesOrdered ? categoriesOrdered.sort((a, b) => a.order - b.order).map((category) => {
                          if (category.name === 'Rewards unlocked') {
                            return null;
                          }

                          return (
                            <option value={category.key}>{category.name}</option>
                          )
                        }) : null}
                      </Input>
                    </div>
                    <div className="basic-information-buttons">
                      <Button color="primary" disabled={buttonPressed === 'category1' || showNoPermission} className="basic-information-check-button" onClick={(e) => this.saveChange(e, 'category1')}>
                        <Check />
                        {buttonPressed === 'category1' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                      </Button>
                      <Button color="primary" className="basic-information-x-button" onClick={(e) => this.closeChange(e, 'category1')}>
                        <X />
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="basic-information-middle">
                      {(categories && company.category1 && categories[company.category1]) ? categories[company.category1].name : ''}
                    </div>
                    <div className="basic-information-buttons">
                      <Button
                        color="link"
                        disabled={buttonPressed === 'category1' || showNoPermission} // || (adminViewAsCompany && !(permissionLevel === 'edit' || permissionLevel === 'full'))
                        className="basic-information-edit-button"
                        onClick={(e) => this.startChange(e, 'category1', company.category1)}
                      >
                        {buttonPressed === 'category1' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                        <span>
                          {/* {'\uD83D\uDD89'} */}
                          <text name="-1" font="Arial" rotate="0" horizAnchor="middle" vertAnchor="middle" width="1" stroke="0x000000">&#x1F589;</text>
                        </span>
                      </Button>
                    </div>
                  </>
                )}
              </div>

              <div className="basic-information-row">
                <Label className="basic-information-label" for="category2">Category 2 for vouchers (optional)</Label>
                {(changes.category2 || changes.category2 === '') ? (
                  <>
                    <div className="basic-information-middle" onClick={(e) => e.stopPropagation()}>
                      <Input name="category2" type="select" className="form-control" value={changes.category2} onChange={this.handleChange}>
                        {categoriesOrdered ? categoriesOrdered.sort((a, b) => a.order - b.order).map((category) => {
                          if (category.name === 'Rewards unlocked') {
                            return null;
                          }

                          return (
                            <option value={category.key}>{category.name}</option>
                          )
                        }) : null}
                      </Input>
                    </div>
                    <div className="basic-information-buttons">
                      <Button color="primary" disabled={buttonPressed === 'category2' || showNoPermission} className="basic-information-check-button" onClick={(e) => this.saveChange(e, 'category2')}>
                        <Check />
                        {buttonPressed === 'category2' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                      </Button>
                      <Button color="primary" className="basic-information-x-button" onClick={(e) => this.closeChange(e, 'category2')}>
                        <X />
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="basic-information-middle">
                      {(categories && company.category2 && categories[company.category2]) ? categories[company.category2].name : ''}
                    </div>
                    <div className="basic-information-buttons">
                      <Button
                        color="link"
                        disabled={buttonPressed === 'category2' || showNoPermission} // || (adminViewAsCompany && !(permissionLevel === 'edit' || permissionLevel === 'full'))
                        className="basic-information-edit-button"
                        onClick={(e) => this.startChange(e, 'category2', company.category2)}
                      >
                        {buttonPressed === 'category2' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                        <span>
                          {/* {'\uD83D\uDD89'} */}
                          <text name="-1" font="Arial" rotate="0" horizAnchor="middle" vertAnchor="middle" width="1" stroke="0x000000">&#x1F589;</text>
                        </span>
                      </Button>
                    </div>
                  </>
                )}
              </div>

              <div className="basic-information-row">
                <Label className="basic-information-label" for="category2">
                  <span data-tip data-for='logo-tooltip'>Logo (minimum 100x100px)</span>
                  <div data-tip data-for='logo-tooltip' className="basic-information-info-icon">i</div>
                </Label>
                {(changes.logo || changes.logo === '') ? (
                  <Modal size="lg" isOpen={(changes.logo || changes.logo === '')} toggle={(e) => this.closeChange(e, 'logo')}>
                    <ModalHeader toggle={(e) => this.closeChange(e, 'logo')}>
                      Change logo
                    </ModalHeader>
                    <ModalBody>
                      {(!!error) && <Alert color="danger">{error}</Alert>}
                      <div>
                        <input type="file" accept="image/*" onChange={(e) => this.onSelectFile(e, 'logo')} />
                      </div>
                      {logoSrc && (
                        <ReactCrop
                          src={logoSrc}
                          crop={logoCrop}
                          ruleOfThirds
                          aspect={'1/1'}
                          minWidth={100}
                          maxWidth={860}
                          onImageLoaded={(e) => this.onImageLoaded(e, 'logo')}
                          onComplete={(e) => this.onCropComplete(e, 'logo')}
                          onChange={(crop) => this.onCropChange(crop, 'logo')}
                        />
                      )}
                      {logoCroppedImageUrl && (
                        <img alt="Crop" style={{ maxWidth: '100%' }} src={logoCroppedImageUrl} />
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Button disabled={buttonPressed === 'logo' || showNoPermission} color="primary" onClick={this.saveChangeLogo}>
                        Save
                        {buttonPressed === 'logo' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                      </Button>
                      <Button color="secondary" onClick={(e) => this.closeChange(e, 'logo')}>Close</Button>
                    </ModalFooter>
                  </Modal>
                ) : (null)}
                <div className="basic-information-upload-middle">
                  {/* <Media style={{ maxHeight: 50 }} src={companyLogo} /> */}
                  {companyLogo ? (<Button color="link" className="basic-information-upload-preview" onClick={() => this.openImagePreview('companyLogo')}>Logo added</Button>) : (<div className="basic-information-placeholder-for-image"> .png, .jpg, .jpeg</div>)}
                </div>
                <div className="basic-information-upload-buttons">
                  <Button
                    color="primary"
                    disabled={buttonPressed === 'logo' || showNoPermission}
                    className="basic-information-upload-button"
                    onClick={(e) => this.startChange(e, 'logo', company.logo)}
                  >
                    {buttonPressed === 'logo' ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                    ) : (null)}
                    <Media src={uploadIcon} />
                    <span>
                      Upload logo
                    </span>
                  </Button>
                </div>
              </div>

              <div className="basic-information-row">
                <Label className="basic-information-label" for="primaryVoucherImage">
                  <span data-tip data-for='primary-image-tooltip'>Primary voucher image (minimum 540x270px)</span>
                  <div data-tip data-for='primary-image-tooltip' className="basic-information-info-icon">i</div>
                </Label>
                {(changes.primaryVoucherImage || changes.primaryVoucherImage === '') ? (
                  <>
                    <Modal size="lg" isOpen={(changes.primaryVoucherImage || changes.primaryVoucherImage === '')} toggle={(e) => this.closeChange(e, 'primaryVoucherImage')}>
                      <ModalHeader toggle={(e) => this.closeChange(e, 'primaryVoucherImage')}>
                        Change primary voucher image
                      </ModalHeader>
                      <ModalBody>
                        {(!!error) && <Alert color="danger">{error}</Alert>}
                        <div>
                          <input type="file" accept="image/*" onChange={(e) => this.onSelectFile(e, 'primaryVoucherImage')} />
                        </div>
                        {primaryVoucherImageSrc && (
                          <ReactCrop
                            src={primaryVoucherImageSrc}
                            crop={primaryVoucherImageCrop}
                            ruleOfThirds
                            aspect={2}
                            minWidth={540}
                            maxWidth={1080}
                            minHeight={270}
                            maxHeight={540}
                            onImageLoaded={(e) => this.onImageLoaded(e, 'primaryVoucherImage')}
                            onComplete={(e) => this.onCropComplete(e, 'primaryVoucherImage')}
                            onChange={(crop) => this.onCropChange(crop, 'primaryVoucherImage')}
                          />
                        )}
                        {primaryCroppedImageUrl && (
                          <img alt="Crop" style={{ maxWidth: '100%' }} src={primaryCroppedImageUrl} />
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <Button disabled={buttonPressed === 'primaryVoucherImage' || showNoPermission} color="primary" onClick={this.savePrimaryVoucherImage}>
                          Save
                          {buttonPressed === 'primaryVoucherImage' ? (
                            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                          ) : (null)}
                        </Button>
                        <Button color="secondary" onClick={(e) => this.closeChange(e, 'primaryVoucherImage')}>Close</Button>
                      </ModalFooter>
                    </Modal>
                  </>
                ) : (null)}
                <div className="basic-information-upload-middle">
                  {companyPrimaryVoucherImage ? (<Button color="link" className="basic-information-upload-preview" onClick={() => this.openImagePreview('primaryVoucherImage')}>Primary voucher image added</Button>) : (<div className="basic-information-placeholder-for-image"> .png, .jpg, .jpeg</div>)}
                  {/* <Media style={{ maxHeight: 50 }} src={companyPrimaryVoucherImage} /> */}
                </div>
                <div className="basic-information-upload-buttons">
                  <Button
                    color="primary"
                    disabled={buttonPressed === 'primaryVoucherImage' || showNoPermission} // || (adminViewAsCompany && !(permissionLevel === 'edit' || permissionLevel === 'full'))
                    className="basic-information-upload-button"
                    onClick={(e) => this.startChange(e, 'primaryVoucherImage', company.primaryVoucherImage)}
                  >
                    {buttonPressed === 'primaryVoucherImage' ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                    ) : (null)}
                    <Media src={uploadIcon} />
                    <span>
                      Upload primary
                    </span>
                  </Button>
                </div>
              </div>

              {/* <div className="basic-information-row">
                <Label className="basic-information-label" for="voucherImages">Additional images (optional)</Label>
                {(changes.voucherImages || changes.voucherImages === '') ? (
                  <>
                    <Modal size="xl" isOpen={(changes.voucherImages || changes.voucherImages === '')} toggle={(e) => this.closeChange(e, 'voucherImages')}>
                      <ModalHeader toggle={(e) => this.closeChange(e, 'voucherImages')}>
                        Change voucher images
                      </ModalHeader>
                      <ModalBody>
                        {(!!error) && <Alert color="danger">{error}</Alert>}
                        {voucherImageUrls && voucherImageUrls.map((imageUrl, index) => (
                          <div style={{ display: 'inline-block', maxWidth: '25%', position: 'relative' }}>
                            <img alt="Crop" src={imageUrl.url ? imageUrl.url : imageUrl} />
                            <Button style={{ position: 'absolute', top: 0, right: 0, width: 20, height: 20, padding: 0 }} onClick={(e) => this.deleteVoucherImage(e, index)}>
                              <X style={{ top: -3, position: 'relative' }} />
                            </Button>
                          </div>
                        ))}
                        {voucherImageSrc && (
                          <ReactCrop
                            src={voucherImageSrc}
                            crop={voucherImageCrop}
                            ruleOfThirds
                            aspect={2}
                            minWidth={540}
                            maxWidth={1080}
                            minHeight={270}
                            maxHeight={540}
                            onImageLoaded={(e) => this.onImageLoaded(e, 'voucherImage')}
                            onComplete={(e) => this.onCropComplete(e, 'voucherImage')}
                            onChange={(crop) => this.onCropChange(crop, 'voucherImage')}
                          />
                        )}



                        {!voucherImageSrc && (!voucherImageUrls || voucherImageUrls.length < 5) && (
                          <div style={{ display: 'inline-block' }}>
                            <input type="file" accept="image/*" onChange={(e) => this.onSelectFile(e, 'voucherImage')} />
                          </div>
                        )}

                      </ModalBody>
                      <ModalFooter>
                        {voucherCroppedImageUrl ? (
                          <>
                            <img alt="Crop" style={{ maxWidth: '100%' }} src={voucherCroppedImageUrl} />
                            <Button color="primary" style={{ display: 'inline-block' }} onClick={this.saveVoucherImage}>
                              Confirm selection
                            </Button>
                          </>
                        ) : (
                          <>
                            {voucherImageSrc ? (
                              <Button color="primary" style={{ display: 'inline-block' }} onClick={this.saveVoucherImage}>
                                Confirm selection
                              </Button>
                            ) : (null)}
                          </>
                        )}
                        <Button disabled={buttonPressed === 'voucherImages' || showNoPermission} color="primary" onClick={this.updateVoucherImages}>
                          Save
                          {buttonPressed === 'voucherImages' ? (
                            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                          ) : (null)}
                        </Button>
                        <Button color="secondary" onClick={(e) => this.closeChange(e, 'voucherImages')}>Close</Button>
                      </ModalFooter>
                    </Modal>
                  </>
                ) : (null)}

                <div className="basic-information-upload-middle">
                  {(existingVoucherImages && existingVoucherImages.length) ? (<Button color="link" className="basic-information-upload-preview" onClick={() => this.openImagePreview('voucherImages')}>Additional images added</Button>) : (<div className="basic-information-placeholder-for-image"> .png, .jpg, .jpeg</div>)}

                  {/*  {company && company.voucherImagesArray && company.voucherImagesArray.length && company.voucherImagesArray.map(image => (
                      <Media style={{ maxHeight: 100, marginBottom: 5 }} src={image} />
                    ))}  *

                </div>
                {/*  display: 'inline-block'  *
                <div className="basic-information-upload-buttons">
                  <Button
                    color="primary"
                    disabled={buttonPressed === 'voucherImages' || showNoPermission}
                    className="basic-information-upload-button"
                    onClick={(e) => this.startChange(e, 'voucherImages', company.voucherImages)}
                  >
                    {buttonPressed === 'voucherImages' ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                    ) : (null)}
                    <Media src={uploadIcon} />
                    <span>
                      Upload additional
                    </span>
                  </Button>
                </div>
              </div> */}
            </div>

          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <div class="basic-information" style={{ padding: 15, paddingBottom: 0, paddingLeft: 0, paddingRight: 0, color: '#7f8fa4' }}>
              <div className="basic-information-row">
                <Label style={{ fontWeight: 700 }}>Compliance questions</Label>
              </div>
              <div className="basic-information-row" style={{ marginBottom: 15, fontSize: 14 }}>
                These answers will not be shared externally without your consent. If the status of the below changes, please update these immediately.
              </div>
              <div className="basic-information-row">
                <div className="basic-information-compliance-label">
                  Have you been trading for the past 12 months?
                  {savingComplianceData === 'question1' ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                  ) : (null)}
                </div>
                <div className="basic-information-compliance-right">
                  <div style={{ width: 18, float: 'left' }}>
                    <Input
                      style={{ marginRight: 15, width: 18, height: 18, position: 'relative', display: 'block', float: 'left' }}
                      name="question1"
                      disabled={savingComplianceData === 'question1' || showNoPermission || adminViewAsCompany}
                      type="radio"
                      checked={complianceData && complianceData.question1}
                      onChange={(e) => this.updateComplianceData(e, 'yes')}
                    />
                  </div>
                  <div style={{ width: 35, float: 'left' }}>
                    <Label style={{ paddingRight: '0.4rem', display: 'block', float: 'left', width: 35 }} for="question1"> Yes </Label>
                  </div>
                  <div style={{ width: 18, float: 'left' }}>
                    <Input
                      style={{ marginRight: 15, width: 18, height: 18, position: 'relative', display: 'block', float: 'left' }}
                      name="question1"
                      disabled={savingComplianceData === 'question1' || showNoPermission || adminViewAsCompany}
                      type="radio"
                      checked={complianceData && complianceData.question1 === false}
                      onChange={(e) => this.updateComplianceData(e, 'no')}
                    />
                  </div>
                  <div style={{ width: 35, float: 'left' }}>
                    <Label style={{ paddingRight: '0.4rem', display: 'block', float: 'left', width: 35 }} for="question1"> No </Label>
                  </div>
                  {complianceData && complianceData.question1 === false ? (
                    <div style={{ marginLeft: 106 }}>
                      <Input
                        style={{ marginLeft: 0, width: '100%', minWidth: 130, minHeight: 38, height: 38, position: 'relative', display: 'inline-block' }}
                        name="question1Description"
                        disabled={savingComplianceData === 'question1Description' || showNoPermission || adminViewAsCompany}
                        placeholder="Brief explanation..."
                        type="textarea"
                        value={complianceData && complianceData.question1Description}
                        onChange={this.updateComplianceData}
                      />
                    </div>
                  ) : (null)}
                  {/*  {buttonPressed === 'comp1' ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                    ):(null)} */}
                </div>
              </div>
              <div className="basic-information-row">
                <div className="basic-information-compliance-label">
                  Are you solvent, able and intending to continue trading for the foreseeable future?
                  {savingComplianceData === 'question2' ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                  ) : (null)}
                </div>
                <div className="basic-information-compliance-right">
                  <div style={{ width: 18, float: 'left' }}>
                    <Input
                      style={{ marginRight: 15, width: 18, height: 18, position: 'relative', display: 'block', float: 'left' }}
                      name="question2"
                      disabled={savingComplianceData === 'question2' || showNoPermission || adminViewAsCompany}
                      type="radio"
                      checked={complianceData && complianceData.question2}
                      onChange={(e) => this.updateComplianceData(e, 'yes')}
                    />
                  </div>
                  <div style={{ width: 35, float: 'left' }}>
                    <Label style={{ paddingRight: '0.4rem', display: 'block', float: 'left' }} for="question2"> Yes </Label>
                  </div>
                  <div style={{ width: 18, float: 'left' }}>
                    <Input
                      style={{ marginRight: 15, width: 18, height: 18, position: 'relative', display: 'block', float: 'left' }}
                      name="question2"
                      disabled={savingComplianceData === 'question2' || showNoPermission || adminViewAsCompany}
                      type="radio"
                      checked={complianceData && complianceData.question2 === false}
                      onChange={(e) => this.updateComplianceData(e, 'no')}
                    />
                  </div>
                  <div style={{ width: 35, float: 'left' }}>
                    <Label style={{ paddingRight: '0.4rem', display: 'block', float: 'left' }} for="question2"> No </Label>
                  </div>
                  {complianceData && complianceData.question2 === false ? (
                    <div style={{ marginLeft: 106 }}>
                      <Input
                        style={{ marginLeft: 0, width: '100%', minWidth: 130, minHeight: 38, height: 38, position: 'relative', display: 'inline-block' }}
                        name="question2Description"
                        disabled={savingComplianceData === 'question2Description' || showNoPermission || adminViewAsCompany}
                        placeholder="Brief explanation..."
                        type="textarea"
                        value={complianceData && complianceData.question2Description}
                        onChange={this.updateComplianceData}
                      />
                    </div>
                  ) : (null)}
                </div>
              </div>

              <div className="basic-information-row">
                <div className="basic-information-compliance-label">
                  Have you had any issues with lenders/creditors/HMRC over the past 12 months?
                  {savingComplianceData === 'question3' ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                  ) : (null)}
                </div>
                <div className="basic-information-compliance-right">
                  <div style={{ width: 18, float: 'left' }}>
                    <Input
                      style={{ marginRight: 15, width: 18, height: 18, position: 'relative', display: 'block', float: 'left' }}
                      name="question3"
                      disabled={savingComplianceData === 'question3' || showNoPermission || adminViewAsCompany}
                      type="radio"
                      checked={complianceData && complianceData.question3}
                      onChange={(e) => this.updateComplianceData(e, 'yes')}
                    />
                  </div>
                  <div style={{ width: 35, float: 'left' }}>
                    <Label style={{ paddingRight: '0.4rem', display: 'block', float: 'left' }} for="question3"> Yes </Label>
                  </div>
                  <div style={{ width: 18, float: 'left' }}>
                    <Input
                      style={{ marginRight: 15, width: 18, height: 18, position: 'relative', display: 'block', float: 'left' }}
                      name="question3"
                      disabled={savingComplianceData === 'question3' || showNoPermission || adminViewAsCompany}
                      type="radio"
                      checked={complianceData && complianceData.question3 === false}
                      onChange={(e) => this.updateComplianceData(e, 'no')}
                    />
                  </div>
                  <div style={{ width: 35, float: 'left' }}>
                    <Label style={{ paddingRight: '0.4rem', display: 'block', float: 'left' }} for="question3"> No </Label>
                  </div>
                  {complianceData && complianceData.question3 === true ? (
                    <div style={{ marginLeft: 106 }}>
                      <Input
                        style={{ marginLeft: 0, width: '100%', minWidth: 130, minHeight: 38, height: 38, position: 'relative', display: 'inline-block' }}
                        name="question3Description"
                        disabled={savingComplianceData === 'question3Description' || showNoPermission || adminViewAsCompany}
                        placeholder="Brief explanation..."
                        type="textarea"
                        value={complianceData && complianceData.question3Description}
                        onChange={this.updateComplianceData}
                      />
                    </div>
                  ) : (null)}
                </div>
              </div>

              <div className="basic-information-row">
                <div className="basic-information-compliance-label">
                  What was your turnover bracket for the last 12 months?
                  {savingComplianceData === 'question4' ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                  ) : (null)}
                </div>
                <div className="basic-information-compliance-right">
                  <Input
                    style={{ width: '100%', minWidth: 200, paddingLeft: 22, paddingRight: 22 }}
                    name="question4"
                    type="select"
                    className="form-control" value={complianceData && complianceData.question4}
                    onChange={this.updateComplianceData}
                    disabled={savingComplianceData === 'question4' || showNoPermission || adminViewAsCompany}
                    className={(!complianceData || !complianceData.question4) ? "basic-information-placeholder" : ''}
                  >
                    <option value={null} hidden selected> Select </option>
                    <option value={1}> £0-£25,000 </option>
                    <option value={2}> £25,001-£50,000 </option>
                    <option value={3}> £50,001-£100,000 </option>
                    <option value={4}> £100,001-£250,000 </option>
                    <option value={5}> £250,001-£500,000 </option>
                    <option value={6}> £500,001-£1,000,000 </option>
                    <option value={7}> £1,000,001-£5,000,000 </option>
                    <option value={8}> £5,000,001+ </option>
                  </Input>
                </div>
              </div>

              <div className="basic-information-row">
                <div className="basic-information-compliance-label">
                  What was your net income bracket for the last 12 months?
                  {savingComplianceData === 'question5' ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                  ) : (null)}
                </div>
                {console.log('complianceData test', complianceData)}
                <div className="basic-information-compliance-right">
                  <Input
                    style={{ width: '100%', minWidth: 200, paddingLeft: 22, paddingRight: 22 }}
                    name="question5"
                    type="select"
                    className="form-control"
                    value={complianceData && complianceData.question5}
                    onChange={this.updateComplianceData}
                    disabled={savingComplianceData === 'question5' || showNoPermission || adminViewAsCompany}
                    className={(!complianceData || !complianceData.question5) ? "basic-information-placeholder" : ''}
                  >
                    <option value={null} hidden selected> Select </option>
                    <option value={1}> {'<£0'} </option>
                    <option value={2}> £0-£25,000 </option>
                    <option value={3}> £25,001-£50,000 </option>
                    <option value={4}> £50,001-£100,000 </option>
                    <option value={5}> £100,001-£250,000 </option>
                    <option value={6}> £250,001-£500,000 </option>
                    <option value={7}> £500,001-£1,000,000 </option>
                    <option value={8}> £1,000,001-£5,000,000 </option>
                    <option value={9}> £5,000,001+ </option>
                  </Input>
                  {complianceData && complianceData.question5 === '1' ? (
                    <Input
                      style={{ marginTop: 10, marginLeft: 0, width: 'auto', minWidth: 200, minHeight: 38, height: 38, position: 'relative', display: 'inline-block' }}
                      name="question5Description"
                      disabled={savingComplianceData === 'question5' || showNoPermission || adminViewAsCompany}
                      placeholder="Brief explanation..."
                      type="textarea"
                      value={complianceData && complianceData.question5Description}
                      onChange={this.updateComplianceData}
                    />
                  ) : (null)}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        
        <Modal size="lg" isOpen={imagePreviewModalOpen} toggle={this.closeImagePreview}>
          <ModalBody>
            {(imagePreviewModalType === 'companyLogo') ? (
              <Media style={{ width: '90%', height: 'auto', margin: 'auto', display: 'block' }} src={companyLogo} />
            ) : (null)}
            {(imagePreviewModalType === 'primaryVoucherImage') ? (
              <Media style={{ width: '90%', height: 'auto', margin: 'auto', display: 'block' }} src={companyPrimaryVoucherImage} />
            ) : (null)}
            {(imagePreviewModalType === 'voucherImages') ? (
              <>
                {company && company.voucherImagesArray && company.voucherImagesArray.length && company.voucherImagesArray.map(image => (
                  <Media style={{ maxHeight: 100, marginBottom: 5 }} src={image} />
                ))}
              </>
            ) : (null)}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  company: state.company.company || {},
  categories: state.company.categories || {},
  isLoggedIn: state.user.isLoggedIn || false,
  adminViewAsCompany: state.admin.adminViewAsCompany || null,
  roles: state.users.companiesRoles || {},
  companyInfoTooltips: state.contentManagement.companyInfoTooltips || {},
});

const mapDispatchToProps = {
  updateCompanyValue: updateCompanyData,
  updateLogo: updateCompanyLogo,
  updatePrimaryVoucherImage: updateCompanyPrimaryVoucherImage,
  // updateVoucherImages: updateCompanyVoucherImages,
  getCompliance: getComplianceData,
  updateCompliance: updateComplianceData,
  fetchCompanyInfoData: getCompanyInfoData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Company));
