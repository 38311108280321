import {
  ADD_GIFTING_DATA,
  GET_ORDERED_TEMPLATE,
  GET_SINGLE_ORDER,
  GET_SINGLE_VOUCHER,
  GET_USER_VOUCHERS,
  CUSTOMER_GET_VOUCHER_CATEGORIES,
  CUSTOMER_GET_VOUCHER_COMPANIES,
  GET_VOUCHER_IMAGES,
  CUSTOMER_GET_VOUCHER_LIST,
  GET_VOUCHER_ORDERS,

  SAVE_PAYMENT_DATA,
  REMOVE_PAYMENT_DATA,
  GET_PAYMENT_PROGRESS,
} from '../../actions';

const defaultState = {
  list: {},
  companies: {},
  categories: {},
  orders: {},
  images: {},
  orderedVouchers: {},
  voucherById: {},
  orderData: {},
  templateOrdered: {},
  giftingData: {},
};

const voucherReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CUSTOMER_GET_VOUCHER_LIST:
      return { ...state, list: action.payload };

    case ADD_GIFTING_DATA:
      return { ...state, giftingData: action.payload };
    case GET_ORDERED_TEMPLATE:
      return { ...state, templateOrdered: action.payload };
    case GET_SINGLE_ORDER:
      return { ...state, orderData: action.payload };
    case CUSTOMER_GET_VOUCHER_COMPANIES:
      return { ...state, companies: { ...state.companies, ...action.payload } };
    case CUSTOMER_GET_VOUCHER_CATEGORIES:
      return { ...state, categories: { ...state.categories, ...action.payload } };
    case GET_VOUCHER_ORDERS:
      return { ...state, orders: action.payload };
    case GET_VOUCHER_IMAGES:
      return { ...state, images: action.payload };
    case GET_USER_VOUCHERS:
      return { ...state, orderedVouchers: action.payload };
    case GET_SINGLE_VOUCHER:
      return {
        ...state,
        voucherById: { ...state.voucherById, [action.voucher]: action.payload },
      };

    case SAVE_PAYMENT_DATA:
      return { ...state, paymentData: { ...state.paymentData, ...action.payload } };
    case REMOVE_PAYMENT_DATA:
      return { ...state, paymentData: {} };
    case GET_PAYMENT_PROGRESS:
      return { ...state, paymentStatus: { ...action.payload } };
    default:
      return state;
  }
};

export default voucherReducer;
