import {
  GET_USERS,
  COMPANIES_LIST,
  UPDATE_USER,
  DELETE_USER,
  GET_ROLES,
  GET_ADMIN_ROLES,
  GET_ADMIN_ACTIONS,
  GET_ADMINS_ROLES,
  GET_COMPANIES_ROLES,
  UPDATE_ROLE,
  REMOVE_ROLE,
  GET_COMPANY_COMPLIANCE_DATA,
  UPDATE_COMPANIES_ACTIVE_API_DATA,
  GET_COMPANY_OWNERS,
  GET_MORE_COMPANY_OWNERS,
  UPDATE_COMPANY,
  GET_COMPANYS_USERS,
  GET_COMPANY_LOGO,
  GET_APP_CUSTOMERS,
  GET_MORE_APP_CUSTOMERS,
  UPDATE_APP_CUSTOMER,
  GET_REQUESTING_CAMPAIGNS,
  GET_CAMPAIGNS,
  UPDATE_CAMPAIGN,
  GET_MORE_CAMPAIGNS,
  GET_CAMPAIGNS_BY_COMPANY,
  UPDATE_CAMPAIGN_BY_COMPANY,
  GET_MORE_CAMPAIGNS_BY_COMPANY,
  GET_ADMINS,
  GET_MORE_ADMINS,
  UPDATE_ADMIN,
  DELETE_ADMIN,
  GET_ADMIN_STATISTICS,
  GET_ADMIN_STATISTIC,
  GET_ADMIN_SETTINGS,
  GET_APP_SETTINGS,
  GET_ADMIN_DASHBOARD,
  GET_ADMIN_DASHBOARD_CUSTOM,
  GET_ADMIN_DASHBOARD_TOTALS,
  GET_ADMIN_DASHBOARD_STRIPE_DATA,
  GET_ADMIN_DASHBOARD_STRIPE_CUSTOM_DATA,
  GET_ADMIN_PAYMENTS,
  SORT_ADMIN_PAYMENTS,
  SORT_ADMIN_DATA,
  CARD_TEMPLATES,
  CARD_TEMPLATES_CATEGORIES,
  CARD_QUOTE,
  OPEN_COMPANY_DASHBOARD,
  LEAVE_COMPANY_DASHBOARD,
  SET_ANALYTICS_TEMPLATE,
  ANALYTICS_TEMPLATES,
  CARD_QUOTE_DESCRIPTIONS,
  GET_FIRST_ADMIN_PAYMENTS,
  GET_MORE_ADMIN_PAYMENTS,
  GET_SINGLE_ADMIN_PAYMENT,
  GREETING_TAB,
  GIFTING_PRICE,
  APP_GIFT_PREVIEW_LINK,
} from '../../actions';

import { auth } from '../../lib/firebase';

const defaultState = {
    users: {},
    adminStatistics: {},
    adminViewAsCompany: null,
};

const userReducer = (state=defaultState, action) => {
    switch(action.type) {
        case GET_USERS:
            return { ...state, users: action.payload };

        case COMPANIES_LIST:
            return { ...state, companiesList: action.payload };

        case SET_ANALYTICS_TEMPLATE: {
          if (state.adminAnalyticsTemplate) {
            return { ...state, adminAnalyticsTemplate: { ...state.adminAnalyticsTemplate, [action.id]: action.payload }};
          } else {
            return { ...state, adminAnalyticsTemplate: { [action.id]: action.payload }};
          }
        }


        case ANALYTICS_TEMPLATES: {
          return { ...state, adminAnalyticsTemplate: action.payload };
        }

        case UPDATE_USER: {
          console.log('UPDATE_USER', action);
          if (action.roleType === 'teamOwner') {
            const { companyOwners } = state;

            if (companyOwners) {
              const owner = companyOwners[action.index];

              console.log('owner', owner);

              if (owner) {
                const newCompanyOwners = [...companyOwners];

                newCompanyOwners[action.index] = { ...owner, ...action.payload };

                console.log('newCompanyOwners', newCompanyOwners);

                return { ...state, companyOwners: newCompanyOwners };
              } else {
                return state;
              }
            } else {
              return state;
            }
          } else {
            const { companyUsers } = state;

            if (companyUsers) {
              const user = companyUsers[action.index];

              if (user) {
                const newCompanyUsers = [...companyUsers];

                newCompanyUsers[action.index] = { ...user, ...action.payload };

                return { ...state, companyUsers: newCompanyUsers };
              } else {
                return state;
              }
            } else {
              return state;
            }
          }
        }
        case DELETE_USER: {
          if (action.roleType === 'teamOwner') {
            let newUsers = { ...state.companyOwners };
            delete newUsers[action.key];

            return {
              ...state,
              companyOwners: newUsers,
            };
          } else {
            let newUsers = { ...state.companyUsers };
            delete newUsers[action.key];

            return {
              ...state,
              companyUsers: newUsers,
            };
          }
        }




        case UPDATE_ADMIN: {
            if (action.key && state.admins && state.admins[action.key]) {
              return {
                ...state,
                admins: {
                  ...state.admins,
                  [action.key]: {
                    ...state.admins[action.key],
                    ...action.payload,
                  },
                }
              };
            } else {
              return {
                ...state,
                admins: {
                  ...state.admins,
                  [action.key]: {
                    ...action.payload,
                  },
                }
              };
            }
        }
        case DELETE_ADMIN: {
            let newAdmins = { ...state.admins };
            delete newAdmins[action.key];

            return {
              ...state,
              admins: newAdmins,
            };
        }
        case GET_ADMINS:
          let array = [];
          if (action.payload.data) {
              Object.keys(action.payload.data).map((key) => {
                array.push(action.payload.data[key]);
              });
          }

            return { ...state, admins: array, lastAdmin: action.payload.lastVisible };
        case GET_MORE_ADMINS: {
            return { ...state, admins: [ ...state.admins, ...action.payload.data ], lastAdmin: action.payload.lastVisible };
        }

      case GET_COMPANY_COMPLIANCE_DATA: {
        console.log('GET_COMPANY_COMPLIANCE_DATA', action.payload);
        return { ...state, companiesComplianceData: action.payload };
      }

      case UPDATE_COMPANIES_ACTIVE_API_DATA: {
        console.log('UPDATE_COMPANIES_ACTIVE_API_DATA', action.payload);
        return { ...state, companiesApiActiveType: action.payload };
      }

      case GET_COMPANY_OWNERS: {
        console.log('GET_COMPANY_OWNERS', action.payload);
            return { ...state, companyOwners: action.payload.data, lastCompanyOwner: action.payload.lastVisible, companyOwnersObj: action.companyOwnersObj };
        }
        case GET_MORE_COMPANY_OWNERS: {
            return { ...state, companyOwners: [ ...state.companyOwners, ...action.payload.data ], lastCompanyOwner: action.payload.lastVisible };
        }

        case UPDATE_COMPANY: {
            const newCompanies = [...state.companyOwners];

            if (action.changeType === 'commission') {
              newCompanies[action.index] = {
                ...newCompanies[action.index],
                commission: action.payload,
              }
            }

            return { ...state, companyOwners: newCompanies };
        }

        case GET_COMPANYS_USERS:
            return { ...state, companyUsers: action.payload };
        case GET_REQUESTING_CAMPAIGNS:
            return { ...state, voucherCampaigns: action.payload };

        case GET_CAMPAIGNS: {
          console.log('GET_CAMPAIGNS', action.payload.data);
          return { ...state, voucherCampaigns: action.payload.data, lastVoucherCampaign: action.payload.lastVisible, voucherCampaignsObj: action.campaignsObj };
        }

        case UPDATE_CAMPAIGN: {
            const newVoucherCampaigns = [...state.voucherCampaigns];

            if (action.changeType === 'enableRedemptions') {
              newVoucherCampaigns[action.index] = {
                ...newVoucherCampaigns[action.index],
                enableRedemptions: action.payload,
              }
            } else if (action.changeType === 'commission') {
              newVoucherCampaigns[action.index] = {
                ...newVoucherCampaigns[action.index],
                commission: action.payload,
              }
            } else if (action.changeType === 'all') {
              newVoucherCampaigns[action.index] = {
                ...newVoucherCampaigns[action.index],
                ...action.payload,
              }
            }

            return { ...state, voucherCampaigns: newVoucherCampaigns };
        }

        case GET_MORE_CAMPAIGNS:
            return { ...state, voucherCampaigns: [ ...state.voucherCampaigns, ...action.payload.data ], lastVoucherCampaign: action.payload.lastVisible };

        case GET_CAMPAIGNS_BY_COMPANY: {

            return { ...state, voucherCampaignsCompany: action.payload.data, lastVoucherCampaignCompany: action.payload.lastVisible };

        }
        case UPDATE_CAMPAIGN_BY_COMPANY: {
            const newVoucherCampaignsCompany = [...state.voucherCampaignsCompany];

            newVoucherCampaignsCompany[action.index] = {
              ...newVoucherCampaignsCompany[action.index],
              enableRedemptions: action.payload,
            }

            return { ...state, voucherCampaignsCompany: newVoucherCampaignsCompany, lastVoucherCampaignCompany: action.payload.lastVisible };
        }
        case GET_MORE_CAMPAIGNS_BY_COMPANY:
            return { ...state, voucherCampaignsCompany: [ ...state.voucherCampaignsCompany, ...action.payload.data ], lastVoucherCampaignCompany: action.payload.lastVisible };

        case GET_COMPANY_LOGO:
            return { ...state, companyLogo: action.payload };
        case GET_APP_CUSTOMERS:
            return { ...state, appCustomers: action.payload.data, lastAppCustomer: action.payload.lastVisible };

        case UPDATE_APP_CUSTOMER: {
            const newAppCustomers = [...state.appCustomers];

            let dataToChange = 'status';
            let newValue = action.status;

            if (action.changeType === 'buying') {
              dataToChange = 'buyingStatus';
            } else if (action.changeType === 'redemptions') {
              dataToChange = 'redemptionsStatus';
          } else if (action.changeType === 'tester') {
              dataToChange = 'tester';
            } else if (action.changeType === 'status') {
              if (action.status) {
                newValue = 'approved';
              } else {
                newValue = 'denied';
              }
            }

            newAppCustomers[action.index] = {
              ...newAppCustomers[action.index],
              [dataToChange]: newValue,
            }

            return { ...state, appCustomers: newAppCustomers };
        }

        case GET_MORE_APP_CUSTOMERS:
            return { ...state, appCustomers: [ ...state.appCustomers, ...action.payload.data ], lastAppCustomer: action.payload.lastVisible };
        case GET_ROLES:
            return { ...state, roles: action.payload };
        case GET_ADMIN_ROLES:
            return { ...state, adminRoles: action.payload };

        case GET_ADMIN_ACTIONS:
            return { ...state, adminActions: action.payload };

        case GET_COMPANIES_ROLES:
            return { ...state, companiesRoles: action.payload };
        case GET_ADMINS_ROLES: {
            let newRoles = {};

            if (action.payload) {
              Object.keys(action.payload).map((key) => {
                const { permissions } = action.payload[key];

                let sortValue = 0;
                if (permissions) {
                  Object.keys(permissions).map((key2) => {
                    if (permissions[key2] === 1 || permissions[key2] === 2) {
                      sortValue = sortValue + permissions[key2];
                    }
                    return null;
                  });
                }

                newRoles[key] = { ...action.payload[key], sortValue };

                return null;
              })
            }

            return { ...state, adminsRoles: newRoles };
        }

        case UPDATE_ROLE: {
          if (action.roleType === 'admin') {
            return {
              ...state,
              adminRoles: {
                ...state.adminRoles,
                [action.key]: action.payload,
              }
            };
          } else {
            return {
              ...state,
              roles: {
                ...state.roles,
                [action.key]: action.payload,
              }
            };
          }
        }
        case REMOVE_ROLE: {
          if (action.roleType === 'admin') {
            let newRoles = { ...state.adminRoles };
            delete newRoles[action.key];

            return {
              ...state,
              adminRoles: newRoles,
            };
          } else {
            let newRoles = { ...state.roles };
            delete newRoles[action.key];

            return {
              ...state,
              roles: newRoles,
            };
          }
        }

        case CARD_TEMPLATES:
            return { ...state, cardTemplates: action.payload.templates, cardCategories: action.payload.categories };

        case CARD_TEMPLATES_CATEGORIES:
            return { ...state, cardCategories: action.payload.categories };

        case CARD_QUOTE: {
          const { quotes } = action.payload;
          const { product } = action.productData;

          return { ...state, cardQuote: quotes, cardProductData: product };
        }

        case CARD_QUOTE_DESCRIPTIONS:
            return { ...state, cardQuoteDescriptions: action.payload };

        case GET_ADMIN_STATISTICS:
            return { ...state, adminStatistics: action.payload };

        case GET_ADMIN_SETTINGS:
            return { ...state, adminSettings: action.payload };

        case GET_APP_SETTINGS:
          return { ...state, appSettings: action.payload };

        case GET_ADMIN_STATISTIC: {
            return {
              ...state,
              adminStatistics: {
                ...state.adminStatistics,
                [action.key]: action.payload,
              }
            };
        }

        case GET_ADMIN_DASHBOARD: {
        console.log('GET_ADMIN_DASHBOARD', action);
          return {
            ...state,
            adminDashboard: action.payload,
            adminDashboardAgregated: {
              adminStatistics1Day: action.adminStatistics1Day,
              adminStatistics1Week: action.adminStatistics1Week,
              adminStatistics1Month: action.adminStatistics1Month,
              adminStatistics1Year: action.adminStatistics1Year,
              adminStatisticsAllTime: action.adminStatisticsAllTime,
            },
            adminDashboardCustom: action.adminStatistics1Month,
            adminDashboardLastUpdate: Date.now(),

            adminStatisticsFaceValueSales: action.adminStatisticsFaceValueSales,
            adminStatisticsSalesValue: action.adminStatisticsSalesValue,
            adminStatisticsSalesCount: action.adminStatisticsSalesCount,
            adminStatisticsCommission: action.adminStatisticsCommission,
            adminStatisticsRedemptionsCount: action.adminStatisticsRedemptionsCount,
            adminStatisticsRedemptionsValue: action.adminStatisticsRedemptionsValue,
          //  adminStatisticsRefundsCount: action.adminStatisticsRefundsCount,
          //  adminStatisticsRefundsValue: action.adminStatisticsRefundsValue,
            adminStatisticsRefunds: action.adminStatisticsRefunds,
            adminStatisticsCancelledVouchers: action.adminStatisticsCancelledVouchers,
            adminStatisticsRedemptionsPercentages: action.adminStatisticsRedemptionsPercentages,
            adminStatisticsRedemptions: action.adminStatisticsRedemptions,
            adminStatisticsGiftedCount: action.adminStatisticsGiftedCount,
            adminStatisticsGiftedValue: action.adminStatisticsGiftedValue,

            adminStatisticsCompanies: action.adminStatisticsCompanies,
            adminStatisticsCampaigns: action.adminStatisticsCampaigns,
            adminStatisticsCustomers: action.adminStatisticsCustomers,
            adminStatisticsLastRefresh: Date.now(),
          };
        }

        case GET_ADMIN_DASHBOARD_TOTALS: {
        return { ...state, adminDashboardTotals: action.payload, last24HoursClients: action.last24HoursClients };
        }

        case GET_ADMIN_DASHBOARD_STRIPE_DATA: {
        console.log('GET_ADMIN_DASHBOARD_STRIPE_DATA', action.payload, action.data1Month, action.timeFrame);
        let timeObj = 'data1Month';
        if (action.timeFrame === 'day') {
          timeObj = 'data1Day';
        } else if (action.timeFrame === 'week') {
          timeObj = 'data1Week';
        } else if (action.timeFrame === 'year') {
          timeObj = 'data1Year';
        } else if (action.timeFrame === 'allTime') {
          timeObj = 'dataAllTime';
        } else if (action.timeFrame === 'custom') {
          timeObj = 'payload';
        }

          return {
            ...state,
            adminDashboardStripe: action.payload,
            adminDashboardStripeRawData: action.rawData,
           /* adminDashboardStripeAgregated: {
              data1Day: action.data1Day,
              data1Week: action.data1Week,
              data1Month: action.data1Month,
              data1Year: action.data1Year,
              dataAllTime: action.dataAllTime,
            }, */
            adminDashboardStripeCustom: action[timeObj],
          };
        }

        case GET_ADMIN_DASHBOARD_CUSTOM: {
          return {
            ...state,
            adminDashboardCustom: action.payload,
          };
        }

      case GET_ADMIN_DASHBOARD_STRIPE_CUSTOM_DATA: {
        console.log('GET_ADMIN_DASHBOARD_STRIPE_CUSTOM_DATA');
          return {
            ...state,
            adminDashboardStripeCustom: action.payload,
          };
        }

      case GET_ADMIN_PAYMENTS: {
        console.log('GET_ADMIN_PAYMENTS');
          const { typeFilter, payload } = action;

          if (typeFilter === 'all') {
            let arr = [];
            let totals = {};

            Object.keys(payload).map((key, index) => {
              arr.push({
                ...payload[key],
                dbId: key,
                chargeId: (payload[key] && payload[key].chargeId) ? payload[key].chargeId : null,
                companyName: (payload[key] && payload[key].companyName) ? payload[key].companyName : payload[key].destination,
                companyId: (payload[key] && payload[key].companyId) ? payload[key].companyId : null,
                customerEmail: (payload[key] && payload[key].customerEmail) ? payload[key].customerEmail : payload[key].customer,
                customerId: (payload[key] && payload[key].customerId) ? payload[key].customerId : null,
                customerName: (payload[key] && payload[key].customerName) ? payload[key].customerName : null,
                voucherId: (payload[key] && payload[key].voucherId) ? payload[key].voucherId : null,
                voucherName: (payload[key] && payload[key].voucherName) ? payload[key].voucherName : null,
                discount: (payload[key] && payload[key].discount) ? payload[key].discount : null,
                discountStep: (payload[key] && payload[key].discountStep) ? payload[key].discountStep : null,
                faceValue: (payload[key] && payload[key].faceValue) ? payload[key].faceValue : null,
                gift: (payload[key] && payload[key].gift) ? payload[key].gift : null,
                // gift: (payload[key] && payload[key].buyFor && payload[key].buyFor === 'gift') ? true : null,
                giftingData: (payload[key] && payload[key].giftingData) ? payload[key].giftingData : null,
                redeemed: (payload[key] && payload[key].redeemed && payload[key].redeemed === 'true') ? payload[key].redeemed : null,
              });

              totals = {
                faceValue: (totals.faceValue || 0) + ((payload[key] && payload[key].faceValue) ? parseInt(payload[key].faceValue, 10) : 0),
                amount_captured: (totals.amount_captured || 0) + (payload[key].amount_captured || 0),
                gift: (payload[key] && payload[key].buyFor && payload[key].buyFor === 'gift') ? (totals.gift || 0) + 1 : totals.gift || 0,
                redeemed: (payload[key] && payload[key].redeemed && payload[key].redeemed === 'true') ? (totals.redeemed || 0) + 1 : totals.redeemed || 0,
                application_fee_amount: (totals.application_fee_amount || 0) + (payload[key].application_fee_amount || 0),
              }
            });

            return { ...state, adminPayments: arr.reverse(), adminPaymentsTotals: totals };
          } else if (typeFilter === 'company') {
            let companiesPayments = {};
            let totals = {};

            Object.keys(payload).map((key, index) => {
              if (payload[key] && payload[key].companyId) {
                companiesPayments[payload[key].companyId] = {
                  ...companiesPayments[payload[key].companyId],
                  [payload[key].companyId]: payload[key],
                }
              }
            })

            let arr = [];
            Object.keys(companiesPayments).map((key, index) => {
              const company = companiesPayments[key];

              let companyTotal = {};
              Object.keys(company).map((key2) => {
                companyTotal = {
                  amount_captured: (companyTotal.amount_captured || 0) + company[key2].amount_captured,
                  capturedCount: company[key2].amount_captured ? ((companyTotal.capturedCount || 0) + 1) : companyTotal.capturedCount,
                  id: (company[key2] && company[key2].companyId) ? company[key2].companyId : null,
                  amount_refunded: (companyTotal.amount_refunded || 0) + company[key2].amount_refunded,
                  refundedCount: company[key2].amount_refunded ? ((companyTotal.refundedCount || 0) + 1) : companyTotal.refundedCount,
                  application: company[key2].application,
                  destination: company[key2].destination,
                  companyName: (company[key2] && company[key2].companyName) ? company[key2].companyName : company[key2].destination,
                  companyId: (company[key2] && company[key2].companyId) ? company[key2].companyId : null,
                  application_fee: (companyTotal.application_fee || 0) + company[key2].application_fee,
                  application_fee_amount: (companyTotal.application_fee_amount || 0) + company[key2].application_fee_amount,
                  customer: null,
                  faceValue: (companyTotal.faceValue || 0) + (company[key2].faceValue ? parseInt(company[key2].faceValue, 10) : 0),
                }

                totals = {
                  faceValue: (totals.faceValue || 0) + (company[key2].faceValue ? parseInt(company[key2].faceValue, 10) : 0),
                  amount_captured: (totals.amount_captured || 0) + (company[key2].amount_captured || 0),
                  application_fee_amount: (totals.application_fee_amount || 0) + (company[key2].application_fee_amount || 0),
                }
              })

              arr.push({ ...companyTotal });
            });

            return { ...state, adminPayments: arr, adminPaymentsTotals: totals };
          } else if (typeFilter === 'customer') {
            let customersPayments = {};
            let totals = {};

            Object.keys(payload).map((key, index) => {
              if (payload[key] && payload[key].customerId) {
                customersPayments[payload[key].customerId] = {
                  ...customersPayments[payload[key].customerId],
                  [payload[key].customerId]: payload[key],
                }
              }
            })

            let arr = [];
            Object.keys(customersPayments).map((key, index) => {
              const customer = customersPayments[key];

              let customerTotal = {};
              Object.keys(customer).map((key2) => {
                customerTotal = {
                  amount_captured: (customerTotal.amount_captured || 0) + customer[key2].amount_captured,
                  capturedCount: customer[key2].amount_captured ? ((customerTotal.capturedCount || 0) + 1) : customerTotal.capturedCount,
                  id: (customer[key2] && customer[key2].customerId) ? customer[key2].customerId : '',
                  amount_refunded: (customerTotal.amount_refunded || 0) + customer[key2].amount_refunded,
                  refundedCount: customer[key2].amount_refunded ? ((customerTotal.refundedCount || 0) + 1) : customerTotal.refundedCount,
                  application: null,
                  destination: null,
                  application_fee: (customerTotal.application_fee || 0) + customer[key2].application_fee,
                  application_fee_amount: (customerTotal.application_fee_amount || 0) + customer[key2].application_fee_amount,
                  customer: customer[key2].customer,
                  customerName: (customer[key2] && customer[key2].customerName) ? customer[key2].customerName : '',
                  customerEmail: (customer[key2] && customer[key2].customerEmail) ? customer[key2].customerEmail : '',
                  customerId: (customer[key2] && customer[key2].customerId) ? customer[key2].customerId : null,
                  faceValue: (customerTotal.faceValue || 0) + (customer[key2].faceValue ? parseInt(customer[key2].faceValue, 10) : 0),
                }

                totals = {
                  faceValue: (totals.faceValue || 0) + (customer[key2].faceValue ? parseInt(customer[key2].faceValue, 10) : 0),
                  application_fee_amount: (totals.application_fee_amount || 0) + (customer[key2].application_fee_amount || 0),
                  amount_captured: (totals.amount_captured || 0) + (customer[key2].amount_captured || 0),
                }
              })

              arr.push({ ...customerTotal });
            });

            return { ...state, adminPayments: arr, adminPaymentsTotals: totals };
          } else if (typeFilter === 'campaign') {
            let campaignsPayments = {};
            Object.keys(payload).map((key, index) => {
              if (payload[key] && payload[key] && payload[key].voucherId) {
                campaignsPayments[payload[key].voucherId] = {
                  ...campaignsPayments[payload[key].voucherId],
                  [payload[key].id]: payload[key],
                }
              }
            })

            let arr = [];
            Object.keys(campaignsPayments).map((key, index) => {
              const campaign = campaignsPayments[key];

              let campaignTotal = {};
              Object.keys(campaign).map((key2) => {
                campaignTotal = {
                  amount_captured: (campaignTotal.amount_captured || 0) + campaign[key2].amount_captured,
                  capturedCount: campaign[key2].amount_captured ? ((campaignTotal.capturedCount || 0) + 1) : campaignTotal.capturedCount,
                  id: (campaign[key2] && campaign[key2].voucherId) ? campaign[key2].voucherId : null,
                  amount_refunded: (campaignTotal.amount_refunded || 0) + campaign[key2].amount_refunded,
                  refundedCount: campaign[key2].amount_refunded ? ((campaignTotal.refundedCount || 0) + 1) : campaignTotal.refundedCount,
                  application: null,
                  destination: null,
                  application_fee: (campaignTotal.application_fee || 0) + campaign[key2].application_fee,
                  application_fee_amount: (campaignTotal.application_fee_amount || 0) + campaign[key2].application_fee_amount,
                  customer: null,
                  voucherId: (campaign[key2] && campaign[key2].voucherId) ? campaign[key2].voucherId : null,
                  voucherName: (campaign[key2] && campaign[key2].voucherName) ? campaign[key2].voucherName : null,
                  companyName: (campaign[key2] && campaign[key2].companyName) ? campaign[key2].companyName : campaign[key2].destination,
                  companyId: (campaign[key2] && campaign[key2].companyId) ? campaign[key2].companyId : null,
                  faceValue: (campaignTotal.faceValue || 0) + (campaign[key2].faceValue ? parseInt(campaign[key2].faceValue, 10) : 0),
                }
              })

              arr.push({ ...campaignTotal });
            });

            return { ...state, adminPayments: arr };
          } else {
            return { ...state }
          }
        }

        case GET_SINGLE_ADMIN_PAYMENT: {
          const { payload, dbId } = action;
          const { adminPayments } = state;

          if (!payload) {
            return null;
          }

          const index = adminPayments.findIndex(i => i.dbId === dbId);

          const newAdminPayments = [ ...adminPayments ];

          newAdminPayments[index] = {
            ...adminPayments[index],
            ...payload,
            dbId: dbId,
            chargeId: (payload.chargeId) ? payload.chargeId : null,
            companyName: (payload.companyName) ? payload.companyName : payload.destination,
            companyId: (payload.companyId) ? payload.companyId : null,
            customerEmail: (payload.customerEmail) ? payload.customerEmail : payload.customer,
            customerId: (payload.customerId) ? payload.customerId : null,
            customerName: (payload.customerName) ? payload.customerName : null,
            voucherId: (payload.voucherId) ? payload.voucherId : null,
            voucherName: (payload.voucherName) ? payload.voucherName : null,
            discount: (payload.discount) ? payload.discount : null,
            discountStep: (payload.discountStep) ? payload.discountStep : null,
            faceValue: (payload.faceValue) ? payload.faceValue : null,
            gift: (payload.gift) ? payload.gift : null,
            // gift: (payload && payload.buyFor && payload.buyFor === 'gift') ? true : null,
            giftingData: (payload && payload.giftingData) ? payload.giftingData : null,
            redeemed: (payload.redeemed && payload.redeemed === 'true') ? payload.redeemed : null,
            refunded: (payload.refunded) ? payload.refunded : false,
            refundValue: (payload.refundValue) ? payload.refundValue : null,
            refundDate: (payload.refundDate) ? payload.refundDate : null
          }

          return { ...state, adminPayments: newAdminPayments };
        }


      case GET_FIRST_ADMIN_PAYMENTS: {
        console.log('GET_FIRST_ADMIN_PAYMENTS');
          const { typeFilter, payload, lastKey, orderBy, sortType } = action;

          let arr = [];
          let totals = {};

          Object.keys(payload).map((key, index) => {
            arr.push({
              ...payload[key],
              dbId: key,
              chargeId: (payload[key] && payload[key].chargeId) ? payload[key].chargeId : null,
              companyName: (payload[key] && payload[key].companyName) ? payload[key].companyName : payload[key].destination,
              companyId: (payload[key] && payload[key].companyId) ? payload[key].companyId : null,
              customerEmail: (payload[key] && payload[key].customerEmail) ? payload[key].customerEmail : payload[key].customer,
              customerId: (payload[key] && payload[key].customerId) ? payload[key].customerId : null,
              customerName: (payload[key] && payload[key].customerName) ? payload[key].customerName : null,
              voucherId: (payload[key] && payload[key].voucherId) ? payload[key].voucherId : null,
              voucherName: (payload[key] && payload[key].voucherName) ? payload[key].voucherName : null,
              discount: (payload[key] && payload[key].discount) ? payload[key].discount : null,
              discountStep: (payload[key] && payload[key].discountStep) ? payload[key].discountStep : null,
              faceValue: (payload[key] && payload[key].faceValue) ? payload[key].faceValue : null,
              gift: (payload[key] && payload[key].gift) ? payload[key].gift : null,
              // gift: (payload[key] && payload[key].buyFor && payload[key].buyFor === 'gift') ? true : null,
              giftingData: (payload[key] && payload[key].giftingData) ? payload[key].giftingData : null,
              redeemed: (payload[key] && payload[key].redeemed && payload[key].redeemed === 'true') ? payload[key].redeemed : null,
              refunded: (payload[key] && payload[key].refunded) ? payload[key].refunded : false,
              refundValue: (payload[key] && payload[key].refundValue) ? payload[key].refundValue : null,
              refundDate: (payload[key] && payload[key].refundDate) ? payload[key].refundDate : null,
            });

            totals = {
              faceValue: (totals.faceValue || 0) + ((payload[key] && payload[key].faceValue) ? parseInt(payload[key].faceValue, 10) : 0),
              amount_captured: (totals.amount_captured || 0) + (payload[key].amount_captured || 0),
              // gift: (payload[key] && payload[key].gift && payload[key].gift === 'true') ? (totals.gift || 0) + 1 : totals.gift || 0,
              gift: (payload[key] && payload[key].buyFor && payload[key].buyFor === 'gift') ? (totals.gift || 0) + 1 : totals.gift || 0,
              redeemed: (payload[key] && payload[key].redeemed && payload[key].redeemed === 'true') ? (totals.redeemed || 0) + 1 : totals.redeemed || 0,
              application_fee_amount: (totals.application_fee_amount || 0) + (payload[key].application_fee_amount || 0),
            }
          });

          if (sortType === 'asc') {
            arr.sort((a, b) => {
              if (b[orderBy] > a[orderBy]) {
                return 1;
              } else {
                return -1;
              }
            });
          } else {
            arr.sort((a, b) => {
              if (a[orderBy] > b[orderBy]) {
                return 1;
              } else {
                return -1;
              }
            });
          }

          return { ...state, adminPayments: arr.reverse(), lastPaymentId: lastKey };
        }

      case GET_MORE_ADMIN_PAYMENTS: {
        console.log('GET_MORE_ADMIN_PAYMENTS');
          const { typeFilter, payload, lastKey, orderBy, sortType } = action;
          const { lastPaymentId, adminPayments } = state;

          let arr = [];

          if (lastKey === lastPaymentId) {
            return { ...state, lastPaymentId: null };
          }

          Object.keys(payload).map((key, index) => {
            arr.push({
              ...payload[key],
              dbId: key,
              chargeId: (payload[key] && payload[key].chargeId) ? payload[key].chargeId : null,
              companyName: (payload[key] && payload[key].companyName) ? payload[key].companyName : payload[key].destination,
              companyId: (payload[key] && payload[key].companyId) ? payload[key].companyId : null,
              customerEmail: (payload[key] && payload[key].customerEmail) ? payload[key].customerEmail : payload[key].customer,
              customerId: (payload[key] && payload[key].customerId) ? payload[key].customerId : null,
              customerName: (payload[key] && payload[key].customerName) ? payload[key].customerName : null,
              voucherId: (payload[key] && payload[key].voucherId) ? payload[key].voucherId : null,
              voucherName: (payload[key] && payload[key].voucherName) ? payload[key].voucherName : null,
              discount: (payload[key] && payload[key].discount) ? payload[key].discount : null,
              discountStep: (payload[key] && payload[key].discountStep) ? payload[key].discountStep : null,
              faceValue: (payload[key] && payload[key].faceValue) ? payload[key].faceValue : null,
              gift: (payload[key] && payload[key].gift) ? payload[key].gift : null,
              // gift: (payload[key] && payload[key].buyFor && payload[key].buyFor === 'gift') ? true : null,
              giftingData: (payload[key] && payload[key].giftingData) ? payload[key].giftingData : null,
              redeemed: (payload[key] && payload[key].redeemed && payload[key].redeemed === 'true') ? payload[key].redeemed : null,
              refunded: (payload[key] && payload[key].refunded) ? payload[key].refunded : false,
              refundValue: (payload[key] && payload[key].refundValue) ? payload[key].refundValue : null,
              refundDate: (payload[key] && payload[key].refundDate) ? payload[key].refundDate : null,
            });
          });

          if (sortType === 'asc') {
            arr.sort((a, b) => {
              if (b[orderBy] > a[orderBy]) {
                return 1;
              } else {
                return -1;
              }
            });
          } else {
            arr.sort((a, b) => {
              if (a[orderBy] > b[orderBy]) {
                return 1;
              } else {
                return -1;
              }
            });
          }

          let newPayments = [...adminPayments, ...arr.reverse()];

          return { ...state, adminPayments: newPayments, lastPaymentId: lastKey };
        }





        case SORT_ADMIN_PAYMENTS: {
          const { typeFilter, sortColumn, sortType } = action;
          const { adminPayments } = state;

            if (sortType && sortColumn) {
              if (sortType === 'desc') {
                adminPayments.sort((a, b) => {
                  if (b[sortColumn] > a[sortColumn]) {
                    return 1;
                  } else {
                    return -1;
                  }
                });
              } else if (sortType === 'asc') {
                adminPayments.sort((a, b) => {
                  if (a[sortColumn] > b[sortColumn]) {
                    return 1;
                  } else {
                    return -1;
                  }
                });
              }
            }

            return { ...state, adminPayments: adminPayments };
        }

        case SORT_ADMIN_DATA: {
          const { typeFilter, sortColumn, sortType } = action;

          console.log('SORT_ADMIN_DATA', action);

          let dataKey;
          if (typeFilter === 'companies') {
            dataKey = 'companyOwners';
          } else if (typeFilter === 'admins') {
            dataKey = 'admins';
          }
          if (!dataKey) {
            return state;
          }

          const currentData = state[dataKey];

          console.log('currentData', currentData);

          if (sortType && sortColumn) {
            if (sortColumn === 'lastActivity') {
              if (sortType === 'desc') {
                currentData.sort((a, b) => {
                  if (b[sortColumn] && a[sortColumn]) {
                    if (b[sortColumn].seconds > a[sortColumn].seconds) {
                      return 1;
                    } else {
                      return -1;
                    }
                  } else if (b[sortColumn]) {
                    return 1;
                  } else if (a[sortColumn]) {
                    return -1;
                  } else {
                    return 0;
                  }
                });
              } else if (sortType === 'asc') {
                currentData.sort((a, b) => {
                /*  if (a[sortColumn] > b[sortColumn]) {
                    return 1;
                  } else {
                    return -1;
                  } */
                  if (b[sortColumn] && a[sortColumn]) {
                    if (b[sortColumn].seconds < a[sortColumn].seconds) {
                      return 1;
                    } else {
                      return -1;
                    }
                  } else if (b[sortColumn]) {
                    return-1;
                  } else if (a[sortColumn]) {
                    return 1;
                  } else {
                    return 0;
                  }
                });
              }
            } else {
              if (sortType === 'desc') {
                currentData.sort((a, b) => {
                  if (b[sortColumn] > a[sortColumn]) {
                    return 1;
                  } else {
                    return -1;
                  }
                });
              } else if (sortType === 'asc') {
                currentData.sort((a, b) => {
                  if (a[sortColumn] > b[sortColumn]) {
                    return 1;
                  } else {
                    return -1;
                  }
                });
              }
            }
          }

            return { ...state, [dataKey]: currentData };
        }

        case OPEN_COMPANY_DASHBOARD: {
          return { ...state, adminViewAsCompany: action.payload, adminViewAsCompanyName: action.companyName, adminViewAsCompanyReturnPath: action.path };
        }
        case LEAVE_COMPANY_DASHBOARD: {
          return { ...state, adminViewAsCompany: null, adminViewAsCompanyName: null };
      }

      case GREETING_TAB: {
        return { ...state, greetingEmail: action.payload, };
      }

      case GIFTING_PRICE: {
        return { ...state, giftingPrice: action.payload, };
      }

      case APP_GIFT_PREVIEW_LINK: {
        return { ...state, appGiftPreviewLink: action.payload, };
      }

      default:
        return state;
    }
};

export default userReducer;
