import { forEach, get } from 'lodash';

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const hexToRGB = (hex, alpha) => {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

export const formatWebsite = (website) =>
  website.replace('https://', '').replace('http://', '').replace('www.', '');

export const canBuyVoucher = (voucherData, orders) => {
  // {
  //   statusCodes:
  //
  //   1: available
  //   2: bought
  //   3: notAvailable
  // }

  const vouchers = voucherData.availableVouchers;

  let voucherCanBuy = {
    1: {
      1: { status: get(orders, [1, 0]) ? 2 : 1 },
      2: { status: get(orders, [1, 1]) ? 2 : 1 },
      3: { status: get(orders, [1, 2]) ? 2 : 1 },
    },
    2: {
      1: { status: get(orders, [2, 0]) ? 2 : 1 },
      2: { status: get(orders, [2, 1]) ? 2 : 1 },
      3: { status: get(orders, [2, 2]) ? 2 : 1 },
    },
    3: {
      1: { status: get(orders, [3, 0]) ? 2 : 1 },
      2: { status: get(orders, [3, 1]) ? 2 : 1 },
      3: { status: get(orders, [3, 2]) ? 2 : 1 },
    },
  };

  forEach(voucherCanBuy, (vouchersPerStep, step) => {
    forEach(vouchersPerStep, (voucherAvailable, voucherIndex) => {
      if (voucherAvailable.status === 2) {
        return true;
      }
      if (step == 1) {
        if (voucherData.salesValueLeft1Step < vouchers[voucherIndex - 1]) {
          voucherCanBuy = {
            ...voucherCanBuy,
            [step]: { ...voucherCanBuy[step], [voucherIndex]: { status: 3 } },
          };
        }
      }

      if (step == 2) {
        if (
          voucherData.salesValueLeft2Step < vouchers[voucherIndex - 1] ||
          voucherCanBuy[1][voucherIndex].status == 3
        ) {
          voucherCanBuy = {
            ...voucherCanBuy,
            [step]: { ...voucherCanBuy[step], [voucherIndex]: { status: 3 } },
          };
        }
      }

      if (step == 3) {
        if (
          voucherData.salesValueLeft3Step < vouchers[voucherIndex - 1] ||
          voucherCanBuy[2][voucherIndex].status == 3
        ) {
          voucherCanBuy = {
            ...voucherCanBuy,
            [step]: { ...voucherCanBuy[step], [voucherIndex]: { status: 3 } },
          };
        }
      }
    });
  });

  return voucherCanBuy;
};

/*
export const saveCategoryData = async (uri, svg) => {
  const oldItem = await AsyncStorage.getItem('categories-image');
  const newData = {
    ...JSON.parse(oldItem),
    [uri]: svg,
  };

  await AsyncStorage.setItem('categories-image', JSON.stringify(newData));
}; */
