import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { Eye, EyeOff } from 'react-feather';

import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  CardText,
  CardTitle,
  CardSubtitle,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Alert,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';

import 'rsuite-table/dist/css/rsuite-table.css';

import { connect } from 'react-redux';

import { storage } from '../../../lib/firebase';

import {
  getVoucherList,
  getSingleVoucher,
  getVoucherCompanies,
  getVoucherCategories,
  doPayment,
  // loginAppUser,
} from '../../../actions/customer';

import { loginAppUser, getAppUserData } from '../../../actions/user'; 

import line from '../../../assets/images/line.svg';

import notifications from '../../../const/notifications';

const pound = `${'\u00A3'}`;

class VouchersList extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    getVouchers: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      enabled: false,
      deleteSettingModal: false,
      deleteSettingErr: null,
      settingToDelete: null,
      activeTab: '1',
      companyNotification: '',
      allChecked: true,
      checked: {},
      companyNotifications: [],
      companyInformation: '',
      selectedStep: 1,
      purchaseModal: false,
      showPassword1: 'password',
    };
  }

  componentDidMount() {
    const { user, history, getVouchers, fetchVoucherCompanies, fetchVoucherCategories, fetchUserData } = this.props;

    getVouchers();
    fetchVoucherCompanies();
    fetchVoucherCategories();

    console.log('componentDidMount', user);

    // if (user && user.uid) {
      fetchUserData();
    // }
  }

  fetchDisablePerksTab = () => {
    const { fetchDisablePerksTab } = this.props;

    fetchDisablePerksTab().then((res2) => {
      console.log('fetchDisablePerksTab', res2);

      this.setState({ disablePerks: res2 });
    })
  }




  openPreview = async (voucherId) => {
    const { vouchersList, fetchSingleVoucher } = this.props;

    console.log('openPreview', voucherId, vouchersList[voucherId]);

    const { logo, image, images, discount, availableVouchers } = vouchersList[voucherId];

    fetchSingleVoucher(voucherId).then(async (res) => {
      console.log('fetchSingleVoucher', res.payload);

      const { companyData } = res.payload;

      console.log('companyData', companyData);

      const { primaryVoucherImage, logo } = companyData;

      let companyPrimaryVoucherImage;
      let logoImage;

      if (primaryVoucherImage && logo) {
        console.log('test aaaa');
        const primaryVoucherImageReference = storage.ref(primaryVoucherImage);
        const logoReference = storage.ref(logo);

        console.log('test bbbb');

        await primaryVoucherImageReference.getDownloadURL().then((url) => {
          companyPrimaryVoucherImage = url;
        }).catch((error) => {
          console.log('get image error', { error });
        });

        console.log('test cccc');

        await logoReference.getDownloadURL().then((url) => {
          logoImage = url;
        }).catch((error) => {
          console.log('get image error', { error });
        });

        this.setState({ previewModal: true, previewVoucher: vouchersList[voucherId], primaryVoucherImage: companyPrimaryVoucherImage || null, logo: logoImage || null, discount, availableVouchers });
      }
    });
  }

  closePreview = () => {
    this.setState({ previewModal: false, primaryVoucherImage: null, logo: null });
  }

  toggleReadMore = () => {
    this.setState((prevState) => ({ readMore: !prevState.readMore }))
  }

  togglePreviewStep = (step) => {
    this.setState({ previewStep: step });
  }

  openPurchaseModal = (selectedVoucher) => {
    const { selectedStep } = this.state;
    console.log('openPurchaseModal', selectedStep, selectedVoucher);

    this.setState({ purchaseModal: true, selectedVoucher });
  }

  closePurchaseModal = () => {
    console.log('closePurchaseModal');

    this.setState({ purchaseModal: false, selectedVoucher: null });
  }

  finishPayment = () => {
    const { doPaymentFunc } = this.props;
    const { selectedStep, selectedVoucher } = this.state;

    console.log('finishPayment', selectedStep, selectedVoucher);
  }

  changeStep = (step) => {
    console.log('changeStep', step);

    this.setState({ selectedStep: step });
  }

  changeActiveCategory = (id) => {
    const { getVouchers } = this.props;

    getVouchers(null, id).then((res) => {
      console.log('getVouchers res', res);
    });

    this.setState({ activeCategory: id });
  }




  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = (e) => {
    e.preventDefault();

    const { login, history, fetchUserData, getVouchers, fetchVoucherCompanies, fetchVoucherCategories } = this.props;
    const { email, password, loading } = this.state;

    if (loading) return;

    this.setState({ loading: true });

    console.log('handleSubmit', email, password);

    return login({ email, password })
      .then((res) => {
        console.log('login then', { res });

        fetchUserData();

        getVouchers();
        fetchVoucherCompanies();
        fetchVoucherCategories();
      })
      .catch((err) => {
        console.log('login error', err);

        console.log(notifications.login);
        if (err === 'missing email') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.noEmail || err });
        } else if (err === 'missing password') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.noPassword || err });
        } else if (err === 'The email address is badly formatted.') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.badEmail || err });
        } else if (err === 'To access your account please click the verification link in the email sent to you.') {
          this.setState({ loading: false, errorGreen: true, error: notifications.login.needVerification || err });
        } else if (err === 'The password is invalid or the user does not have a password.' || 'There is no user record corresponding to this identifier. The user may have been deleted.') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.wrongLogin || err });
        } else {
          this.setState({ loading: false, errorGreen: false, error: err });
        }
      });
  }


  toggleShowPassword = (id) => {
    console.log('toggleShowPassword');
    console.log(id);
    if (id === 1) {
      //  this.setState((prevState) => ({ showPassword1: !prevState.showPassword1 }));

      this.setState({
        showPassword1: this.state.showPassword1 === 'input' ? 'password' : 'input'
      })
    } else if (id === 2) {
      //  this.setState((prevState) => ({ showPassword2: !prevState.showPassword2 }));
      this.setState({
        showPassword2: this.state.showPassword2 === 'input' ? 'password' : 'input'
      })
    }
  }

  render() {
    const { user, vouchersList, companies, categories } = this.props;
    const {
      activeTab,
      dontShowPage,
      perks,
      perksTabTitle,

      previewModal,
      previewStep,
      readMore,
      primaryVoucherImage,
      logo,
      discount,
      availableVouchers,
      previewVoucher,

      purchaseModal,
      selectedStep,
      selectedVoucher,
      activeCategory,

      email,
      password,
      showPassword1,
      loading,
      error,
      errorGreen,
    } = this.state;

    console.log('vouchersList', vouchersList, companies, categories, previewModal);

    if (!user || !user.uid) {
      return (
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <Card>
              <CardBody>
                {(!!error) && <Alert color={errorGreen ? "success" : "danger"}>{error}</Alert>}
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      name="email"
                      id="email"
                      placeholder="john@doe.corp"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup style={{ position: 'relative' }}>
                    <Label for="password">Password</Label>
                    <Input
                      type={showPassword1}
                      name="password"
                      id="password"
                      placeholder="******"
                      value={password}
                      onChange={this.handleChange}
                    />
                    {showPassword1 === 'input' ? (
                      <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem', width: 20 }} onClick={() => this.toggleShowPassword(1)} />
                    ) : (
                      <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem', width: 20 }} onClick={() => this.toggleShowPassword(1)} />
                    )}
                  </FormGroup>
                  <Button disabled={loading} id="sign-in-button" className="custom-primary-button" color="primary">
                    Login
                    {loading ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                    ) : (null)}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )
    }

    return (
      <div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div>
              <Row>
                <Col onClick={() => this.changeActiveCategory(null)}>
                  <img
                    src={null}
                    style={{ borderRadius: 10, width: 40, aspectRatio: '1:1', border: !activeCategory ? '1px solid #6FC0F9' : '0px' }}
                  />
                  <div>All</div>
                </Col>
                {categories ? Object.keys(categories).map(key => (
                  <Col onClick={() => this.changeActiveCategory(categories[key].id)}>
                    <img
                      src={categories[key].imageUrl}
                      style={{ borderRadius: 10, width: 40, aspectRatio: '1:1', border: activeCategory === categories[key].id ? '1px solid #6FC0F9' : '0px' }}
                    />
                    <div>{categories[key].name}</div>
                  </Col>
                )) : (null)}
              </Row>
              <Row>
                {vouchersList ? Object.keys(vouchersList).map(key => (
                  <Col xs="12" sm="6" md="4" lg="3">
                    <Card
                      className="perks-card"
                      onClick={() => this.openPreview(key)}
                    >
                      <div style={{ borderBottom: '1px solid #EEEEEE', background: '#FFF' }}>
                        <img
                          alt={vouchersList[key].name}
                          src={vouchersList[key].primaryImage}
                          className="perks-card-image"
                        />
                      </div>
                      <CardBody style={{ background: '#6FC0F9', height: 155 }}>
                        <div style={{ width: '20%', display: 'inline-block', height: '100%', verticalAlign: 'top' }}>
                          <img
                            src={vouchersList[key].logo}
                            // className="perks-card-image"
                            style={{ borderRadius: 10, width: '100%', aspectRatio: '1:1' }}
                          />
                        </div>
                        <div style={{ width: '80%', display: 'inline-block', paddingLeft: 5 }}>
                          <CardTitle tag="h5" style={{ color: '#FFF', textDecoration: 'none' }}>
                            <a
                              href={vouchersList[key].link}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: '#FFF', textDecoration: 'none' }}
                            >
                              {vouchersList[key].name}
                            </a>
                          </CardTitle>
                          <CardSubtitle
                            style={{ color: '#FFF' }}
                            className="mb-2"
                            tag="h6"
                          >
                            {vouchersList[key].description}
                          </CardSubtitle>
                          <CardText style={{ color: '#FFF', fontWeight: 'bold' }}>
                            {vouchersList[key].summary}
                          </CardText>
                        </div>
                      </CardBody>
                    </Card>

                  </Col>
                )) : null}
              </Row>
            </div>
          </TabPane>
        </TabContent>


        <Modal isOpen={previewModal} toggle={this.closePreview}>
          <ModalHeader toggle={this.closePreview}>
            Purchase voucher
          </ModalHeader>
          <ModalBody>
            <div className="voucher-preview" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <div className="voucher-preview-top">
                {primaryVoucherImage ? (
                  <Media className="voucher-preview-top-image" src={primaryVoucherImage} />
                ) : null}
              </div>

              <div className="voucher-preview-header">
                {logo ? (
                  <>
                    <Media className="voucher-preview-header-logo" src={logo} />
                    <div className="voucher-preview-header-text">
                      <div className="voucher-preview-header-name">
                        {previewVoucher ? previewVoucher.name : ''}
                      </div>
                      <div className="voucher-preview-header-website">
                        {(previewVoucher && previewVoucher.website) ? previewVoucher.website.replace(/(^\w+:|^)\/\//, '') : ''}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              <div className="voucher-preview-body">
                <div className={readMore ? 'voucher-preview-body-description' : 'voucher-preview-body-description-hidden'}>
                  {previewVoucher ? previewVoucher.description : ''}
                </div>
                <Button className="voucher-preview-body-more" color="link" onClick={this.toggleReadMore}>
                  {readMore ? 'show less' : 'read more'}
                </Button>
              </div>
              <div className="voucher-preview-loyalty-text"> Loyalty steps </div>
              <div className="voucher-preview-footer">
                <div className="voucher-preview-steps">
                  <Media className="voucher-preview-steps-image" src={line} />
                  <div className={`voucher-purchase-step-1 ${selectedStep === 1 ? 'voucher-purchase-step-active' : ''}`} onClick={() => this.changeStep(1)}>
                    <div className="voucher-preview-steps-percent">
                      {discount ? `${discount['1']}%` : null}
                    </div>
                    <div className="voucher-preview-steps-name">
                      Step 1
                    </div>
                  </div>
                  {/*voucher-preview-steps-1*/}
                  <div className={`voucher-purchase-step-2 ${selectedStep === 2 ? 'voucher-purchase-step-active' : ''}`} onClick={() => this.changeStep(2)}>
                    <div className="voucher-preview-steps-percent">
                      {discount ? `${discount['2']}%` : null}
                    </div>
                    <div className="voucher-preview-steps-name">
                      Step 2
                    </div>
                  </div>
                  <div className={`voucher-purchase-step-3 ${selectedStep === 3 ? 'voucher-purchase-step-active' : ''}`} onClick={() => this.changeStep(3)}>
                    <div className="voucher-preview-steps-percent">
                      {discount ? `${discount['3']}%` : null}
                    </div>
                    <div className="voucher-preview-steps-name">
                      Step 3
                    </div>
                  </div>
                </div>

                <div className="voucher-preview-vouchers">
                  <div className="voucher-preview-voucher">
                    <div className="voucher-preview-vouchers-left">
                      <div className="voucher-preview-vouchers-left-1">
                        {`${pound}${availableVouchers ? availableVouchers['0'] : null}`}
                      </div>
                      <div className="voucher-preview-vouchers-left-2">
                        VOUCHER
                      </div>
                    </div>
                    <div className="voucher-preview-vouchers-right" onClick={() => this.openPurchaseModal(0)}>
                      <div className="voucher-preview-vouchers-right-1">
                        BUY FOR
                      </div>
                      <div className="voucher-preview-vouchers-right-2">
                        {(availableVouchers && discount && availableVouchers['0'] && discount['1']) ? `${pound}${(availableVouchers['0'] - (availableVouchers['0'] * (discount['1'] / 100)))}` : null}
                      </div>
                    </div>
                  </div>
                  <div className="voucher-preview-voucher">
                    <div className="voucher-preview-vouchers-left">
                      <div className="voucher-preview-vouchers-left-1">
                        {`${pound}${availableVouchers ? availableVouchers['1'] : null}`}
                      </div>
                      <div className="voucher-preview-vouchers-left-2">
                        VOUCHER
                      </div>
                    </div>
                    <div className="voucher-preview-vouchers-right">
                      <div className="voucher-preview-vouchers-right-1" onClick={() => this.openPurchaseModal(1)}>
                        BUY FOR
                      </div>
                      <div className="voucher-preview-vouchers-right-2">
                        {(availableVouchers && discount && availableVouchers['1'] && discount['2']) ? `${pound}${(availableVouchers['1'] - (availableVouchers['1'] * (discount['2'] / 100)))}` : null}
                      </div>
                    </div>
                  </div>
                  <div className="voucher-preview-voucher">
                    <div className="voucher-preview-vouchers-left">
                      <div className="voucher-preview-vouchers-left-1">
                        {`${pound}${availableVouchers ? availableVouchers['2'] : null}`}
                      </div>
                      <div className="voucher-preview-vouchers-left-2">
                        VOUCHER
                      </div>
                    </div>
                    <div className="voucher-preview-vouchers-right" onClick={() => this.openPurchaseModal(2)}>
                      <div className="voucher-preview-vouchers-right-1">
                        BUY FOR
                      </div>
                      <div className="voucher-preview-vouchers-right-2">
                        {(availableVouchers && discount && availableVouchers['2'] && discount['3']) ? `${pound}${(availableVouchers['2'] - (availableVouchers['2'] * (discount['3'] / 100)))}` : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          {/* <ModalFooter>
            <Button color="secondary" onClick={this.closePreview}>Close</Button>
          </ModalFooter> */}
        </Modal>




        <Modal isOpen={purchaseModal} toggle={this.closePurchaseModal}>
          <ModalHeader toggle={this.closePurchaseModal}>
            Purchase voucher
          </ModalHeader>
          <ModalBody>
            <div >
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.finishPayment}>Buy</Button>
            <Button color="secondary" onClick={this.closePurchaseModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  vouchersList: state.customer.list || {},
  companies: state.customer.companies || {},
  categories: state.customer.categories || {},
});

const mapDispatchToProps = {
  getVouchers: getVoucherList,
  fetchSingleVoucher: getSingleVoucher,
  fetchVoucherCompanies: getVoucherCompanies,
  fetchVoucherCategories: getVoucherCategories,
  doPaymentFunc: doPayment,
  login: loginAppUser,
  fetchUserData: getAppUserData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VouchersList));