import { forEach, get } from 'lodash';

// import { firestore, auth, functions, storage } from '../../fb/firebase';

import { firestore, functions, storage, auth } from '../../lib/firebase';


import {
  ADD_GIFTING_DATA,
  GET_ORDERED_TEMPLATE,
  GET_SINGLE_ORDER,
  GET_SINGLE_VOUCHER,
  GET_USER_VOUCHERS,
  CUSTOMER_GET_VOUCHER_CATEGORIES,
  CUSTOMER_GET_VOUCHER_COMPANIES,
  CUSTOMER_GET_VOUCHER_LIST,
  GET_VOUCHER_ORDERS,
  //SET_VOUCHER_LIST_FETCHING,
  //SET_VOUCHER_ORDERS_FETCHING,

  REMOVE_PAYMENT_DATA,
  SAVE_PAYMENT_DATA,
  GET_PAYMENT_PROGRESS,
} from '../index';

import { DB_TABLE_NAME } from '../../const/firebase';
// import { BUY_FOR } from '../../modules/const';
import { canBuyVoucher } from '../../modules/handlers';

export const getSingleVoucher = (voucher) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('getSingleVoucher', voucher);

  const singleVoucher = firestore.collection(DB_TABLE_NAME.vouchers).doc(voucher);

  singleVoucher.get().then((payload) => {
    const voucherData = payload.data();
    const companyData = firestore
      .collection(DB_TABLE_NAME.companies)
      .doc(voucherData.company.id)
      .get();

    console.log('getSingleVoucher payload', payload);

    companyData.then((company) => {
      console.log('companyData then', company);
      dispatch({
        payload: { ...voucherData, id: voucher, companyData: company.data() },
        voucher,
        type: GET_SINGLE_VOUCHER,
      });

      return resolve({
        payload: { ...voucherData, id: voucher, companyData: company.data() },
        voucher,
        type: GET_SINGLE_VOUCHER,
      });
    }).catch(err => {
      console.log('companyData err', err);
    });
  });
});

export const getVoucherList = (onSuccess, activeCategory) => async (dispatch, getState) => {
  // const filters = getState().filters;

 // dispatch({
 //   type: SET_VOUCHER_LIST_FETCHING,
 //   payload: true,
 // });

  console.log('getVoucherList', activeCategory);

  const companiesList = {};

  const companies = firestore.collection(DB_TABLE_NAME.companies);
  companies.get().then(async (querySnapshot) => {
    let payload = {};
    querySnapshot.forEach((doc) => {
      companiesList[doc.id] = { ...doc.data() };
    });

    console.log('companiesList', companiesList);

    dispatch({
      type: CUSTOMER_GET_VOUCHER_COMPANIES,
      payload,
    });

  let vouchers = firestore
    .collection(DB_TABLE_NAME.vouchers)
    // .orderBy(filters.sortValues.sort, filters.sortValues.sortMethod)
    .where('isActive', '==', true)
    .where('frozen', '==', false)
    .limit(1000);

  if (activeCategory && activeCategory === 'unlocked') {
    return dispatch(getUnlockedVouchers());
  }

  console.log('test 111');

  const vouchersOrder = firestore.collection(DB_TABLE_NAME.orders);
  console.log('test 222');

  const orders = await vouchersOrder.where('uid', '==', auth.currentUser.uid).get();
  console.log('test 333');

  let userOrders = {};

  if (orders) {
    orders.docs.map((doc, index) => {
      const docData = doc.data();
      const id = doc.id;

      userOrders[id] = docData;
    });

    /*
    for (const doc of orders.docs) {
      const document = doc.data();

      console.log('document', document);

      userOrders = {
        ...userOrders,
        [document.voucher.id]: {
          ...get(userOrders, document.voucher.id, {}),
          [document.voucherStep]: {
            ...get(userOrders, [document.voucher.id, document.voucherStep], {}),
            [document.voucherId]: true,
          },
        },
      };
    } */
  } else {
    console.log('no orders');
  }

  // console.log('userOrders', userOrders);

  const voucherBoughtByUser = await firestore.collection(DB_TABLE_NAME.vouchersBoughtByUser).doc(auth.currentUser.uid);

  console.log('test 444');

  const allUserVouchersFullyBought = await voucherBoughtByUser.collection('vouchers').get();

  const userUid = auth.currentUser.uid;
  const userSetting = await firestore
    .collection(DB_TABLE_NAME.appClients)
    .doc(userUid)
    .get();

  console.log('test 555');

  let allUserVouchersFullyBoughtList = {};

  allUserVouchersFullyBought.forEach((doc) => {
    allUserVouchersFullyBoughtList = {
      ...allUserVouchersFullyBoughtList,
      [doc.id]: doc.data(),
    };
  });

  console.log('test 666');

  if (activeCategory) {
    const cat = firestore.collection(DB_TABLE_NAME.categories).doc(activeCategory);

    vouchers = firestore
      .collection(DB_TABLE_NAME.vouchers)
      .where('frozen', '==', false)
      .where('isActive', '==', true)
      .where('category', 'array-contains-any', [cat])
      // .orderBy(filters.sortValues.sort, filters.sortValues.sortMethod);
    }

    const promises = [];

  vouchers.get().then(function (querySnapshot) {
      let payload = {};
      querySnapshot.forEach(async (doc) => promises.push(new Promise(async (resolve2) => {
        const voucherData = doc.data();

        const smallerVoucher = voucherData.availableVouchers[0];

        const company = voucherData.company;

        console.log('vouchers company', company.id, companiesList[company.id]);

        const { primaryVoucherImage, logo } = companiesList[company.id];

        const primaryVoucherImageReference = storage.ref(primaryVoucherImage);
        const logoReference = storage.ref(logo);

        let companyPrimaryVoucherImage;
        let logoImage;

        console.log('primaryVoucherImage, logo', primaryVoucherImage, logo);

        if (primaryVoucherImage && logo) {
          await primaryVoucherImageReference.getDownloadURL().then((url) => {
            companyPrimaryVoucherImage = url;
          }).catch((error) => {
            console.log('get image error', { error, primaryVoucherImage });
          });

          console.log('test cccc');

          await logoReference.getDownloadURL().then((url) => {
            logoImage = url;
          }).catch((error) => {
            console.log('get image error', { error, logo });
          });
        }

        const canBuyVoucherArray = canBuyVoucher(voucherData, get(userOrders, [doc.id]));

        let hide = false;
        /* 
        forEach(canBuyVoucherArray, (v) => {
          forEach(v, (va) => {
            if (va.status !== 3 && va.status !== 2) {
              hide = false;
            }
          });
        }); */

        if (hide) {
          return resolve2();
        }

        if (voucherData.salesValueLeft1Step < smallerVoucher && !get(userOrders, [doc.id, 1])) {
          return resolve2();
        }

        if (voucherData.salesValueLeft2Step < smallerVoucher && voucherData.salesValueLeft1Step < smallerVoucher && voucherData.salesValueLeft3Step < smallerVoucher) {
          return resolve2();
        }

        if (voucherData.salesValueLeft2Step < smallerVoucher && !get(userOrders, [doc.id, 2])) {
          return resolve2();
        }

        if (voucherData.testMode) {
          if (!userSetting || !userSetting.data() || !userSetting.data().tester) {
            return resolve2();
          }
        }

        if (!allUserVouchersFullyBoughtList[doc.id] || allUserVouchersFullyBoughtList[doc.id].countBought < 9) {
          if (voucherData.availableVouchers[0] <= voucherData.salesValueLeft1Step ||
            voucherData.availableVouchers[0] <= voucherData.salesValueLeft2Step ||
            voucherData.availableVouchers[0] <= voucherData.salesValueLeft3Step
          ) {
            payload = { ...payload, [doc.id]: { ...voucherData, companyId: company.id, logo: logoImage, primaryImage: companyPrimaryVoucherImage } };
            console.log('add to payload', payload);
          }
        }

        return resolve2();
      })));
      if (onSuccess) {
        onSuccess();
      }

    Promise.all(promises).then(() => {
      console.log('payload', payload);

      dispatch({
        type: CUSTOMER_GET_VOUCHER_LIST,
        payload,
      });
    }).catch(err => {
      console.log('promises err', err);
    })
    //  dispatch({
    //    type: SET_VOUCHER_LIST_FETCHING,
    //    payload: false,
    //  });
    })
    .catch((err) => {
      console.log(err);
    });
  });
};

export const getVoucherCategories = () => (dispatch) => {
  const vouchers = firestore.collection(DB_TABLE_NAME.categories);
  vouchers
    .where('enabled', '==', true)
    .orderBy('order', 'asc')
    .get()
    .then(function (querySnapshot) {
      let payload = {};
      const promises = [];

      querySnapshot.forEach((doc) => promises.push(new Promise(async (resolve2) => {
        const docData = doc.data();
        // payload = { ...payload, [doc.id]: { ...docData, id: doc.id } };

        storage.ref(docData.icon).getDownloadURL().then(async (url) => {
        //  const response = await fetch(url);
        //  const svg = await response.text();

          payload = { ...payload, [doc.id]: { ...docData, id: doc.id, imageUrl: url } };

          return resolve2();
            // saveCategoryData(docData.icon, svg);
        }).catch(() => {
          return resolve2();
        });
      })));

      Promise.all(promises).then(() => {
        dispatch({
          type: CUSTOMER_GET_VOUCHER_CATEGORIES,
          payload,
        });
      });

    });
};

export const getVoucherCompanies = () => (dispatch) => {
  const vouchers = firestore.collection(DB_TABLE_NAME.companies);
  vouchers.get().then(function (querySnapshot) {
    let payload = {};
    querySnapshot.forEach(function (doc) {
      payload = { ...payload, [doc.id]: doc.data() };
    });
    dispatch({
      type: CUSTOMER_GET_VOUCHER_COMPANIES,
      payload,
    });
  });
};

export const getVoucherOrders = (voucherId, onSuccess) => (dispatch) => {
//  dispatch({
//    type: SET_VOUCHER_ORDERS_FETCHING,
//    payload: true,
//  });
  const vouchersOrder = firestore.collection(DB_TABLE_NAME.orders);
  const vouchersDoc = firestore
    .collection(DB_TABLE_NAME.vouchers)
    .doc(voucherId);
  vouchersOrder
    .where('uid', '==', auth.currentUser.uid)
    .where('voucher', '==', vouchersDoc)
    .get()
    .then(function (querySnapshot) {
      let payload = {};
      querySnapshot.forEach(function (doc) {
        const document = doc.data();
        payload = {
          ...payload,
          [document.voucherId]: {
            ...get(payload, document.voucherId, {}),
            [document.voucherStep]: document,
          },
        };
      });
      dispatch({
        type: GET_VOUCHER_ORDERS,
        payload,
      });
    //  dispatch({
    //    type: SET_VOUCHER_ORDERS_FETCHING,
    //    payload: false,
    //  });
      if (onSuccess) {
        onSuccess();
      }
    });
};

export const getUnlockedVouchers = (onSuccess) => (dispatch) => {
  const vouchersOrder = firestore.collection(DB_TABLE_NAME.vouchersUnlocked);
  vouchersOrder
    .where('uid', '==', auth.currentUser.uid)
    .get()
    .then(function (querySnapshot) {
      let payload = {};
      querySnapshot.forEach(function (doc) {
        const document = doc.data();
        payload = {
          ...payload,
          [`${document.voucher.id}_${document.level}`]: {
            ...document,
            voucherId: document.voucher.id,
          },
        };
      });
      dispatch({
        type: CUSTOMER_GET_VOUCHER_LIST,
        payload,
      });
     // dispatch({
    //    type: SET_VOUCHER_LIST_FETCHING,
    //    payload: false,
    //  });
      if (onSuccess) {
        onSuccess();
      }
    });
};

export const getOrderedVouchers =
  (usedVouchers = false, onSuccess) =>
    (dispatch) => {
      const vouchersOrder = firestore.collection(DB_TABLE_NAME.orders);
      vouchersOrder
        .where('uid', '==', auth.currentUser.uid)
        .where('used', '==', usedVouchers)
        .where('buyFor', '==', 'self')
        .orderBy('paymentDate', 'desc')
        .get()
        .then(function (querySnapshot) {
          let payload = {};
          querySnapshot.forEach(function (doc) {
            const document = doc.data();
            payload = {
              ...payload,
              [`${document.voucher.id}_${document.code}`]: {
                ...document,
                id: doc.id,
              },
            };
          });
          dispatch({
            type: GET_USER_VOUCHERS,
            payload,
          });

          if (onSuccess) {
            onSuccess();
          }
        });
    };

export const getTemplateInfo = (template) => (dispatch) => {
  const templateOrdered = firestore
    .collection(DB_TABLE_NAME.templatesOrdered)
    .doc(template);
  templateOrdered.get().then((t) => {
    dispatch({
      type: GET_ORDERED_TEMPLATE,
      payload: t.data(),
    });
  });
};

export const getOrderInfo = (id, onSuccess) => (dispatch) => {
  const vouchersOrder = firestore.collection(DB_TABLE_NAME.orders).doc(id);
  vouchersOrder.get().then((orderData) => {
    const ratingsData = firestore.collection(DB_TABLE_NAME.ordersRating);

    ratingsData
      .where('order', '==', vouchersOrder)
      .where('uid', '==', auth.currentUser.uid)
      .get()
      .then(async (snapshot) => {
        let rating = 0;

        snapshot.forEach((r) => {
          if (r.data().rating > 0) {
            rating = r.data().rating;
          }
        });

        const codeStatus = functions.httpsCallable('checkVouchedCodeStatus');

        dispatch({
          type: GET_SINGLE_ORDER,
          payload: {
            ...orderData.data(),
            id,
            rating,
          },
        });
        if (onSuccess) {
          onSuccess();
        }
      });
  });
};

export const postOrderRating = (order, rating) => (dispatch) => {
  const ordersDoc = firestore.collection(DB_TABLE_NAME.orders).doc(order);
  const ordersRating = firestore.collection(DB_TABLE_NAME.ordersRating);
  ordersRating
    .add({
      order: ordersDoc,
      uid: auth.currentUser.uid,
      rating,
    })
    .then();
};

export const submitPayment =
  (voucher, voucherId, voucherStep, onSuccess) => (dispatch) => {
    const vouchersDoc = firestore
      .collection(DB_TABLE_NAME.vouchers)
      .doc(voucher);
    const orders = firestore.collection(DB_TABLE_NAME.orders);
    orders
      .add({
        voucher: vouchersDoc,
        voucherStep: Number(voucherStep),
        voucherId: Number(voucherId),
        uid: auth.currentUser.uid,
      })
      .then(dispatch(getVoucherOrders(voucher, onSuccess)));
  };

export const receiveGift = (code, onSuccess) => () => {
  const receiveGiftF = functions.httpsCallable('receiveGift');

  receiveGiftF({ code: code, uid: auth.currentUser.uid })
    .then(onSuccess)
    .catch((err) => {
      console.log(err);
    });
};

export const addGiftingDetails = (payload) => ({
  type: ADD_GIFTING_DATA,
  payload,
});







export const removePaymentData = (payload) => (dispatch) =>
  dispatch({
    type: REMOVE_PAYMENT_DATA,
    payload: {},
  });

export const savePaymentData = (payload) => (dispatch) =>
  dispatch({
    type: SAVE_PAYMENT_DATA,
    payload,
  });

export const doPayment = (paymentData, voucherData, templateOrdered) => async (dispatch) => {
    const userUid = auth.currentUser.uid;
    const orders = firestore.collection('payments').doc(userUid);

    let source;

    if (paymentData.card) {
      const createToken = functions.httpsCallable('stripeCreateCardSource');
      const sourceData = await createToken(paymentData);
      source = sourceData.data;
  }

    let templateData = null;
    if (templateOrdered) {
      templateData = firestore.collection(DB_TABLE_NAME.templatesOrdered).doc(templateOrdered);
    }
    orders.collection('payments').add({
        templateOrdered: templateData,
        amount: paymentData.amount,
        currency: paymentData.currency,
        userUid,
        voucherData,
        source: source || null,
      }).then((data) => {
        console.log('document');
        dispatch({
          type: GET_PAYMENT_PROGRESS,
          payload: { id: data.id },
        });
      }).catch((err) => {
        console.log(' error with document creation', err);
      });
  };

export const getPaymentStatus = (paymentId) => (dispatch) => {
  const userUid = auth.currentUser.uid;
  const payments = firestore.collection(DB_TABLE_NAME.payments).doc(userUid);
  // dispatch(setIsFetching(true));

  payments.collection('payments').doc(paymentId).get().then((data) => {
      const doc = data.data();
      if (doc.status || doc.response || doc.error) {
        dispatch({
          type: GET_PAYMENT_PROGRESS,
          payload: { id: data.id, ...data.data() },
        });

        // dispatch(setIsFetching(false));
      } else {
        dispatch(getPaymentStatus(paymentId));
      }
    });
};




export const loginAppUser = ({ email, password }) => (dispatch) => new Promise(async (resolve, reject) => {
  // dispatch(setIsFetching(true));

  console.log('loginAppUser', email, password);

  auth
    .signInWithEmailAndPassword(email, password)
    .then((res) => {
      const userDetails = res && res.user ? res.user : null;

      console.log('userDetails', userDetails);

      if (userDetails.emailVerified === false) {
        userDetails.sendEmailVerification().catch(() => console.log('Verification email failed to send'));

        console.log('Please verify your email.');
        return resolve();
        // dispatch(setIsFetching(false));
      } else {
        // dispatch();
        return resolve();
        // dispatch(setIsFetching(false));
      }
    })
    .catch((err) => {
      console.log(err.message);
      return resolve();
      // dispatch(setIsFetching(false));
    });
});