import Dashboard from './pages/Dashboard';
import BlankPage from './pages/BlankPage';
import SubNav from './pages/SubNav';

import ErrorPage from './pages/404';
import LoginPage from './pages/Login';
import ForgotPasswordPage from './pages/ForgotPassword';
import ResetPasswordPage from './pages/ResetPassword';
import SignupPage from './pages/SignUp';

import AdminDashboard from './pages/Admin/Dashboard/Dashboard';
import Companies from './pages/Admin/Companies';
import Customers from './pages/Admin/Customers/Customers';
// import AllRoles from './pages/Admin/AllRoles';
import PermissionLevels from './pages/Admin/PermissionLevels/PermissionLevels';
import Notifications from './pages/Admin/Notifications';
import Admins from './pages/Admin/Admins';
import AdminActions from './pages/Admin/AdminActions';
import Perks from './pages/Admin/Perks';
import FeaturedCampaigns from './pages/Admin/FeaturedCampaigns';

import ContentManagement from './pages/Admin/ContentManagement/ContentManagement';
// import SetupHelp from './pages/Admin/ResourceCenter/ResourceCenter';

import Campaigns from './pages/Admin/Campaigns/Campaigns';
import AdminPayments from './pages/Admin/Payments/Payments';

import Draggable from './pages/Admin/Draggable';
import GreetingCards from './pages/Admin/Greetings/GreetingCards';
import Greetings from './pages/Admin/Greetings/Greetings';

import Company from './pages/Company/Company';
import Vouchers from './pages/Company/Vouchers';
import CompanyDashboard from './pages/Company/Dashboard/Dashboard';
import NewVoucher from './pages/Company/NewVoucher';
import BasicInformation from './pages/Company/BasicInformation/BasicInformation';
import Payments from './pages/Company/Payments';
import Contact from './pages/Company/Contact';
import Api from './pages/Company/Api';
import CompanySetupHelp from './pages/Company/ResourceCenter/ResourceCenter';
import CompanyPerks from './pages/Company/Perks';

import TAndCPage from './pages/Footer/TAndCPage';
import PrivacyPolicyPage from './pages/Footer/PrivacyPolicyPage';
// import CookiePolicyPage from './pages/Footer/CookiePolicyPage';

import TAndCApp from './pages/Footer/TAndCApp';
import PrivacyPolicyApp from './pages/Footer/PrivacyPolicyApp';

import EmailVerify from './pages/User/EmailVerify';
import EmailVerifyUpdate from './pages/User/EmailVerifyUpdate';

import VouchersList from './pages/Customer/VouchersList';

// See React Router documentation for details: https://reacttraining.com/react-router/web/api/Route
const pageList = [
  {
    name: 'Email Verify',
    path: '/email-verify',
    component: EmailVerify,
  },
  {
    name: 'Email Verify',
    path: '/email-verify-update',
    component: EmailVerifyUpdate,
  },
  {
    name: 'Dashboard',
    path: '/home',
    component: Dashboard,
  },
  {
    name: 'Login',
    path: '/login',
    component: LoginPage,
    hidden: true,
  },
  {
    name: 'Reset Password',
    path: '/reset-password',
    component: ForgotPasswordPage,
  },
  {
    name: 'Reset Password',
    path: '/reset-password-confirm',
    component: ResetPasswordPage,
  },
  {
    name: 'Sign up',
    path: '/signup',
    component: SignupPage,
    hidden: true,
  },
  {
    name: 'Dashboard',
    path: '/admin/dashboard',
    component: AdminDashboard,
  },
  {
    name: 'Companies',
    path: '/admin/companies',
    component: Companies,
  },
  {
    name: 'Customers',
    path: '/admin/customers',
    component: Customers,
  },
  {
    name: 'All Roles',
    path: '/admin/roles',
    component: PermissionLevels,
  },
  {
    name: 'Admins',
    path: '/admin/admins',
    component: Admins,
  },
  {
    name: 'Admin Actions',
    path: '/admin/admin-actions',
    component: AdminActions,
  },
  {
    name: 'Perks',
    path: '/admin/perks',
    component: Perks,
  },
  {
    name: 'FeaturedCampaigns',
    path: '/admin/featured',
    component: FeaturedCampaigns,
  },
  {
    name: 'Notifications',
    path: '/admin/notifications',
    component: Notifications,
  },
  {
    name: 'Content Management',
    path: '/admin/content-management',
    component: ContentManagement,
  },
  {
    name: 'Campaigns',
    path: '/admin/campaigns',
    component: Campaigns,
  },
  {
    name: 'Payments',
    path: '/admin/payments',
    component: AdminPayments,
  },

  {
    name: 'Draggable',
    path: '/admin/draggable',
    component: Draggable,
  },
  {
    name: 'GreetingCards',
    path: '/admin/greeting-cards',
    component: GreetingCards,
  },
  {
    name: 'Gifting',
    path: '/admin/gifting',
    component: Greetings,
  },
  {
    name: 'New Greeting Card',
    path: '/admin/greeting-cards-new',
    component: Draggable,
  },



  {
    name: 'T&Cs',
    path: '/T&Cs',
    component: TAndCPage,
  },
  {
    name: 'App T&Cs',
    path: '/T&Cs-app',
    component: TAndCApp,
  },

  {
    name: 'Privacy Policy',
    path: '/privacy-policy',
    component: PrivacyPolicyPage,
  },
  {
    name: 'Privacy Policy App',
    path: '/privacy-policy-app',
    component: PrivacyPolicyApp,
  },


  {
    name: 'Users',
    path: '/users',
    component: Company,
  },
  {
    name: 'Dashboard',
    path: '/vouchers',
    component: Vouchers,
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: CompanyDashboard,
  },
  {
    name: 'Create New Campaign',
    path: '/new-voucher',
    component: NewVoucher,
  },
  {
    name: 'Basic Information',
    path: '/basic-information',
    component: BasicInformation,
  },
  {
    name: 'Payments',
    path: '/payments',
    component: Payments,
  },
  {
    name: 'Contact',
    path: '/contact',
    component: Contact,
  },
  {
    name: 'Website Connection',
    path: '/api',
    component: Api,
  },
  {
    name: 'Setup Help',
    path: '/setup-help',
    component: CompanySetupHelp,
  },
  {
    name: 'Perks',
    path: '/perks',
    component: CompanyPerks,
  },
  {
    name: 'VouchersList',
    path: '/VouchersList',
    component: VouchersList,
  },

  {
    name: 'Blank',
    path: '/pages/blank',
    component: BlankPage,
  },
  {
    name: 'Sub Navigation',
    path: '/pages/subnav',
    component: SubNav,
  },
  {
    name: '404',
    path: '/pages/404',
    component: ErrorPage,
  },
];

export default pageList;
