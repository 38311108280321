export default {
  client: [
    {
      name: 'Dashboard',
      url: '/dashboard',
    },
    {
      name: 'Create New Campaign',
      url: '/new-voucher',
    },
    {
      name: 'Perks',
      url: '/perks',
    },
    { name: 'divider' },
    {
      name: 'Payments',
      url: '/payments',
    },
    {
      name: 'Website Connection',
      url: '/api',
    },
    {
      name: 'Basic Information',
      url: '/basic-information',
    },
    { name: 'divider' },
    {
      name: 'Setup Help',
      url: '/setup-help',
    }
  ],
  signedOut: [
    {
      name: 'Login',
      url: '/login',
      icon: 'User',
    },
    {
      name: 'Sign up',
      url: '/signup',
      icon: 'User',
    }
  ],
  admin: [
    {
      name: 'Dashboard',
      url: '/admin/dashboard',
    },
    {
      name: 'Companies',
      url: '/admin/companies',
    },
    {
      name: 'Customers',
      url: '/admin/customers',
    },
    {
      name: 'Campaigns',
      url: '/admin/campaigns',
    },
    {
      name: 'Payments',
      url: '/admin/payments',
    },
    {
      name: 'Admins',
      url: '/admin/admins',
    },
    {
      name: 'Permission levels',
      url: '/admin/roles',
    },
    {
      name: 'Notifications',
      url: '/admin/notifications',
    },
    {
      name: 'Content Management',
      url: '/admin/content-management',
    },
    {
      name: 'Gifting',
      url: '/admin/gifting',
    },
    {
      name: 'Interaction log',
      url: '/admin/admin-actions',
    },
    {
      name: 'Perks',
      url: '/admin/perks',
    },
    {
      name: 'Featured',
      url: '/admin/featured',
    }
  ],
  bottom: [
    {
      name: 'Account',
      url: '/dashboard',
      icon: 'User',
      badge: {
        variant: 'success',
        text: '3',
      },
    },
  ],
};
