import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import NavSpacer from './components/NavSpacer';
import NavOverlay from './components/NavOverlay';
import NavDivider from './components/NavDivider';
import NavSingleItem from './components/NavSingleItem';
import NavExternal from './components/NavExternal';
import NavDropdownItem from './components/NavDropdownItem';
import PageAlertContext from '../PageAlert/PageAlertContext';
import NavSingleItemWithCount from './components/NavSingleItemWithCount';

import ToggleSidebarButton from './components/ToggleSidebarButton';

import { Button } from 'reactstrap';

export default class SidebarNavAsAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  navItems = items => {
    const { perksDisabled, perkTitle, company, vouchers } = this.props;

    const itemsClone = [...items];
  //  console.log('navItems', perksDisabled, itemsClone);

    // console.log('navItems 222', company, vouchers);

    // if (perksDisabled) {
    const found = itemsClone.findIndex((element) => element.url === '/perks');

  //  console.log('navItems found', found, itemsClone);

    if (found && found !== -1) {
      if (perksDisabled) {
        itemsClone.splice(found, 1);
      } else {
        itemsClone[found].name = perkTitle;
      }
    }
    // }

 //   console.log('itemsClone', itemsClone);

    return itemsClone.map((item, index) => this.itemType(item, index));
  };

  itemType = (item, index) => {
    const { user, adminStatistics, branding, location, shadeColor } = this.props;

    if (item.name && item.name === 'divider') {
      return <div style={{ height: 0, width: '100%', borderBottom: '1px solid white' }} />;
    } else if (item.children) {
      return <NavDropdownItem location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} adminStatistics={adminStatistics} user={user} key={index} item={item} isSidebarCollapsed={this.props.isSidebarCollapsed} />;
    } else if (adminStatistics && adminStatistics.newCompaniesCount && adminStatistics.newCompaniesCount.count && item.name && item.name === 'Companies') {
      return (<NavSingleItemWithCount location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} item={item} key={index} count={adminStatistics.newCompaniesCount.count} />)
    } else if (adminStatistics && adminStatistics.pendingVouchersCount && adminStatistics.pendingVouchersCount.count && item.name && item.name === 'Campaigns') {
      return (<NavSingleItemWithCount location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} item={item} key={index} count={adminStatistics.pendingVouchersCount.count} />)
    } else if (item.divider) {
      return <NavDivider key={index} />;
    } else {
      return <NavSingleItem location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} item={item} key={index} />;
    }
  };

  navBrand = () => {
    const { logo, logoText, toggleSidebar, isSidebarCollapsed } = this.props;

    return (
      <div className="site-logo-bar">
        <NavLink to="/" className="navbar-brand">
          {logo && <img src={logo} alt="" />}
          {logoText && <span className="logo-text">{logoText}</span>}
        </NavLink>
        {!isSidebarCollapsed ? (
          <ToggleSidebarButton
            toggleSidebar={toggleSidebar}
            isSidebarCollapsed={isSidebarCollapsed}
            color="primary"
          />
        ) : (null)}
      </div>
    );
  };

  navContact = () => {
  //  const { logout, branding } = this.props;
    const { branding, location, shadeColor } = this.props;

    return <NavSingleItem location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding}
    item={{
      name: 'Contact',
      url: '/contact',
    }}
    key={99}
    />;
  };

  navLogout = () => {
    const { logout, branding } = this.props;

    return (
      <div className="nav-item">
        <div>
          <Button color="link" className="logout-button" style={{ color: branding ? branding.sidebarTextColor : '#FFF' }} onClick={logout}>
            Logout
          </Button>
        </div>
      </div>
    );
  };

  navUserData = () => {
    const { user, branding, isLoggedIn, company, roles } = this.props;

  //  console.log('user', { user });

    return (
      <div className="nav-item">
        {(isLoggedIn && user && user.uid) ? (
          <>
            <div style={{ height: 0, width: '100%', borderBottom: '1px solid white' }} />
            <div className="userDropdown-wrapper" style={{ paddingTop: 10, paddingBottom: 20 }}>
              <h4 className="userDropdown-name" style={{ fontWeight: 'bold', fontSize: 16, paddingLeft: 10, paddingBottom: 12, color: branding ? branding.sidebarTextColor : '#FFF' }}>
                {user.displayName}
              </h4>
              <h2 className="userDropdown-role" style={{ fontSize: 12, textAlign: 'left', paddingLeft: 30, color: branding ? branding.sidebarTextColor : '#FFF' }}>
                {(user.role === 'admin') ? 'Admin' : ((user.role === 'teamOwner') ? user.roleInCompany : (roles[user.role] ? roles[user.role].name : null))}
              </h2>
            </div>
            <div style={{ height: 0, width: '100%', borderBottom: '1px solid white' }} />
          </>
        ):(
          null
        )}
      </div>
    );
  };


  navFAQ = () => {
    const { logout, branding, faqLinks, shadeColor } = this.props;

    console.log('faqLinks', faqLinks);

    return (
      <>
        <div className="nav-item">
          <a className="logout-button" style={{ color: branding ? branding.sidebarTextColor : '#FFF' }}>
            FAQs
          </a>
        </div>
        {faqLinks ? Object.keys(faqLinks).map((key) => (
          <NavExternal link={faqLinks[key].value} name={faqLinks[key].name} branding={branding} shadeColor={shadeColor} indent />
        )) : (null)}
      </>
    );
  };

  navUsers = () => {
    const { branding, location, shadeColor } = this.props;

    let isActive = false;
    if (location && location.pathname && location.pathname === '/users') {
      isActive = true;
    }

    let bgColor = '#0b1628';

    if (branding.sidebarPrimaryColor) {
      bgColor = branding.sidebarPrimaryColor;
    }

    const item = { name: "Users", url: "/users" }

    return (<NavSingleItem location={location ? location.pathname : ''} shadeColor={shadeColor} branding={branding} item={item} />);
  };

  toggleSidebarOnClick = (e) => {
    const { toggleSidebar, isSidebarCollapsed } = this.props;

    if (isSidebarCollapsed) {
      e.preventDefault();
      toggleSidebar();
    }
  }

  render() {
    const { user, isLoggedIn, adminStatistics, branding, logout, isSidebarCollapsed } = this.props;

  //  const { isLoggedIn, userData } = user;

  //  console.log('isSidebarCollapsed', { isSidebarCollapsed });

  const NavBrand = ({ logo, logoText }) => {
    return (
      <div className="site-logo-bar">
        <NavLink to="/" className="navbar-brand">
          {logo && <img src={logo} alt="" />}
          {logoText && <span className="logo-text">{logoText}</span>}
        </NavLink>
      </div>
    );
  };

    return (
      <>
      <PageAlertContext.Consumer>
        {consumer => {
          const hasPageAlertClass = consumer.alert ? 'has-alert' : '';
          return (
            <div>
              <div
                onClick={(e) => { this.toggleSidebarOnClick(e) }}
                style={{ backgroundColor: branding.sidebarPrimaryColor ? branding.sidebarPrimaryColor : '#162c50' }}
                className={`app-sidebar ${hasPageAlertClass}`}
              >
                {/* <NavBrand logo={this.props.logo} logoText={this.props.logoText} /> */}
                {this.navBrand()}
                {!isSidebarCollapsed ? (
                <nav>
                  <ul id="main-menu">
                    {(isLoggedIn && user) ? (
                      <>
                        {this.navUserData()}
                      </>
                    ):(null)}
                    {(isLoggedIn && user) ? (
                      <>
                        {(
                          this.navItems(this.props.nav.client)
                        )}
                      </>
                    ):(null)}

                      {(isLoggedIn) ? (
                        <>
                          {this.navFAQ()}
                        </>
                      ) : (null)}
                      <NavSpacer />
                      {(isLoggedIn) ? (
                        <>
                          {this.navUsers()}
                        </>
                      ) : (null)}
                    {/*  {this.navContact()} */}
                    {this.navLogout()}
                    {/*navItems(this.props.nav.bottom)*/}
                  </ul>
                  </nav>
                ) : (null)}
              </div>
              {this.props.isSidebarCollapsed && <NavOverlay onClick={this.props.toggleSidebar} />}
            </div>
          );
        }}
      </PageAlertContext.Consumer>
      </>
    );
  }
}
