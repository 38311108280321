import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter, Link } from 'react-router-dom';

import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, Form, FormGroup, Alert, Media, Spinner } from 'reactstrap';

import { connect } from 'react-redux';

import ScrollArea from 'react-scrollbar';

import moment from 'moment';
import Moment from 'react-moment';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import momentLocalizer from 'react-widgets-moment';

import ReactTooltip from 'react-tooltip';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';

import { convertFromHTML } from 'draft-convert';
import htmlToDraft from 'html-to-draftjs';

import bell from '../../../assets/icons/alarm.svg';
import gear from '../../../assets/icons/cog.svg';
import leftArrow from '../../../assets/icons/left-arrow.svg';

import { storage } from '../../../lib/firebase';

import {
  createVoucher,
} from '../../../actions/vouchers';

import {
  updateLastActive,
} from '../../../actions/company';

import {
  getNewVoucherTooltips,
  getNewVoucherPageData,
} from '../../../actions/contentManagement';

import formattedNumber from '../../components/FormattedNumber/FormattedNumber';

import line from '../../../assets/images/line.svg';
import tutorialVideo from '../../../assets/voucherTutorial.mp4';
import defaultPrimary from '../../../assets/images/defaultPrimary.png';
import defaultLogo from '../../../assets/images/defaultLogo.png';

import notifications from '../../../const/notifications';

moment.locale('en-GB');
momentLocalizer();

class NewVoucher extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    company: PropTypes.shape(),
    dashboardTooltips: PropTypes.shape(),
    addVoucher: PropTypes.func.isRequired,
    fetchNewVoucherTooltips: PropTypes.func.isRequired,
    fetchNewVoucherPageData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
    dashboardTooltips: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      discount: {},
      availableVouchers: {},
      showNoPermission: false,
      permissionLevel: null,
      dontShowPage: false,
      previewModal: false,
      readMore: false,
      acceptRules: false,
      recomendedDiscounts: false,
      testMode: false,
      startDate: new Date(),
      endDate: new Date(),
      expiry: '5',
      previewStep: '1',
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    const { user, history, adminViewAsCompany, fetchNewVoucherTooltips, fetchNewVoucherPageData, updateLastActiveFunc } = this.props;

    const date = new Date();

    date.setUTCHours(0, 0, 0, 0);

    console.log('datedatedate', date);

    this.setState({ startDate: date, endDate: date });

    if (user && Object.keys(user).length > 0) {
      fetchNewVoucherTooltips();

      if (user.company) {
        updateLastActiveFunc(user.company);
      }

      fetchNewVoucherPageData().then((res) => {
        if (res) {
          const contentState = convertFromHTML({
            htmlToStyle: (nodeName, node, currentStyle) => {
              if (nodeName === 'span' && node.style.color) {
                let color = node.style.color.substring(3);

                return currentStyle.add(`color-rgb${color}`);
              } else if (nodeName === 'span' && node.style.fontSize) {
                let fontSize = node.style.fontSize;

                fontSize = fontSize.substring(0, fontSize.length - 2);

                return currentStyle.add(`fontsize-${fontSize}`);
              } else {
                return currentStyle;
              }
            },
            htmlToEntity: (nodeName, node, createEntity) => {
              if (nodeName === 'a') {
                return createEntity(
                  'LINK',
                  'MUTABLE',
                  { url: node.href }
                )
              } else if (nodeName === 'iframe') {
                return createEntity(
                  'EMBEDDED_LINK',
                  'MUTABLE',
                  { src: node.src, height: node.height, width: node.width }
                )
              }
            },
            textToEntity: (text, createEntity) => {
              const result = [];
              text.replace(/\@(\w+)/g, (match, name, offset) => {
                const entityKey = createEntity(
                  'AT-MENTION',
                  'IMMUTABLE',
                  { name }
                );
                result.push({
                  entity: entityKey,
                  offset,
                  length: match.length,
                  result: match
                });
              });
              return result;
            },
            htmlToBlock: (nodeName, node) => {
              if (nodeName === 'blockquote') {
                return {
                  type: 'blockquote',
                  data: {}
                };
              }
            }
          })(res);

          if (contentState) {
            this.setState({ editorState: EditorState.createWithContent(contentState) });
          }
        }
      });

      if (user.company) {

        if (user.role === 'teamOwner') {
          this.setState({ showNoPermission: false, dontShowPage: false, permissionLevel: null });
        } else {
          const { roles } = this.props;

          if (roles && roles[user.role] && roles[user.role].permissions && roles[user.role].permissions.createNewVoucher) {
            if (roles[user.role].permissions.createNewVoucher === 2) {
              this.setState({ showNoPermission: false, dontShowPage: false });
            } else {
              this.setState({ showNoPermission: true, dontShowPage: false });
            }
          } else {
            this.setState({ showNoPermission: true, dontShowPage: true, permissionLevel: null });
          }
        }
      } else if (adminViewAsCompany && user.roleClass === 'admin') {
        const { adminsRoles } = this.props;

        if (user.role === 'admin') {
          this.setState({ showNoPermission: false, dontShowPage: false });
        } else {
          if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.companyAccess && adminsRoles[user.role].permissions.companyAccessCreateNewVoucher) {
            if (adminsRoles[user.role].permissions.companyAccessCreateNewVoucher === 2) {
              this.setState({ showNoPermission: false, dontShowPage: false });
            } else {
              this.setState({ showNoPermission: true, dontShowPage: false });
            }
          } else {
            this.setState({ showNoPermission: true, dontShowPage: true });
          }
        }
      } else {
        history.push('/');
        return null;
      }
    } else {
      history.push('/');
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value, success: false, error: false });

  //total value
  handleChangeTotalValue = e => {
    this.setState({ [e.target.name]: parseFloat(e.target.value.replace(/,/g, '')), success: false, error: false })
  };

  handleChangeAcceptRules = (e) => {
    this.setState((prevState) => ({ acceptRules: !prevState.acceptRules }))
  }

  handleChangeRecomendedDiscounts = (e) => {
    this.setState((prevState) => ({ recomendedDiscounts: !prevState.recomendedDiscounts }))
  }

  handleChangeTestMode = (e) => {
    this.setState((prevState) => ({ testMode: !prevState.testMode }))
  }


  handleChangeDiscount = (e, id) => {
    const value = e.target.value;

    const parsedValue = parseInt(value, 10);

    console.log('handleChangeDiscount', value, parsedValue);

    if (isNaN(parsedValue)) {
      this.setState((prevState) => ({
        discount: { ...prevState.discount, [id]: prevState.discount[id] || 0 },
        error: notifications.company.newVoucher.mustBeNumber || 'Value must be a number',
        success: false
      }));
      return null;
    }

    if (parsedValue > 90) {
      this.setState((prevState) => ({
        discount: { ...prevState.discount, [id]: 90 },
        error: notifications.company.newVoucher.maxDiscount || 'Maximum discount is 90%',
        success: false
      }));
      return null;
    }

    //  if (parsedValue < 5) {
    //    this.setState((prevState) => ({ error: notifications.company.newVoucher.minDiscount || 'Minimum discount is 0%', success: false }));
    //    return null;
    //  }

    this.setState((prevState) => ({ discount: { ...prevState.discount, [id]: parsedValue }, error: null, success: false }));
  }

  handleChangeAvailableVouchers = (e, id) => {
    const value = e.target.value;

    const parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue)) {
      this.setState((prevState) => ({
        availableVouchers: { ...prevState.availableVouchers, [id]: prevState.availableVouchers[id] || 0 },
        error: notifications.company.newVoucher.mustBeNumber || 'Value must be a number',
        success: false
      }));

      return null;
    }

    if (parsedValue > 1000) {
      this.setState((prevState) => ({
        availableVouchers: { ...prevState.availableVouchers, [id]: 1000 },
        error: notifications.company.newVoucher.maxValue || 'Maximum value is 1000£',
        success: false
      }));
      return null;
    }

    this.setState((prevState) => ({
      availableVouchers: { ...prevState.availableVouchers, [id]: parsedValue },
      error: null,
      success: false
    }))
  }

  createVoucher = () => {
    const { addVoucher, user, company, adminViewAsCompany, vouchers } = this.props;
    const { availableVouchers, name, totalValue, discount, startDate, acceptRules, expiry, testMode } = this.state;

    console.log('createVoucher', name, startDate);

    if (!acceptRules) {
      this.setState({ error: notifications.company.newVoucher.acceptTC || 'Please certify you have read and accepted the current T&Cs before requesting your campaign' });
      return null;
    }

    if (!name) {
      this.setState({ error: notifications.company.newVoucher.noName || 'Missing voucher name' });
      return null;
    }

    if (vouchers) {
      let hasSameName = false;
      Object.keys(vouchers).map(key => {
        if (vouchers[key] && (vouchers[key].status < 4 || vouchers[key].status === 8) && vouchers[key].name === name) {
          hasSameName = true;
        }
        return null;
      })

      if (hasSameName) {
        this.setState({ error: notifications.company.newVoucher.nameUsed || 'Name already in use' });
        return null;
      }
    }

    if (!availableVouchers['0'] || !availableVouchers['1'] || !availableVouchers['2']) {
      this.setState({ error: notifications.company.newVoucher.noValue || 'Missing voucher value' });
      return null;
    }


    // Change after testing
    if (availableVouchers['0'] < 10 || availableVouchers['1'] < 10 || availableVouchers['2'] < 10) {
      this.setState({ error: notifications.company.newVoucher.minValue || 'Minimum Voucher value is 10£' });
      return null;
    }

    if (availableVouchers['0'] > 1000 || availableVouchers['1'] > 1000 || availableVouchers['2'] > 1000) {
      this.setState({ error: notifications.company.newVoucher.maxValue || 'Maximum Voucher value is 1000£' });
      return null;
    }

    if (availableVouchers['0'] % 5 || availableVouchers['1'] % 5 || availableVouchers['2'] % 5) {
      this.setState({ error: notifications.company.newVoucher.multipleOf5 || 'Voucher values have to be a multiple of 5' });
      return null;
    }

    if (availableVouchers['0'] + 5 > availableVouchers['1']) {
      this.setState({ error: notifications.company.newVoucher.voucher2Val || 'Voucher 2 value has to at least 5£ larger than Voucher 1' });
      return null;
    }

    if (availableVouchers['1'] + 5 > availableVouchers['2']) {
      this.setState({ error: notifications.company.newVoucher.voucher3Val || 'Voucher 3 value has to at least 5£ larger than Voucher 2' });
      return null;
    }

    if (!discount['1'] || !discount['2'] || !discount['3']) {
      this.setState({ error: notifications.company.newVoucher.noDiscount || 'Missing discount value' });
      return null;
    }

    if (discount['0'] < 10 || discount['1'] < 10 || discount['2'] < 10) {
      this.setState({ error: notifications.company.newVoucher.minDiscountValue || 'Minimum discount value is 10%' });
      return null;
    }

    if (discount['0'] > 90 || discount['1'] > 90 || discount['2'] > 90) {
      this.setState({ error: notifications.company.newVoucher.maxDiscountValue || 'Maximum discount value is 90£' });
      return null;
    }

    if (discount['1'] % 5 || discount['2'] % 5 || discount['3'] % 5) {
      this.setState({ error: notifications.company.newVoucher.multipleOf5Discount || 'Discount steps have to be multiple of 5' });
      return null;
    }

    if (discount['1'] + 5 > discount['2']) {
      this.setState({ error: notifications.company.newVoucher.discount2Val || 'Discount step 2 has to at least 5% larger than step 1' });
      return null;
    }

    if (discount['2'] + 5 > discount['3']) {
      this.setState({ error: notifications.company.newVoucher.discount3Val || 'Discount step 3 has to at least 5% larger than step 2' });
      return null;
    }

    /* if (!totalValue) {
      this.setState({ error: notifications.company.newVoucher.noTotalValue || 'Missing Total value of vouchers for sale' });
      return null;
    } */

    if (totalValue < 1000) {
      this.setState({ error: notifications.company.newVoucher.minTotalValue || 'Minimum value of Total value of vouchers for sale is £1000' });
      return null;
    }

    //  if (totalValue > 1000000) {
    //    this.setState({ error: notifications.company.newVoucher.maxTotalValue || 'Maximum value of Total value of vouchers for sale is £1000000' });
    //    return null;
    //  }

    if (!expiry) {
      this.setState({ error: notifications.company.newVoucher.noExpiry || 'Missing expiry value' });
      return null;
    }

    const newDate = new Date();
    newDate.setHours(0, 0, 1);

    const start = new Date(startDate);
    start.setHours(0, 0, 2);

    if (newDate > start) {
      this.setState({ error: notifications.company.newVoucher.wrongStartDate || 'Start date cannot be set to past' });
      return null;
    }

    this.setState({ processing: true });

    if (adminViewAsCompany && !user.company) {
      addVoucher(adminViewAsCompany, { availableVouchers, name, totalValue, discount, startDate: start, expiry, testMode }).then(res => {
        if (res === 'success') {
          this.setState({ processing: false, availableVouchers: {}, name: null, totalValue: null, discount: {}, error: null, success: true, startDate: new Date(), expiry: '5' });
        }
      }).catch(err => {
        console.log(err);
        if (err === 'Please check your payments and website connections') {
          this.setState({ processing: false, error: notifications.company.newVoucher.checkWebsitePayments || err });
        } else if (err === 'Please check your website connection') {
          this.setState({ processing: false, error: notifications.company.newVoucher.checkWebsite || err });
        } else if (err === 'Please check your payments connection') {
          this.setState({ processing: false, error: notifications.company.newVoucher.checkPayments || err });
          //  } else if (err === 'Missing stripe connection') {
          //    this.setState({ processing: false, error: notifications.company.newVoucher.missingStripeConnection || err });
        } else if (err === 'Missing compliance data') {
          this.setState({ processing: false, error: notifications.company.newVoucher.missingComplianceData || err });
        } else if (err === 'Missing company data') {
          this.setState({ processing: false, error: notifications.company.newVoucher.missingCompanyData || err });
        } else if (err === 'Missing primary voucher image') {
          this.setState({ processing: false, error: notifications.company.newVoucher.missingPrimaryVoucherImage || err });
        } else if (err === 'Missing logo image') {
          this.setState({ processing: false, error: notifications.company.newVoucher.missingLogoImage || err });
        } else if (err === 'Missing company category') {
          this.setState({ processing: false, error: notifications.company.newVoucher.missingCategory || err });
        } else if (err === 'Missing website') {
          this.setState({ processing: false, error: notifications.company.newVoucher.missingWebsite || err });
        } else if (err === 'Missing description') {
          this.setState({ processing: false, error: notifications.company.newVoucher.missingDescription || err });
        } else if (err === 'Name already in use') {
          this.setState({ processing: false, error: notifications.company.newVoucher.nameInUse || err });
        } else if (err === 'Wrong company id') {
          this.setState({ processing: false, error: notifications.company.newVoucher.wrongCompanyId || err });
        } else {
          this.setState({ processing: false, error: notifications.company.newVoucher.failedToCreate || err });
        }
      })
    } else {
      addVoucher(user.company, { availableVouchers, name, totalValue, discount, startDate: start, expiry, testMode }).then(res => {
        if (res === 'success') {
          this.setState({ processing: false, availableVouchers: {}, name: null, totalValue: null, discount: {}, error: null, success: true, startDate: new Date(), expiry: '5' });
        }
      }).catch(err => {
        console.log(err);
        if (err === 'Please check your payments and website connections') {
          this.setState({ processing: false, error: notifications.company.newVoucher.checkWebsitePayments || err });
        } else if (err === 'Please check your website connection') {
          this.setState({ processing: false, error: notifications.company.newVoucher.checkWebsite || err });
        } else if (err === 'Please check your payments connection') {
          this.setState({ processing: false, error: notifications.company.newVoucher.checkPayments || err });
          //  } else if (err === 'Missing stripe connection') {
          //    this.setState({ processing: false, error: notifications.company.newVoucher.missingStripeConnection || err });
        } else if (err === 'Missing compliance data') {
          this.setState({ processing: false, error: notifications.company.newVoucher.missingComplianceData || err });
        } else if (err === 'Missing company data') {
          this.setState({ processing: false, error: notifications.company.newVoucher.missingCompanyData || err });
        } else if (err === 'Missing primary voucher image') {
          this.setState({ processing: false, error: notifications.company.newVoucher.missingPrimaryVoucherImage || err });
        } else if (err === 'Missing logo image') {
          this.setState({ processing: false, error: notifications.company.newVoucher.missingLogoImage || err });
        } else if (err === 'Missing company category') {
          this.setState({ processing: false, error: notifications.company.newVoucher.missingCategory || err });
        } else if (err === 'Missing website') {
          this.setState({ processing: false, error: notifications.company.newVoucher.missingWebsite || err });
        } else if (err === 'Missing description') {
          this.setState({ processing: false, error: notifications.company.newVoucher.missingDescription || err });
        } else if (err === 'Name already in use') {
          this.setState({ processing: false, error: notifications.company.newVoucher.nameInUse || err });
        } else if (err === 'Wrong company id') {
          this.setState({ processing: false, error: notifications.company.newVoucher.wrongCompanyId || err });
        } else {
          this.setState({ processing: false, error: notifications.company.newVoucher.failedToCreate || err });
        }
      })
    }
  }

  openPreview = async () => {
    const { company } = this.props;

    if (company) {
      let companyPrimaryVoucherImage;
      let logoImage;

      if (company.primaryVoucherImage && company.logo) {
        const primaryVoucherImageReference = storage.ref(company.primaryVoucherImage);
        const logoReference = storage.ref(company.logo);

        await primaryVoucherImageReference.getDownloadURL().then((url) => {
          companyPrimaryVoucherImage = url;
        }).catch((error) => {
          console.log('get image error', { error });
        });

        await logoReference.getDownloadURL().then((url) => {
          logoImage = url;
        }).catch((error) => {
          console.log('get image error', { error });
        });
      }

      this.setState({ primaryVoucherImage: companyPrimaryVoucherImage || null, logo: logoImage || null });
    }
  }

  closePreview = () => {
    this.setState({ previewModal: false, primaryVoucherImage: null, logo: null });
  }

  toggleReadMore = () => {
    this.setState((prevState) => ({ readMore: !prevState.readMore }))
  }

  changeStartDate = (date) => {
    console.log('changeStartDate', date);
    date.setUTCHours(0, 0, 0, 0);
    this.setState({ startDate: date });
  }

  changeEndDate = (date) => {
    date.setUTCHours(0, 0, 0, 0);
    this.setState({ endDate: date });
  }

  togglePreviewStep = (step) => {
    this.setState({ previewStep: step });
  }

  render() {
    const { company, dashboardTooltips, vouchers, branding } = this.props;
    const {
      availableVouchers,
      name,
      discount,
      totalValue,
      showNoPermission,
      dontShowPage,
      error,
      success,
      previewModal,
      primaryVoucherImage,
      logo,
      readMore,
      startDate,
      previewStep,
      acceptRules,
      recomendedDiscounts,
      testMode,
      expiry,
      processing,
    } = this.state;

    if (dontShowPage) {
      return (
        <div>
          <Card>
            <CardBody>
              You don't have permissions to create new vouchers
            </CardBody>
          </Card>
        </div>
      );
    }

    return (
      <Row style={{ margin: 0 }}>

        <Col className="voucher-preview-card">
          <div className="voucher-preview">
            <Row className="voucher-preview-app-top">
              <Col xs="4" className="voucher-preview-app-top-logo-wrapper">
                {(branding && branding.logo) ? <img className="voucher-preview-app-top-logo" src={branding.logo} alt="" /> : null}
                <div className="voucher-preview-app-top-logo-text">
                  Steppr
                </div>
              </Col>
              <Col xs="3" className="voucher-preview-app-top-middle">
                <div className="voucher-preview-app-top-middle-icon">
                  <img src={leftArrow} alt="" />
                </div>
              </Col>
              <Col xs="5" className="voucher-preview-app-top-right">
                <div className="voucher-preview-app-top-right-icon">
                  <img src={bell} alt="" />
                </div>
                <div className="voucher-preview-app-top-right-icon">
                  <img src={gear} alt="" />
                </div>
              </Col>
            </Row>
            <ScrollArea
              style={{ maxHeight: 500 }}
              speed={0.6}
              smoothScrolling={true}
              className="area"
              contentClassName="content"
              horizontal={false}
              ref={(el) => { this.previewScrollbar = el; }}
            >
              <div className="voucher-preview-top">
                <Media className="voucher-preview-top-image" src={company.companyPrimaryVoucherImage || defaultPrimary} />
              </div>

              <div className="voucher-preview-header">
                {(company.logoImage || company.name) ? (
                  <>
                    <Media className="voucher-preview-header-logo" src={company.logoImage || defaultLogo} />
                    <div className="voucher-preview-header-text">
                      <div className="voucher-preview-header-name">
                        {company.name}
                      </div>
                      <div className="voucher-preview-header-website">
                        {company.website ? company.website.replace(/(^\w+:|^)\/\//, '') : ''}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="voucher-preview-body">
                <div className={readMore ? 'voucher-preview-body-description' : 'voucher-preview-body-description-hidden'}>
                  {company.description}
                </div>
                <Button className="voucher-preview-body-more" color="link" onClick={this.toggleReadMore}>
                  {readMore ? 'show less' : 'read more'}
                </Button>
              </div>
              <div className="voucher-preview-loyalty-text"> Loyalty steps </div>
              <div className="voucher-preview-footer">
                <div className="voucher-preview-steps">
                  <Media className="voucher-preview-steps-image" src={line} />
                  <div className={`voucher-preview-steps-1 ${previewStep === '1' ? 'voucher-preview-steps-active' : ''}`} onClick={() => this.togglePreviewStep('1')}>
                    <div className="voucher-preview-steps-percent">
                      {(discount && discount['1']) ? `${discount['1']}%` : '0%'}
                    </div>
                    <div className="voucher-preview-steps-name">
                      Step 1
                    </div>
                  </div>
                  <div className={`voucher-preview-steps-2 ${previewStep === '2' ? 'voucher-preview-steps-active' : ''}`} onClick={() => this.togglePreviewStep('2')}>
                    <div className="voucher-preview-steps-percent">
                      {(discount && discount['2']) ? `${discount['2']}%` : '0%'}
                    </div>
                    <div className="voucher-preview-steps-name">
                      Step 2
                    </div>
                  </div>
                  <div className={`voucher-preview-steps-3 ${previewStep === '3' ? 'voucher-preview-steps-active' : ''}`} onClick={() => this.togglePreviewStep('3')}>
                    <div className="voucher-preview-steps-percent">
                      {(discount && discount['3']) ? `${discount['3']}%` : '0%'}
                    </div>
                    <div className="voucher-preview-steps-name">
                      Step 3
                    </div>
                  </div>
                </div>

                <div className="voucher-preview-vouchers">
                  <div className="voucher-preview-voucher">
                    <div className="voucher-preview-vouchers-left">
                      <div className="voucher-preview-vouchers-left-1">
                        {`£${(availableVouchers && availableVouchers['0']) ? availableVouchers['0'] : 0}`}
                      </div>
                      <div className="voucher-preview-vouchers-left-2">
                        VOUCHER
                      </div>
                    </div>
                    <div className="voucher-preview-vouchers-right">
                      {previewStep === '1' ? (
                        <div className="voucher-preview-vouchers-right-1">
                          {'BUY FOR'}
                        </div>
                      ) : (
                        <div className="voucher-preview-vouchers-right-1-long">
                          {previewStep === '2' ? (
                            'BUY STEP 1 TO UNLOCK'
                          ) : (
                            'BUY STEP 2 TO UNLOCK'
                          )}
                        </div>
                      )}
                      <div className={`voucher-preview-vouchers-right-2 ${previewStep !== '1' ? 'voucher-preview-vouchers-right-2-long' : ''}`}>
                        {(availableVouchers && discount && availableVouchers['0'] && discount[previewStep]) ? `£${(availableVouchers['0'] - (availableVouchers['0'] * (discount[previewStep] / 100))).toFixed(2)}` : '£0'}
                      </div>
                    </div>
                  </div>
                  <div className="voucher-preview-voucher">
                    <div className="voucher-preview-vouchers-left">
                      <div className="voucher-preview-vouchers-left-1">
                        {`£${(availableVouchers && availableVouchers['1']) ? availableVouchers['1'] : 0}`}
                      </div>
                      <div className="voucher-preview-vouchers-left-2">
                        VOUCHER
                      </div>
                    </div>
                    <div className="voucher-preview-vouchers-right">
                      {previewStep === '1' ? (
                        <div className="voucher-preview-vouchers-right-1">
                          {'BUY FOR'}
                        </div>
                      ) : (
                        <div className="voucher-preview-vouchers-right-1-long">
                          {previewStep === '2' ? (
                            'BUY STEP 1 TO UNLOCK'
                          ) : (
                            'BUY STEP 2 TO UNLOCK'
                          )}
                        </div>
                      )}
                      <div className={`voucher-preview-vouchers-right-2 ${previewStep !== '1' ? 'voucher-preview-vouchers-right-2-long' : ''}`}>
                        {(availableVouchers && discount && availableVouchers['1'] && discount[previewStep]) ? `£${(availableVouchers['1'] - (availableVouchers['1'] * (discount[previewStep] / 100))).toFixed(2)}` : '£0'}
                      </div>
                    </div>
                  </div>
                  <div className="voucher-preview-voucher">
                    <div className="voucher-preview-vouchers-left">
                      <div className="voucher-preview-vouchers-left-1">
                        {`£${(availableVouchers && availableVouchers['2']) ? availableVouchers['2'] : 0}`}
                      </div>
                      <div className="voucher-preview-vouchers-left-2">
                        VOUCHER
                      </div>
                    </div>
                    <div className="voucher-preview-vouchers-right">
                      {previewStep === '1' ? (
                        <div className="voucher-preview-vouchers-right-1">
                          {'BUY FOR'}
                        </div>
                      ) : (
                        <div className="voucher-preview-vouchers-right-1-long">
                          {previewStep === '2' ? (
                            'BUY STEP 1 TO UNLOCK'
                          ) : (
                            'BUY STEP 2 TO UNLOCK'
                          )}
                        </div>
                      )}
                      <div className={`voucher-preview-vouchers-right-2 ${previewStep !== '1' ? 'voucher-preview-vouchers-right-2-long' : ''}`}>
                        {(availableVouchers && discount && availableVouchers['2'] && discount[previewStep]) ? `£${(availableVouchers['2'] - (availableVouchers['2'] * (discount[previewStep] / 100))).toFixed(2)}` : '£0'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ScrollArea>
          </div>
          <Row className="voucher-preview-title">
            <Col xs="12" >
              App preview
            </Col>
          </Row>


        </Col>
        <Col className="card" style={{ background: '#FFF' }}>
          {/*  <Row style={{ color: "#7f8fa4", paddingTop: 17, paddingLeft: 0, paddingBottom: 15, fontSize: 16 }}>
              <Col sm="7">
                Create New Campaign
              </Col>
              <Col style={{ textAlign: 'right', fontSize: 16 }} sm="5">
                All fields are mandatory
              </Col>
            </Row> */}

          <Row>
            <Col>
              <video width="100%" height="auto" controls={false} loop="true" autoplay="autoplay" muted>
                <source src={tutorialVideo} type="video/mp4" />
              </video>
            </Col>
          </Row>

          <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='name'>
            <span>{dashboardTooltips.campaignName ? dashboardTooltips.campaignName.value : ''}</span>
          </ReactTooltip>
          <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='availableVouchers'>
            <span>{dashboardTooltips.faceValues ? dashboardTooltips.faceValues.value : ''}</span>
          </ReactTooltip>
          <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='discount'>
            <span>{dashboardTooltips.discounts ? dashboardTooltips.discounts.value : ''}</span>
          </ReactTooltip>
          <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='totalValue'>
            <span>{dashboardTooltips.totalValue ? dashboardTooltips.totalValue.value : ''}</span>
          </ReactTooltip>
          <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='start-date'>
            <span>{dashboardTooltips.startDate ? dashboardTooltips.startDate.value : ''}</span>
          </ReactTooltip>
          <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='expiry'>
            <span>{dashboardTooltips.expiry ? dashboardTooltips.expiry.value : ''}</span>
          </ReactTooltip>



          {(!error && success) && (
            <Alert color="success">
              <Row>
                <Col>
                  Congratulations on creating your voucher campaign. Our team will quickly go over the details and email you as soon as your campaign is live and visible to customers. You can view how your campaign will look on the Steppr apps by clicking ‘Preview’ on the Dashboard.
                </Col>
              </Row>
              <Row style={{ marginTop: 15 }}>
                <Col xs="12">
                  <div style={{ width: 60, paddingRight: 15, display: 'inline-block', verticalAlign: 'top' }}>
                  <div 
                    className="new-voucher-info-icon"
                    style={{
                      width: 45,
                      height: 45,
                      margin: 0,
                      background: 'unset',
                      fontSize: 32,
                      display: 'inline-block',
                      position: 'relative',
                      borderRadius: '50%',
                      fontSize: 32,
                      fontWeight: 700,
                      color: '#FFF',
                      textAlign: 'center',
                      border: '3px solid #fff',
                      lineHeight: '36px',
                      //marginLeft: 'auto',
                      //marginRight: 'auto',
                      marginTop: 5,
                    }}>i</div>
                  </div>
                  <div style={{ width: 'calc(100% - 60px)', display: 'inline-block' }}>
                    <div>
                      We may contact you to suggest a different primary voucher image. If the image is unsatisfactory, please let us know within 3 days from receiving our email – and check your spam just in case!
                    </div>
                  </div>
                </Col>
              </Row>
            </Alert>
          )}
          {(!!error) && <Alert color="danger">{error}</Alert>}
          <Row>
            <Col xs="12">
              <Row>
                <Col md="7" className="new-voucher-left">
                  <Row className="new-voucher-left-row new-voucher-left-row-first" style={{ paddingTop: 5 }}>
                    <Col style={{ flex: 'none', display: 'inline-block', width: '17.5%' }}>
                      <Label className="new-voucher-label" data-tip data-for='name' for="name">
                        Campaign Name
                        <div className="new-voucher-info-icon">i</div>
                      </Label>
                    </Col>
                    <Col style={{ flex: 'none', display: 'inline-block', width: '82.5%' }}>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        className="new-voucher-left-input-fullwidth"
                        placeholder="For your own records"
                        value={name || ''}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>


                  <Row className="new-voucher-left-row">
                    <Col xs="6" style={{ paddingRight: 0 }}>
                      <Row>
                        <Col xs="11">
                          <Label className="new-voucher-label" data-tip data-for='availableVouchers' for="availableVouchers">
                            Face Values
                            <div className="new-voucher-info-icon">i</div>
                          </Label>

                        </Col>
                        <Col xs="1" />
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Col xs="4" />
                        <Col xs="7" className="new-voucher-left-input-col">
                          <div className="new-voucher-left-input-sign"><div className="new-voucher-left-input-sign-sign">£</div></div>
                          <Input
                            type="text"
                            className="new-voucher-left-input"
                            name="availableVouchers1"
                            id="availableVouchers1"
                            placeholder="Voucher 1 - e.g. £40"
                            value={availableVouchers['0'] || ''}
                            onChange={(e) => this.handleChangeAvailableVouchers(e, '0')}
                          />
                        </Col>
                        <Col xs="1" />
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Col xs="4" />
                        <Col xs="7" className="new-voucher-left-input-col">
                          <div className="new-voucher-left-input-sign"><div className="new-voucher-left-input-sign-sign">£</div></div>
                          <Input
                            type="text"
                            className="new-voucher-left-input"
                            name="availableVouchers2"
                            id="availableVouchers2"
                            placeholder="Voucher 2 - e.g. £60"
                            value={availableVouchers['1'] || ''}
                            onChange={(e) => this.handleChangeAvailableVouchers(e, '1')}
                          />
                        </Col>
                        <Col xs="1" />
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Col xs="4" />
                        <Col xs="7" className="new-voucher-left-input-col">
                          <div className="new-voucher-left-input-sign"><div className="new-voucher-left-input-sign-sign">£</div></div>
                          <Input
                            type="text"
                            className="new-voucher-left-input"
                            name="availableVouchers3"
                            id="availableVouchers3"
                            placeholder="Voucher 3 - e.g. £100"
                            value={availableVouchers['2'] || ''}
                            onChange={(e) => this.handleChangeAvailableVouchers(e, '2')}
                          />
                        </Col>
                        <Col xs="1" />
                      </Row>
                    </Col>



                    <Col xs="6" style={{ paddingLeft: 0 }}>
                      <Row>
                        <Col xs="1" />
                        <Col xs="11">
                          <Label className="new-voucher-label" data-tip data-for='discount' for="discount">
                            Loyalty Steps
                            <div className="new-voucher-info-icon">i</div>
                          </Label>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Col xs="5" />
                        <Col xs="7" className="new-voucher-left-input-col">
                          <div className="new-voucher-left-input-sign"><div className="new-voucher-left-input-sign-sign">%</div></div>
                          <Input
                            type="text"
                            className="new-voucher-left-input"
                            name="discount1"
                            id="discount1"
                            placeholder="Step 1 - e.g. 20%"
                            value={discount['1'] || ''}
                            onChange={(e) => this.handleChangeDiscount(e, '1')}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Col xs="5" />
                        <Col xs="7" className="new-voucher-left-input-col">
                          <div className="new-voucher-left-input-sign"><div className="new-voucher-left-input-sign-sign">%</div></div>
                          <Input
                            type="text"
                            className="new-voucher-left-input"
                            name="discount2"
                            id="discount2"
                            placeholder="Step 2 - e.g. 30%"
                            value={discount['2'] || ''}
                            onChange={(e) => this.handleChangeDiscount(e, '2')}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Col xs="5" />
                        <Col xs="7" className="new-voucher-left-input-col">
                          <div className="new-voucher-left-input-sign"><div className="new-voucher-left-input-sign-sign">%</div></div>
                          <Input
                            type="text"
                            className="new-voucher-left-input"
                            name="discount3"
                            id="discount3"
                            placeholder="Step 3 - e.g. 40%"
                            value={discount['3'] || ''}
                            onChange={(e) => this.handleChangeDiscount(e, '3')}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md="1" className="new-voucher-seperator">
                  <div className="new-voucher-seperator-line"></div>
                </Col>

                <Col md="4" className="new-voucher-right">
                  <Row className="new-voucher-right-row new-voucher-right-row-first">
                    <Col sm="3">
                      <Label className="new-voucher-label" data-tip data-for='totalValue' for="totalValue">
                        Campaign Value (per step)
                        <div className="new-voucher-info-icon">i</div>
                      </Label>
                    </Col>
                    <Col sm="9" className="new-voucher-right-input-col">
                      <div className="new-voucher-right-input-sign"><div className="new-voucher-right-input-sign-sign">£</div></div>
                      <Input
                        type="text"
                        className="new-voucher-right-input"
                        name="totalValue"
                        id="totalValue"
                        placeholder="Leave empty for unlimited"
                        value={totalValue ? formattedNumber(parseInt(totalValue, 10)) : ''}
                        onChange={this.handleChangeTotalValue}
                      />
                    </Col>
                  </Row>
                  <Row className="new-voucher-right-row  new-voucher-right-row-second">
                    <Col sm="3">
                      <Label className="new-voucher-label" data-tip data-for='start-date' for="start-date">
                        Campaign Start Date
                        <div className="new-voucher-info-icon">i</div>
                      </Label>
                    </Col>
                    <Col sm="9" className="new-voucher-right-input-col">
                      <DateTimePicker
                        className="date-picker new-voucher-right-input-start-date"
                        time={false}
                        culture="en"
                        format="MMM DD YYYY"
                        onChange={this.changeStartDate}
                        value={startDate}
                        defaultValue={new Date()}
                      />
                    </Col>
                  </Row>
                  <Row className="new-voucher-right-row">
                    <Col sm="3">
                      <Label className="new-voucher-label" data-tip data-for='expiry' for="expiry">
                        Voucher Redemption Timeframe
                        <div className="new-voucher-info-icon">i</div>
                      </Label>
                    </Col>
                    <Col sm="9" className="new-voucher-right-input-col">
                      <Input className="new-voucher-right-input-fullwidth" name="expiry" type="select" value={expiry} onChange={this.handleChange}>
                        <option value={'1'}>1 year</option>
                        <option value={'2'}>2 years</option>
                        <option value={'3'}>3 years</option>
                        <option value={'4'}>4 years</option>
                        <option value={'5'}>5 years</option>
                      </Input>
                    </Col>
                  </Row>
                </Col>
              </Row>



              <Row>
                <Col>
                  <div className="new-voucher-confirmations-recommended">
                    <span className="new-voucher-confirmations-recommended-text">
                      Highly recommended: Step 1 equals or exceeds discounts we offer elsewhere
                    </span>
                    <Input
                      type="checkbox"
                      name="recomendedDiscounts"
                      id="recomendedDiscounts"
                      value={recomendedDiscounts}
                      className="new-voucher-confirmations-recommended-checkbox"
                      onChange={this.handleChangeRecomendedDiscounts}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="new-voucher-confirmations-terms">
                    <span className="new-voucher-confirmations-terms-text">
                      I have read and accept the current
                    </span>
                    <span className="new-voucher-confirmations-terms-text">
                      <Link to="T&Cs" target="_blank" rel="noopener noreferrer">
                        T&Cs
                      </Link>
                    </span>
                    <Input
                      type="checkbox"
                      name="acceptRules"
                      id="acceptRules"
                      value={acceptRules}
                      className="new-voucher-confirmations-terms-checkbox"
                      onChange={this.handleChangeAcceptRules}
                    />
                  </div>
                </Col>
              </Row>
              {company && company.isTester ? (
                <Row>
                  <Col>
                    <div style={{ float: 'right', marginBottom: 15, marginTop: 5 }}>
                      <span style={{ paddingRight: 5 }}>
                        Test mode
                      </span>
                      <Input
                        type="checkbox"
                        name="testMode"
                        id="testMode"
                        value={testMode}
                        onChange={this.handleChangeTestMode}
                      />
                    </div>
                  </Col>
                </Row>
              ) : (null)}

              <Row>
                <Col>
                  <Button disabled={processing || showNoPermission} style={{ width: '20%', float: 'right', marginBottom: 15 }} color="primary" onClick={this.createVoucher}>
                    {processing ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                    ) : (null)}
                    Request
                  </Button>
                </Col>
              </Row>

            </Col>
          </Row>

          <Modal isOpen={previewModal} toggle={this.closePreview}>
            <ModalHeader toggle={this.closePreview}>
              Preview voucher campaign
            </ModalHeader>
            <ModalBody>
              <div className="voucher-preview">
                <div className="voucher-preview-top">
                  {primaryVoucherImage ? (
                    <Media className="voucher-preview-top-image" src={primaryVoucherImage} />
                  ) : null}
                </div>

                <div className="voucher-preview-header">
                  {logo ? (
                    <>
                      <Media className="voucher-preview-header-logo" src={logo} />
                      <div className="voucher-preview-header-text">
                        <div className="voucher-preview-header-name">
                          {company.name}
                        </div>
                        <div className="voucher-preview-header-website">
                          {company.website ? company.website.replace(/(^\w+:|^)\/\//, '') : ''}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="voucher-preview-body">
                  <div className={readMore ? 'voucher-preview-body-description' : 'voucher-preview-body-description-hidden'}>
                    {company.description}
                  </div>
                  <Button className="voucher-preview-body-more" color="link" onClick={this.toggleReadMore}>
                    {readMore ? 'show less' : 'read more'}
                  </Button>
                </div>
                <div className="voucher-preview-loyalty-text"> Loyalty steps </div>
                <div className="voucher-preview-footer">
                  <div className="voucher-preview-steps">
                    <Media className="voucher-preview-steps-image" src={line} />
                    <div className="voucher-preview-steps-1">
                      <div className="voucher-preview-steps-percent">
                        {discount ? `${discount['1']}%` : null}
                      </div>
                      <div className="voucher-preview-steps-name">
                        Step 1
                      </div>
                    </div>
                    <div className="voucher-preview-steps-2">
                      <div className="voucher-preview-steps-percent">
                        {discount ? `${discount['2']}%` : null}
                      </div>
                      <div className="voucher-preview-steps-name">
                        Step 2
                      </div>
                    </div>
                    <div className="voucher-preview-steps-3">
                      <div className="voucher-preview-steps-percent">
                        {discount ? `${discount['3']}%` : null}
                      </div>
                      <div className="voucher-preview-steps-name">
                        Step 3
                      </div>
                    </div>
                  </div>

                  <div className="voucher-preview-vouchers">
                    <div className="voucher-preview-voucher">
                      <div className="voucher-preview-vouchers-left">
                        <div className="voucher-preview-vouchers-left-1">
                          {`£${availableVouchers ? availableVouchers['0'] : null}`}
                        </div>
                        <div className="voucher-preview-vouchers-left-2">
                          VOUCHER
                        </div>
                      </div>
                      <div className="voucher-preview-vouchers-right">
                        <div className="voucher-preview-vouchers-right-1">
                          BUY FOR
                        </div>
                        <div className="voucher-preview-vouchers-right-2">
                          {(availableVouchers && discount && availableVouchers['0'] && discount['1']) ? `£${(availableVouchers['0'] - (availableVouchers['0'] * (discount['1'] / 100)))}` : null}
                        </div>
                      </div>
                    </div>
                    <div className="voucher-preview-voucher">
                      <div className="voucher-preview-vouchers-left">
                        <div className="voucher-preview-vouchers-left-1">
                          {`£${availableVouchers ? availableVouchers['1'] : null}`}
                        </div>
                        <div className="voucher-preview-vouchers-left-2">
                          VOUCHER
                        </div>
                      </div>
                      <div className="voucher-preview-vouchers-right">
                        <div className="voucher-preview-vouchers-right-1">
                          BUY FOR
                        </div>
                        <div className="voucher-preview-vouchers-right-2">
                          {(availableVouchers && discount && availableVouchers['1'] && discount['2']) ? `£${(availableVouchers['1'] - (availableVouchers['1'] * (discount['2'] / 100)))}` : null}
                        </div>
                      </div>
                    </div>
                    <div className="voucher-preview-voucher">
                      <div className="voucher-preview-vouchers-left">
                        <div className="voucher-preview-vouchers-left-1">
                          {`£${availableVouchers ? availableVouchers['2'] : null}`}
                        </div>
                        <div className="voucher-preview-vouchers-left-2">
                          VOUCHER
                        </div>
                      </div>
                      <div className="voucher-preview-vouchers-right">
                        <div className="voucher-preview-vouchers-right-1">
                          BUY FOR
                        </div>
                        <div className="voucher-preview-vouchers-right-2">
                          {(availableVouchers && discount && availableVouchers['2'] && discount['3']) ? `£${(availableVouchers['2'] - (availableVouchers['2'] * (discount['3'] / 100)))}` : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.closePreview}>Close</Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  roles: state.users.companiesRoles || {},
  adminsRoles: state.admin.adminsRoles || {},
  dashboardTooltips: state.contentManagement.newVoucherTooltips || {},
  company: state.company.company || {},
  adminViewAsCompany: state.admin.adminViewAsCompany || null,
  vouchers: state.vouchers.vouchers || {},
  branding: state.contentManagement.branding || {},
});

const mapDispatchToProps = {
  addVoucher: createVoucher,
  fetchNewVoucherTooltips: getNewVoucherTooltips,
  fetchNewVoucherPageData: getNewVoucherPageData,
  updateLastActiveFunc: updateLastActive,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewVoucher));
