
import React from 'react';
import PropTypes from 'prop-types';

import { ComposedChart, ResponsiveContainer, XAxis, YAxis, Tooltip, BarChart, Bar, LineChart, Line, Area, ReferenceLine, CartesianGrid, LabelList, Legend } from 'recharts';

const renderCustomizedLabel = (props, data) => {
  const { x, y, width, value, index } = props;
  const radius = 10;

  if (index === data.length - 1 && value) {
    return (
      <g>
        <text x={x + (width || 1) / 2} y={y - radius - 1} fill="#000" textAnchor="middle" dominantBaseline="middle">
          {value}
        </text>
      </g>
    );
  }

  return null;
};

function getTicks(array) {
  const step = Math.ceil(array.length / 10);
  console.log('step', step);

  let ticks = [];

  array.map((item, index) => {
    if (index % step === 0) {
      console.log('push item', item.key);
      ticks.push(item.key);
    }
  });

  console.log('ticks', ticks);

  return ticks;
}

let chartId = -1;
let chartColors = ["orange", '#5CB4FF', "green", 'red', "blue"]

const renderType = (dataKey, data, index, fill) => {
  chartId = chartId + 1;

  if (dataKey.type === 'line') {
    // fill={chartColors[index] || '#5CB4FF'}
    return (
      <Line isAnimationActive={false} yAxisId={dataKey.axis || "left"} type="monotone" dataKey={dataKey.data} name={dataKey.name} stroke={chartColors[index] || "#5CB4FF"} strokeWidth="2" >
        <LabelList dataKey={dataKey.data} position="top" content={(props) => renderCustomizedLabel(props, data)} />
      </Line>
    );
  }

  /*
         <Line isAnimationActive={false} yAxisId={dataKey.axis || "left"} type="monotone" dataKey={dataKey.data} name={dataKey.name} stroke={chartColors[index] || '#5CB4FF'} strokeWidth="2" >
        <LabelList dataKey={dataKey.data} position="top" content={(props) => renderCustomizedLabel(props, data)} />
      </Line>
   */
  if (dataKey.type === 'area') {
    return (

      <Area isAnimationActive={false} yAxisId={dataKey.axis || "left"} type="monotone" dataKey={dataKey.data} name={dataKey.name} stroke={chartColors[index] || "#5CB4FF"} strokeWidth="2" fillOpacity={1} fill={fill ? fill : '#D4E9F7'}>
          <LabelList dataKey={dataKey} position="top" content={(props) => renderCustomizedLabel(props, data)} />
      </Area >
    );
  }

  if (dataKey.type === 'bar') {
    return (
      <Bar maxBarSize={12} isAnimationActive={false} yAxisId={dataKey.axis || "left"} type="monotone" dataKey={dataKey.data} name={dataKey.name} fill={chartColors[index] || '#5CB4FF'}>
        <LabelList dataKey={dataKey.data} position="top" content={(props) => renderCustomizedLabel(props, data)} />
      </Bar>
    );
  }

  return null;
};

const MultiGraph = React.memo(({
  data, interval, dataKeys, name, grid, fill,
}) => {
  if (!dataKeys || !dataKeys.length) {
    return null;
  }

  return(
    <ResponsiveContainer width='92%' height={270} marginLeft={'auto'} marginRight={'auto'} overflow={'visible'}>
      <ComposedChart data={data} margin={{ left: -10, right: -80 }}>
        <Legend style={{ fontSize: 12 }} layout="horizontal" verticalAlign="top" align="center" />
        <XAxis dataKey="key" padding={{ right: 15 }} angle={-60} textAnchor='end' interval={(data.length <= 10) ? 0 : (Math.ceil(data.length / 10) - 1)} tickCount={10} tick={{ fontSize: 13 }} /> {/* interval={interval - 1} tickCount={8} ticks={getTicks(data)} minTickGap={1} */}

        <YAxis width={80} padding={{ top: 12 }} tick={{ fontSize: 10 }} yAxisId="left" />
        <YAxis width={80} padding={{ top: 12 }} tick={{ fontSize: 10 }} yAxisId="right" orientation="right" />

        <Tooltip />
        {grid ? (
          <CartesianGrid vertical={false} />
        ):(null)}

        {dataKeys.map((dataKey, index) => (renderType(dataKey, data, index, fill)))}
      </ComposedChart>
    </ResponsiveContainer>
  )
});


MultiGraph.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

MultiGraph.defaultProps = {
  checked: false,
};

export default MultiGraph;
