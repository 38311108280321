import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Button, Input, Label, Form, FormGroup, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

import { connect } from 'react-redux';

import {
  getEventCategories,
  updateEventCategories,
} from '../../../../actions/contentManagement';

import notifications from '../../../../const/notifications';

class EventCategoriesTab extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    categories: PropTypes.arrayOf(PropTypes.shape()),
    getCategories: PropTypes.func.isRequired,
    updateCategories: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    categories: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      data: {},
      categories: [],
    };
  }

  componentDidMount() {
    const { getCategories } = this.props;

    getCategories().then((res) => {
      if (res && res.categories) {
        this.setState({ categories: res.categories });
      }
    });
  }

  handleChange = (e) => {
    e.preventDefault();

    if (e.target) {
      const { value } = e.target;
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          value: value,
          label: value,
        },
        error: null,
        success: null,
      }))
    }
  };

  toggle = (id, value) => {
    const { updateCategory, categories } = this.props;

    const category = categories[id];

    if (category) {
      let enabled = !category.enabled;
      updateCategory(id, enabled);
    }
  }

  openAddModal = () => {
    this.setState({ addModal: true, editModal: false, deleteModal: false, data: { value: '', label: '' } });
  }

  closeAddModal = () => {
    this.setState({ addModal: false, data: { value: '', label: '' } });
  }

  addCategory = () => {
    const { updateCategories, user } = this.props;
    const { data, categories } = this.state;

    this.setState({ saveSpinner: 'addCategory' });

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    updateCategories([...categories, data], adminName).then((res) => {
      if (res === 'success') {
        this.setState({ saveSpinner: null, categories: [...categories, data], success: true, error: false, addModal: false, editModal: false });
      } else {
        this.setState({ saveSpinner: null, success: false, error: true, addModal: false, editModal: false });
      }
    }).catch(err => {
      if (err === 'no value') {
        this.setState({ error: false, success: false, addError: notifications.admin.contentManagement.noValue });
      } else {
        this.setState({ error: false, success: false, addError: err });
      }
    });
  }

  openEditModal = (index, data) => {
    this.setState({ editIndex: index, editModal: true, addModal: false, deleteModal: false, data: data });
  }

  closeEditModal = () => {
    this.setState({ editIndex: null, editModal: false, data: { value: '', label: '' } });
  }

  editCategory = () => {
    const { updateCategories, user } = this.props;
    const { editIndex, data, categories } = this.state;

    const newCategories = [ ...categories ];
    newCategories[editIndex] = data;

    this.setState({ saveSpinner: 'editCategory' });

    console.log('editCategory', newCategories);

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    updateCategories(newCategories, adminName).then((res) => {
      if (res === 'success') {
        this.setState({ saveSpinner: null, editIndex: null, categories: newCategories, success: true, error: false, addModal: false, editModal: false });
      } else {
        this.setState({ saveSpinner: null, success: false, error: true, addModal: false, editModal: false });
      }
    }).catch(err => {
      if (err === 'no value') {
        this.setState({ error: false, success: false, addError: notifications.admin.contentManagement.noValue });
      } else {
        this.setState({ error: false, success: false, addError: err });
      }
    });
  }

  removeCategory = (index) => {
    const { updateCategories, user } = this.props;
    const { categories } = this.state;

    const newCategories = [ ...categories ]
    newCategories.splice(index, 1);

    this.setState({ saveSpinner: 'removeCategory' });

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    updateCategories(newCategories, adminName).then((res) => {
      if (res === 'success') {
        this.setState({ saveSpinner: null, categories: newCategories, success: true, error: false, addModal: false, editModal: false });
      } else {
        this.setState({ saveSpinner: null, success: false, error: true, addModal: false, editModal: false });
      }
    });
  }

  render() {
    const { showNoPermission } = this.props;
    const {
      error,
      addError,
      success,
      addModal,
      editModal,
      data,
      categories,
      saveSpinner,
    } = this.state;

    return (
      <div>
        {(!!success) && <Alert color="success">{notifications.admin.contentManagement.updateSuccessful}</Alert>}
        {(!!error) && <Alert color="danger">{notifications.admin.contentManagement.updateFailed}</Alert>}

        <Button disabled={showNoPermission} onClick={() => this.openAddModal()}>
          Add category
        </Button>

        <Row>
         <Col sm="4">
            Value
          </Col>
          <Col sm="4">
            Edit
          </Col>
          <Col sm="4">
            Remove
          </Col>
        </Row>
        {categories ? categories.map((category, index) => (
          <Row>
            <Col sm="4">
              {category.value}
            </Col>
            <Col sm="4">
              <Button disabled={showNoPermission} onClick={() => this.openEditModal(index, category)}>
                Edit
              </Button>
            </Col>
            <Col sm="4">
              <Button disabled={showNoPermission} onClick={() => this.removeCategory(index)}>
                Remove
                {(saveSpinner === 'removeCategory') ? (
                  <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                ):(null)}
              </Button>
            </Col>
          </Row>
        )) : (null)}

        <Modal isOpen={addModal} toggle={this.closeAddModal}>
          <ModalHeader toggle={this.closeAddModal}>
            Add Category
          </ModalHeader>
          <ModalBody>
            <Form>
              {!!addError ? (<Alert color="danger"> {addError} </Alert>) : (null)}
              <FormGroup>
                <Label>Value</Label>
                <Input
                  type="text"
                  value={data.value}
                  name="value"
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.addCategory}>
              Save
              {(saveSpinner === 'addCategory') ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ):(null)}
            </Button>
            <Button color="secondary" onClick={this.closeAddModal}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={editModal} toggle={this.closeEditModal}>
          <ModalHeader toggle={this.closeEditModal}>
            Edit Category
          </ModalHeader>
          <ModalBody>
            <Form>
              {!!addError ? (<Alert color="danger"> {addError} </Alert>) : (null)}
              <FormGroup>
                <Label>Value</Label>
                <Input
                  type="text"
                  value={data.value}
                  name="value"
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.editCategory}>
              Edit
              {(saveSpinner === 'editCategory') ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ):(null)}
            </Button>
            <Button color="secondary" onClick={this.closeEditModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  eventCategories: state.contentManagement.eventCategories || [],
});

const mapDispatchToProps = {
  getCategories: getEventCategories,
  updateCategories: updateEventCategories,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventCategoriesTab));
