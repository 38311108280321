import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { X } from 'react-feather';

import { Row, Col, Card, CardBody, Alert } from 'reactstrap';

import { connect } from 'react-redux';

import {
  dismissCompanyNotification,
  updateLastActive,
  getComplianceData,
  getShopifyAPIData,
  getWoocommerceAPIData,
  getActiveAPIData,
} from '../../../../actions/company';

import {
  getDashboardTooltips,
  getNewVoucherTooltips,
  getCompanyPopoutData,
} from '../../../../actions/contentManagement';

import {
  getCompanyVouchers,
  getVoucherSales,
} from '../../../../actions/vouchers';

import {
  checkStripeCompanyStatus,
} from '../../../../actions/user';


import LiveMetrics from './LiveMetrics';
import VouchersList from './VouchersList';

import AutoRefresh from '../../../components/AutoRefresh/AutoRefresh';
import SetupAssistance from '../../../components/SetupAssistance/SetupAssistance';

class Dashboard extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    fetchDashboardTooltips: PropTypes.func.isRequired,
    fetchCompanyPopoutData: PropTypes.func.isRequired,
    fetchNewVoucherTooltips: PropTypes.func.isRequired,
    dismissNotification: PropTypes.func.isRequired,
    fetchCompanyVouchers: PropTypes.func.isRequired,
    getStripeCompanyStatus: PropTypes.func.isRequired,
    fetchComplianceData: PropTypes.func.isRequired,
    getShopifyAPI: PropTypes.func.isRequired,
    getWoocommerceAPI: PropTypes.func.isRequired,
    getActiveAPI: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      showNoPermission: false,
      dontShowPage: false,
    };
  }

  componentDidMount() {
    const {
      fetchCompanyPopoutData,
      fetchDashboardTooltips,
      fetchCompanyVouchers,
      getStripeCompanyStatus,
      fetchComplianceData,
      getShopifyAPI,
      getWoocommerceAPI,
      getActiveAPI,
      fetchNewVoucherTooltips,
      user,
      history,
      adminViewAsCompany,
      isLoggedIn,
      updateLastActiveFunc
    } = this.props;

    if ((user && Object.keys(user).length > 0) || isLoggedIn) {
      fetchDashboardTooltips();
      fetchNewVoucherTooltips();
      fetchCompanyPopoutData();
      if (user.company) {
        fetchDashboardTooltips();
        fetchNewVoucherTooltips();

        fetchCompanyVouchers(user.company);
        updateLastActiveFunc(user.company);
        getStripeCompanyStatus(user.company);
        fetchComplianceData(user.company);
        getShopifyAPI(user.company);
        getWoocommerceAPI(user.company);
        getActiveAPI(user.company);

        this.checkSetupStatus();

        if (user.role === 'teamOwner') {
          this.setState({ showNoPermission: false, dontShowPage: false });
        } else {
          const { roles } = this.props;

          if (roles && roles[user.role] && roles[user.role].permissions && roles[user.role].permissions.dashboard) {
            if (roles[user.role].permissions.dashboard === 2) {
              this.setState({ showNoPermission: false, dontShowPage: false });
            } else {
              this.setState({ showNoPermission: true, dontShowPage: false });
            }
          } else {
            this.setState({ showNoPermission: true, dontShowPage: true });
          }
        }
      } else if (adminViewAsCompany && user.roleClass === 'admin') {
        const { adminsRoles } = this.props;

        fetchCompanyVouchers(adminViewAsCompany);
        getStripeCompanyStatus(adminViewAsCompany);
        fetchComplianceData(adminViewAsCompany);
        getShopifyAPI(adminViewAsCompany);
        getWoocommerceAPI(adminViewAsCompany);
        getActiveAPI(adminViewAsCompany);

        this.checkSetupStatus();

        if (user.role === 'admin') {
          this.setState({ showNoPermission: false, dontShowPage: false });
        } else {
          if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.companyAccess && adminsRoles[user.role].permissions.companyAccessDashboard) {
            if (adminsRoles[user.role].permissions.companyAccessDashboard === 2) {
              this.setState({ showNoPermission: false, dontShowPage: false });
            } else {
              this.setState({ showNoPermission: true, dontShowPage: false });
            }
          } else {
            this.setState({ showNoPermission: true, dontShowPage: true });
          }
        }
      } else if (!adminViewAsCompany && user.roleClass === 'admin') {
        console.log('history.push');
        history.push('/admin/dashboard');
      }
    } else {
      console.log('history.push');
      history.push('/');
    }
  }

  componentDidUpdate() {
    const { basicInformationSetup, apiSetup, paymentsSetup, vouchersLength } = this.state;

    // console.log('basicInformationSetup componentDidUpdate', basicInformationSetup, apiSetup, paymentsSetup, vouchersLength);

    if (!(basicInformationSetup === 100 && apiSetup == 100 && paymentsSetup === 100 && vouchersLength)) {
      this.checkSetupStatus();
    } else {
    //   console.log('setup finished');
    }
  }

  checkSetupStatus = () => {
    const {
      companyData,
      complianceData,
      stripeChargesEnabled,
      stripePayoutsEnabled,
      stripeDataFetched,
      shopifyApiKey,
      woocommerceApiKey,
      activeAPI,
      vouchers,
    } = this.props;
    const { basicInformationSetup, apiSetup, paymentsSetup, vouchersLength, companyDataFetched, setupCompleteFully } = this.state;

    const vouchersLengthVar = vouchers ? Object.keys(vouchers).length : 0;

    const { dateCreated, owner, companyName, companyType, companyNumber, website, primaryVoucherImage, logo, category1, description } = companyData;

  //  let basicInformationSetupVar = false, apiSetupVar = false, paymentsSetupVar = false;
    let basicInformationSetupProgress = 0, apiSetupProgress = 0, paymentsSetupProgress = 0;

    /*
    if (activeAPI) {
      if (activeAPI === 'woocommerce') {
        if (woocommerceApiKey && Object.keys(woocommerceApiKey).length) apiSetup = true;
      } else if (shopifyApiKey && Object.keys(shopifyApiKey).length) apiSetup = true;
    } else if (shopifyApiKey && Object.keys(shopifyApiKey).length) apiSetup = true; */

    console.log('companyDatacompanyData', companyData);

    if (companyData.linkedCompaniesAccount) {
      if (companyData.allValidApis && companyData.someValidApis) {
        apiSetupProgress = 100;
      }
    } else {
      if (activeAPI && activeAPI === 'woocommerce') {
        if (woocommerceApiKey && Object.keys(woocommerceApiKey).length) {
          //  apiSetupVar = true;
          apiSetupProgress = 100;
        }
      } if (activeAPI && activeAPI === 'manual') {
        if (companyData && companyData.validManual) {
          apiSetupProgress = 100;
        }
      } else if (shopifyApiKey && Object.keys(shopifyApiKey).length) {
        //  apiSetupVar = true;
        apiSetupProgress = 100;
      }
    }

    let complianceDataQ1 = false, complianceDataQ2 = false, complianceDataQ3 = false, complianceDataQ4 = false, complianceDataQ5 = false;

    if (complianceData) {
      if (complianceData.question1 || complianceData.question1 === false) complianceDataQ1 = true;
      if (complianceData.question2 || complianceData.question2 === false) complianceDataQ2 = true;
      if (complianceData.question3 || complianceData.question3 === false) complianceDataQ3 = true;
      if (complianceData.question4 || complianceData.question4 === false) complianceDataQ4 = true;
      if (complianceData.question5 || complianceData.question5 === false) complianceDataQ5 = true;
    }

    if (companyData && Object.keys(companyData) && Object.keys(companyData).length && dateCreated && owner) {
      if ((companyName && (companyType && (companyNumber || companyType === 'individual')) && website && primaryVoucherImage && logo && category1 && description
        && complianceDataQ1 && complianceDataQ2 && complianceDataQ3 && complianceDataQ4 && complianceDataQ5)) {
        //  basicInformationSetupVar = true;
        basicInformationSetupProgress = 100;
      } else {
        basicInformationSetupProgress = 0;
      }

      /* else {
        if (companyName) { basicInformationSetupProgress = basicInformationSetupProgress + 9 }
        if (companyType && (companyNumber || companyType === 'individual')) { basicInformationSetupProgress = basicInformationSetupProgress + 8 }
        if (website) { basicInformationSetupProgress = basicInformationSetupProgress + 8 }
        if (primaryVoucherImage) { basicInformationSetupProgress = basicInformationSetupProgress + 9 }
        if (logo) { basicInformationSetupProgress = basicInformationSetupProgress + 8 }
        if (category1) { basicInformationSetupProgress = basicInformationSetupProgress + 8 }
        if (description) { basicInformationSetupProgress = basicInformationSetupProgress + 9 }
        if (complianceDataQ1) { basicInformationSetupProgress = basicInformationSetupProgress + 8 }
        if (complianceDataQ2) { basicInformationSetupProgress = basicInformationSetupProgress + 8 }
        if (complianceDataQ3) { basicInformationSetupProgress = basicInformationSetupProgress + 9 }
        if (complianceDataQ4) { basicInformationSetupProgress = basicInformationSetupProgress + 8 }
        if (complianceDataQ5) { basicInformationSetupProgress = basicInformationSetupProgress + 8 }
      } */

      if ((stripeChargesEnabled && stripePayoutsEnabled && stripeDataFetched) || vouchersLength) {
      //  paymentsSetupVar = true;
        paymentsSetupProgress = 100;
      } else if (stripeDataFetched) {
        if (stripeChargesEnabled || stripePayoutsEnabled) {
          paymentsSetupProgress = 0;
        } else {
          paymentsSetupProgress = 0;
        }
      }

      if (!companyDataFetched) {
        this.setState({ companyDataFetched: true });
      }
    }

  //  console.log('basicInformationSetup', basicInformationSetup, basicInformationSetupProgress);
  //  console.log('apiSetup', apiSetup, apiSetupProgress, shopifyApiKey, activeAPI);
  //  console.log('paymentsSetup', paymentsSetup, paymentsSetupProgress, stripeChargesEnabled, stripePayoutsEnabled);

    if (basicInformationSetup !== basicInformationSetupProgress) {
      console.log('basicInformationSetup update1', basicInformationSetupProgress);
      this.setState({ basicInformationSetup: basicInformationSetupProgress });
    }

    if (apiSetup !== apiSetupProgress) {
      console.log('basicInformationSetup update2', apiSetupProgress);
      this.setState({ apiSetup: apiSetupProgress });
    }
    if (paymentsSetup !== paymentsSetupProgress) {
      console.log('basicInformationSetup update3', paymentsSetupProgress);
      this.setState({ paymentsSetup: paymentsSetupProgress });
    }
    if (vouchersLength !== vouchersLengthVar) {
      console.log('basicInformationSetup update4');
      this.setState({ vouchersLength: vouchersLengthVar });
    }

  //  console.log('set setupCompleteFully', basicInformationSetup, apiSetup, paymentsSetup);

    if (!setupCompleteFully && basicInformationSetupProgress === 100 && apiSetupProgress === 100 && paymentsSetupProgress === 100) {
    //  console.log('basicInformationSetup update5', basicInformationSetup, apiSetup, paymentsSetup);
      this.setState({ setupCompleteFully: true });
    }

    // this.setState({ basicInformationSetup: basicInformationSetupVar, apiSetup: apiSetupVar, paymentsSetup: paymentsSetupVar, vouchersLength: vouchersLengthVar });

    return null;
  };

  dismissNotification = (id) => {
    const { dismissNotification, company } = this.props;

    if (company && id) {
      dismissNotification(company, id);
    }
  }

  refresh = () => {
    const { user, adminViewAsCompany, fetchCompanyVouchers } = this.props;
  //  const { currentOrdering, isAscending, filterValue } = this.state;

    if (user.company) {
      fetchCompanyVouchers(user.company);
    } else if (adminViewAsCompany) {
      fetchCompanyVouchers(adminViewAsCompany);
    }

    //  if (isAscending) {
    //    fetchCompaniesFiltered('companies', currentOrdering, 'descending', filterValue);
    //  } else {
    //    fetchCompaniesFiltered('companies', currentOrdering, 'ascending', filterValue);
    //  }
  }

  refreshLiveMetrics = (company, campaignFilter, timeFilter, startDate, endDate) => {
    const { fetchVoucherSales } = this.props;
  //  const { campaignFilter, timeFilter, startDate, endDate } = this.state;

    if (campaignFilter === 'all') {
      if (company) {
        if (timeFilter === 'custom') {
          fetchVoucherSales(company, null, timeFilter, startDate, endDate);
        } else if (timeFilter === 'allTime') {
          fetchVoucherSales(company, null, timeFilter, startDate, null);
        } else {
          fetchVoucherSales(company, null, timeFilter, null, null);
        }
    //  } else if (adminViewAsCompany) {
    //    if (timeFilter === 'custom') {
    //      fetchVoucherSales(adminViewAsCompany, null, timeFilter, startDate, endDate);
    //    } else {
    //      fetchVoucherSales(adminViewAsCompany, null, timeFilter, null, null);
    //    }
      }
    } else {
      if (timeFilter === 'custom') {
        fetchVoucherSales(null, campaignFilter, timeFilter, startDate, endDate);
      } else if (timeFilter === 'allTime') {
        fetchVoucherSales(null, campaignFilter, timeFilter, startDate, null);
      } else {
        fetchVoucherSales(null, campaignFilter, timeFilter, null, null);
      }
    }
  }

  render() {
    const { companyData, companyNotifications, dissmissedNotifications, dashboardTooltips, adminViewAsCompany, stripeDataFetched } = this.props;
    const {
      showNoPermission,
      dontShowPage,
      basicInformationSetup,
      apiSetup,
      paymentsSetup,
      vouchersLength,
      setupCompleteFully,
      companyDataFetched,
    } = this.state;

    if (dontShowPage) {
      return (
        <div>
          <Card>
            <CardBody>
              You don't have permissions to view the dashboard
            </CardBody>
          </Card>
        </div>
      );
    }

    console.log('SetupAssistance test', companyDataFetched, setupCompleteFully, stripeDataFetched, vouchersLength);
    console.log('companycompanyData', companyData, companyNotifications);

    return (
      <div>
        <Row style={{ position: 'relative' }}>
          <Col xs="10" />
          <Col xs="2">
            <div style={{ display: 'block', position: 'relative' }}>
              <AutoRefresh refreshFunction={this.refresh} refreshTime={60} hidden />
            </div>
          </Col>
        </Row>
        {(!setupCompleteFully && !vouchersLength && stripeDataFetched && companyDataFetched) ? (
          <SetupAssistance basicInformationSetup={basicInformationSetup} apiSetup={apiSetup} paymentsSetup={paymentsSetup} vouchersLength={vouchersLength} />
        ) : (null)}
        {console.log('companyNotifications', companyNotifications) }
        {(companyNotifications) ? (
          <>
            {companyNotifications.map((notification) => {
              if (notification && (!dissmissedNotifications || (!dissmissedNotifications[notification.id]))) {
                if (notification.newOnlyDate) {
                  console.log('notification.newOnlyDate > companyData.dateCreated', (notification.newOnlyDate > companyData.dateCreated));
                  console.log('notification.newOnlyDate < companyData.dateCreated', (notification.newOnlyDate < companyData.dateCreated));
                }

                if (notification.newOnlyDate && notification.newOnlyDate > companyData.dateCreated) {
                  return null;
                }
                return (
                  <Alert color="success" className="body-notification">
                    {`${notification.text}`}
                    <div onClick={() => this.dismissNotification(notification.id)} className="body-notification-close">
                      <X />
                    </div>
                  </Alert>
                )
              } else { return null }
            })}
          </>
        ):(null)}
        <LiveMetrics dashboardTooltips={dashboardTooltips} refresh={this.refresh} refreshLiveMetrics={this.refreshLiveMetrics} />
        <Card>
          <CardBody>
          {/*  {(companyNotifications) ? (
              <>
                {companyNotifications.map((notification) => {
                  if (notification && (!dissmissedNotifications || (!dissmissedNotifications[notification.id]))) {
                    return (
                      <Alert color="success" className="body-notification">
                        {`${notification.text}`}
                        <div onClick={() => this.dismissNotification(notification.id)} className="body-notification-close">
                          <X />
                        </div>
                      </Alert>
                    )
                  } else { return null }
                })}
              </>
            ):(null)}
            <LiveMetrics dashboardTooltips={dashboardTooltips} refresh={this.refresh} refreshLiveMetrics={this.refreshLiveMetrics} /> */}
            <VouchersList showNoPermission={showNoPermission} dashboardTooltips={dashboardTooltips} refresh={this.refresh} />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  roles: state.users.companiesRoles || {},
  companyNotifications: state.company.companyNotifications || [],
  company: state.company.companyOwnerData ? state.company.companyOwnerData.company : null,
  dissmissedNotifications: state.company.companyOwnerData ? state.company.companyOwnerData.dissmissedNotifications : {},
  dashboardTooltips: state.contentManagement.dashboardTooltips || {},
  adminsRoles: state.admin.adminsRoles || {},
  adminViewAsCompany: state.admin.adminViewAsCompany || null,

  companyData: state.company.company || {},
  vouchers: state.vouchers.vouchers || null,
  complianceData: state.company.complianceData || {},
  stripeChargesEnabled: state.user.stripeChargesEnabled || null,
  stripePayoutsEnabled: state.user.stripePayoutsEnabled || null,
  stripeDataFetched: state.user.stripeDataFetched || null,

  shopifyApiKey: state.company.shopifyApiKey || null,
  woocommerceApiKey: state.company.woocommerceApiKey || null,
  activeAPI: state.company.activeAPI || null,
  // activeAPI: state.company.activeAPI || null,
});

const mapDispatchToProps = {
  fetchDashboardTooltips: getDashboardTooltips,
  fetchNewVoucherTooltips: getNewVoucherTooltips,
  fetchCompanyPopoutData: getCompanyPopoutData,
  dismissNotification: dismissCompanyNotification,
  fetchCompanyVouchers: getCompanyVouchers,
  fetchVoucherSales: getVoucherSales,
  updateLastActiveFunc: updateLastActive,
  getStripeCompanyStatus: checkStripeCompanyStatus,
  fetchComplianceData: getComplianceData,

  getShopifyAPI: getShopifyAPIData,
  getWoocommerceAPI: getWoocommerceAPIData,
  getActiveAPI: getActiveAPIData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
