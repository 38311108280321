import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Card, CardBody, Table, Button, Input, Spinner, Alert } from 'reactstrap';

import { connect } from 'react-redux';

import {
  getNewVoucherTooltips,
  updateNewVoucherTooltip,
} from '../../../../../actions/contentManagement';

class NewVoucherTooltips extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    dashboardTooltips: PropTypes.shape(),
    fetchTooltips: PropTypes.func.isRequired,
    updateTooltip: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    dashboardTooltips: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      tooltips: {},
      subTabs: [],
      newVoucherSubTab: {},
    };
  }

  componentDidMount() {
    const { fetchTooltips, admin, user, isLoggedIn, history } = this.props;

    fetchTooltips().then((res) => {
      console.log('fetchTooltips', { res });
      if (res && res.tooltips) {
        let tooltips ={};
        let subTabs =[{ id: "newVoucher", name: "New Voucher" }];
        let newVoucherSubTab = {};

        Object.keys(res.tooltips).map((key) => {
          if (res.tooltips[key].subTab && res.tooltips[key].subTab === 'newVoucher') {
            newVoucherSubTab[key] = res.tooltips[key];
          } else {
            tooltips[key] = res.tooltips[key];
          }
          return null;
        });
        this.setState({ tooltips, subTabs, newVoucherSubTab });
      }
    });
  }

  openSubtab = (id) => {
    this.setState({ openedTab: id });
  }

  handleChange = (e, type) => {
    e.preventDefault();

    console.log('target', { target: e.target });
    if (e.target) {
      const { name, value } = e.target;
      console.log(e.target.name);

      if (type === 'newVoucher') {
        this.setState((prevState) => ({
          newVoucherSubTab: {
            ...prevState.newVoucherSubTab,
            [name]: {
              ...prevState.newVoucherSubTab[name],
              value,
            },
          },
          error: null,
          success: null,
        }));
      } else {
        this.setState((prevState) => ({
          tooltips: {
            ...prevState.tooltips,
            [name]: {
              ...prevState.tooltips[name],
              value,
            },
          },
          error: null,
          success: null,
        }));
      }
    }
  };

  updateTooltips = () => {
    const { updateTooltips, user } = this.props;
    const { tooltips, newVoucherSubTab } = this.state;

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    this.setState({ saveSpinner: true });

    updateTooltips({ ...newVoucherSubTab, ...tooltips }, adminName).then((res) => {
      if (res === 'success') {
        this.setState({ success: true, error: null, saveSpinner: null });
      } else if (res === 'error') {
        this.setState({ success: null, error: true, saveSpinner: null });
      }
    });
  }

  render() {
    const { user } = this.props;
    const {
      error,
      success,
      name,
      tooltips,
      subTabs,
      newVoucherSubTab,
      openedTab,
      saveSpinner,
    } = this.state;

    console.log('newVoucherSubTab', tooltips, subTabs, newVoucherSubTab);

    return (
      <div>
        {(!!success) && <Alert color="success">Update successful</Alert>}
        {(!!error) && <Alert color="danger">Update failed, please try again</Alert>}
        {tooltips ? Object.keys(tooltips).sort((a, b) => (tooltips[a].index - tooltips[b].index)).map((key) => (
          <Row>
            <Col sm="6">
              {tooltips[key].name}
            </Col>
            <Col sm="6">
              <Input
                type="textarea"
                name={key}
                id={`data-${key}`}
                value={tooltips[key].value}
                onChange={(e) => this.handleChange(e, null)}
              />
            </Col>
          </Row>
        )) : (null)}

        <Button onClick={this.updateTooltips}>
          Save
          {saveSpinner ? (
            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
          ) : (null)}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  newVoucherTooltips: state.contentManagement.newVoucherTooltips || {},
});

const mapDispatchToProps = {
  fetchTooltips: getNewVoucherTooltips,
  updateTooltips: updateNewVoucherTooltip,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewVoucherTooltips));

// export default Dashboard;
