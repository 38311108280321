import { firestore, functions } from '../../lib/firebase';
import {
  GET_COMPANY_USERS,
  GET_COMPANY_ROLES,
  UPDATE_COMPANY_USER,
  DELETE_COMPANY_USER,
  GET_COMPANY_LINKED_USERS,
  UPDATE_COMPANY_LINKED_USER,
  DELETE_COMPANY_LINKED_USER,
} from '../index';

let rolesUnsubscribe;

export const getTeamUsers = (company) => (dispatch) => new Promise(async (resolve, reject) => {
    const usersRef = firestore.collection('users');
    const snapshot = await usersRef.where("company", "==", company).get();

    if (snapshot.docs) {
      const users = {};

      snapshot.docs.map(doc => {
        users[doc.id] = doc.data();
        return null;
      })

      dispatch({
          type: GET_COMPANY_USERS,
          payload: users,
      })

      return resolve('success');
    }
});

// *****************USERS**************************//
export const createUser = (email, password, name, phoneNumber, role, linkedApi, linkedApiName) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!email) return reject('no email');
  if (!password) return reject('no password');
  if (!name) return reject('no name');
  if (!role) return reject('no role');

  const createUserFunction = functions.httpsCallable('createUser');

  console.log('createUser', { email, password, name, phoneNumber, role, linkedApi, linkedApiName });

  createUserFunction({
    email,
    password,
    name,
    phoneNumber,
    role,
    // selectedLinkedAPI,
    linkedApi,
    linkedApiName,
    linkedCompanyUser: (linkedApi && linkedApiName) ? true : false,
  }).then((result) => {
    if (result && result.data && result.data.status === 'success' && result.data.data) {
      dispatch({
        type: UPDATE_COMPANY_USER,
        payload: result.data.data,
        key: result.data.data.uid,
        roleType: role,
      })

      return resolve(result);
    } else {
      if (result.data && result.data.error) {
        if (result.data.error.code === 'auth/email-already-exists') {
          return reject('email address already used');
        } else {
          return reject(result.data.error);
        }
      }
      return reject('error registering account');
    }
  }).catch((err) => {
    return reject(err);
  });
});

export const changeUserRole = (uid, role) => (dispatch) => new Promise(async (resolve, reject) => {

  if (!uid) return reject('no user');
  if (!role) return reject('no role');

  const changeUserRoleFunction = functions.httpsCallable('changeUserRole');

  changeUserRoleFunction({
    uid,
    role,
  }).then((result) => {
    if (result && result.data && result.data.status === 'success') {
      dispatch({
        type: UPDATE_COMPANY_USER,
        payload: { role: role },
        key: uid,
      })
    }

    return resolve(result);
  }).catch((err) => {
    return reject(err);
  });
});

export const deleteUser = (uid, isClient) => (dispatch) => new Promise(async (resolve, reject) => {

  if (!uid) return reject('no user');
  const deleteUserFunction = functions.httpsCallable('deleteUser');

  deleteUserFunction({
    uid: uid.toString(),
    isClient: isClient || false,
  }).then((result) => {
    if (result && result.data && result.data.status === 'success') {
      if (isClient) {
        dispatch({
          type: DELETE_COMPANY_USER,
          key: uid,
        })
      } else {
        dispatch({
          type: DELETE_COMPANY_USER,
          key: uid,
        })
      }

    }

    return resolve(result);
  }).catch((err) => {
    return reject(err);
  });
});

export const cancelListeners = () => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('logoutVerify test 2');
  if (rolesUnsubscribe) {
    rolesUnsubscribe();
  }

  return resolve();
});

export const getAllRoles = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
    const state = getState();

    const rolesRef = firestore.collection('companiesRoles');

    rolesUnsubscribe = rolesRef.onSnapshot(docSnapshot => {
      if (docSnapshot.docs) {
        const roles = {};

        docSnapshot.docs.map(doc => {
          roles[doc.id] = doc.data();
        });

        if (roles) {
          dispatch({
              type: GET_COMPANY_ROLES,
              payload: roles,
          })

          return resolve();
        } else {
          return resolve();
        }
      }
    }, err => {
      console.log(`Encountered error: ${err}`);
    });
});



export const getLinkedCompanyUsers = (company) => (dispatch) => new Promise(async (resolve, reject) => {
  const usersRef = firestore.collection('users');
  const snapshot = await usersRef.where("company", "==", company).where("role", "==", "linkedCompanyUser").get();

  if (snapshot.docs) {
    const users = {};

    snapshot.docs.map(doc => {
      users[doc.id] = doc.data();
      return null;
    })

    dispatch({
      type: GET_COMPANY_LINKED_USERS,
      payload: users,
    })

    return resolve('success');
  }
});

// *****************USERS**************************//
export const createLinkedCompanyUser = (companyId, email, password, name, companyName) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!companyId) return reject('no companyId');
  if (!email) return reject('no email');
  if (!password) return reject('no password');
  if (!name) return reject('no name');
  if (!companyName) return reject('no name');

  const createLinkedAPIAccountFunction = functions.httpsCallable('createLinkedAPIAccount');

  createLinkedAPIAccountFunction({
    email,
    password,
    name,
    companyName,
    role: 'linkedCompanyUser',
  }).then(async (result) => {
    console.log('result.data', result.data);

    if (result && result.data && result.data.status === 'success' && result.data.data) {

      const checkCompanyStatusFunction = functions.httpsCallable('checkCompanyStatus');

      await checkCompanyStatusFunction({ companyId });

      dispatch({
        type: UPDATE_COMPANY_LINKED_USER,
        payload: result.data.data,
        key: result.data.data.uid,
        roleType: 'linkedCompanyUser',
      });

      return resolve(result);
    } else {
      if (result.data && result.data.error) {
        if (result.data.error.code === 'auth/email-already-exists') {
          return reject('email address already used');
        } else {
          return reject(result.data.error);
        }
      }
      return reject('error registering account');
    }
  }).catch((err) => {
    return reject(err);
  });
});

export const deleteLinkedCompanyUser = (uid, isClient) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!uid) return reject('no user');
  const deleteUserFunction = functions.httpsCallable('deleteUser');

  deleteUserFunction({
    uid: uid.toString(),
    isClient: isClient || false,
  }).then((result) => {
    if (result && result.data && result.data.status === 'success') {
      if (isClient) {
        dispatch({
          type: DELETE_COMPANY_USER,
          key: uid,
        })
      } else {
        dispatch({
          type: DELETE_COMPANY_LINKED_USER,
          key: uid,
        })
      }

    }

    return resolve(result);
  }).catch((err) => {
    return reject(err);
  });
});