import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Media, Button, Input, Alert, Spinner } from 'reactstrap';

import { connect } from 'react-redux';

import { ChromePicker } from 'react-color';

import FileUploader from "react-firebase-file-uploader";

import {
  getBrandingData,
  updatePendingTabData,
  updateBrandingData,
} from '../../../../actions/contentManagement';

import { storage } from '../../../../lib/firebase';

import notifications from '../../../../const/notifications';

class BrandingTab extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    pendingTabData: PropTypes.shape(),
    fetchData: PropTypes.func.isRequired,
    updateBranding: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    dashboardTooltips: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      data: '',
    };
  }

  componentDidMount() {
    const { fetchData } = this.props;

    fetchData().then((res) => {
      if (res) {
        this.setState({ data: res });
      }
    });
  }

  handleChange = (e) => {
    e.preventDefault();

    if (e.target) {
      const { name, value } = e.target;
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          [name]: value,
        },
        error: null,
        success: null,
      }))
    }
  };

  updateData = () => {
    const { updateData, user } = this.props;
    const { data } = this.state;

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    updateData(data, adminName).then((res) => {
      if (res === 'success') {
        this.setState({ saveSpinner: null, success: true, error: null });
      } else if (res === 'error') {
        this.setState({ saveSpinner: null, success: null, error: true });
      }
    });
  }

  // Site logo upload
  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false, error });
    console.error(error);
  };

  handleUploadSuccess = (filename) => {
    const { updateBranding, user } = this.props;

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    this.setState({ saveSpinner: 'logo',progress: 100, isUploading: false });
    storage
      .ref(`siteImages`)
      .child(`logo.png`)
      .getDownloadURL()
      .then(url => updateBranding(url, 'logo', adminName).then(() => {
        this.setState({ saveSpinner: null });
      }));
  };

  // Favicon upload
  handleUploadStart2 = () => this.setState({ isUploading2: true, progress2: 0 });

  handleProgress2 = progress => this.setState({ progress2: progress });

  handleUploadError2 = error => {
    this.setState({ isUploading2: false, error });
    console.error(error);
  };

  handleUploadSuccess2 = (filename) => {
    const { updateBranding, user } = this.props;

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    this.setState({ saveSpinner: 'favicon', progress2: 100, isUploading2: false });
    storage
      .ref(`siteImages`)
      .child(`favicon.png`)
      .getDownloadURL()
      .then(url => updateBranding(url, 'favicon', adminName).then(() => {
        this.setState({ saveSpinner: null });
      }));
  };

  updateSiteName = () => {
    const { updateBranding, user } = this.props;
    const { data } = this.state;

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    this.setState({ saveSpinner: 'siteName' });

    if (data.siteName) {
      updateBranding(data.siteName, 'siteName', adminName).then(() => {
        this.setState({ saveSpinner: null });
      });
    }
  }

  updateColorTheme = () => {
    const { updateBranding, user } = this.props;
    const { data } = this.state;

    this.setState({ saveSpinner: 'colorTheme' });

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    if (data.colorTheme) {
      updateBranding(data.colorTheme, 'colorTheme', adminName).then(() => {
        this.setState({ saveSpinner: null });
      });
    }
  }

  updateTabInfo = () => {
    const { updateBranding, user } = this.props;
    const { data } = this.state;

    this.setState({ saveSpinner: 'tabInfo' });

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    if (data.tabInfo) {
      updateBranding(data.tabInfo, 'tabInfo', adminName).then(() => {
        this.setState({ saveSpinner: null });
      });
    }
  }

  toggleChangeColor = (type) => {
    this.setState({ changeColor: type });
  }

  handleChangeColor = (color, type) => {
    const { hex } = color;

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [type]: hex,
      },
      error: null,
      success: null,
    }))
  }

  updateColor = (type) => {
    const { updateBranding, user } = this.props;
    const { data } = this.state;

    this.setState({ saveSpinner: type });

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    if (data[type]) {
      updateBranding(data[type], type, adminName).then(() => {
        this.setState({ saveSpinner: null, changeColor: null })
      });
    }
  }

  render() {
    const { branding, showNoPermission } = this.props;
    const {
      error,
      success,
      data,
      changeColor,
      saveSpinner,
    } = this.state;

    return (
      <div>
        {(!!success) && <Alert color="success">Update successful</Alert>}
        {(!!error) && <Alert color="danger">Update failed, please try again</Alert>}
        <Row>
          <Col sm="4">
            Logo
          </Col>
          <Col sm="4">
            {(branding && branding.logo && branding.logo) ? (
              <Media style={{ maxHeight: 40 }} src={branding.logo} />
            ) : null}
          </Col>
          <Col sm="4">
            {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
            <FileUploader
              accept="image/*"
              name="image"
              filename="logo.png"
              storageRef={storage.ref("siteImages")}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.handleUploadSuccess}
              onProgress={this.handleProgress}
            />
          </Col>
        </Row>

        <Row>
          <Col sm="4">
            Platform Name
          </Col>
          <Col sm="5">
            <Input
              type="text"
              name="siteName"
              id={`siteName`}
              value={data.siteName}
              onChange={(e) => this.handleChange(e)}
            />
          </Col>
          <Col sm="3">
            <Button disabled={showNoPermission} onClick={this.updateSiteName}>
              Save
              {(saveSpinner === 'siteName') ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ):(null)}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col sm="4">
            favicon
          </Col>
          <Col sm="4">
            {(branding && branding.favicon && branding.favicon) ? (
              <Media style={{ maxHeight: 40 }} src={branding.favicon} />
            ) : null}
          </Col>
          <Col sm="4">
            {this.state.isUploading2 && <p>Progress: {this.state.progress2}</p>}
            <FileUploader
              accept="image/*"
              name="image"
              filename="favicon.png"
              storageRef={storage.ref("siteImages")}
              onUploadStart={this.handleUploadStart2}
              onUploadError={this.handleUploadError2}
              onUploadSuccess={this.handleUploadSuccess2}
              onProgress={this.handleProgress2}
            />
          </Col>
        </Row>

        <Row>
          <Col sm="4">
            Tab info
          </Col>
          <Col sm="5">
            <Input
              type="text"
              name="tabInfo"
              id={`tabInfo`}
              value={data.tabInfo}
              onChange={(e) => this.handleChange(e)}
            />
          </Col>
          <Col sm="3">
            <Button disabled={showNoPermission} onClick={this.updateTabInfo}>
              Save
              {(saveSpinner === 'tabInfo') ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ):(null)}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col sm="4">
            Sidebar color
          </Col>
          <Col sm="5">
            {(changeColor === 'sidebarPrimaryColor') ? (
              <ChromePicker color={ data.sidebarPrimaryColor || '#FFF' } onChange={ (color) => this.handleChangeColor(color, 'sidebarPrimaryColor') }/>
            ):(
              <div style={{ background: data.sidebarPrimaryColor || '#FFF' }} className='draggable-edit-color-button' onClick={() => this.toggleChangeColor('sidebarPrimaryColor')} />
            )}
          </Col>
          <Col sm="3">
            <Button disabled={showNoPermission} onClick={() => this.updateColor('sidebarPrimaryColor')}>
              Save
              {(saveSpinner === 'sidebarPrimaryColor') ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ):(null)}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col sm="4">
            Sidebar text color
          </Col>
          <Col sm="5">
            {(changeColor === 'sidebarTextColor') ? (
              <ChromePicker color={ data.sidebarTextColor || '#000' } onChange={ (color) => this.handleChangeColor(color, 'sidebarTextColor') }/>
            ):(
              <div style={{ background: data.sidebarTextColor || '#000' }} className='draggable-edit-color-button' onClick={() => this.toggleChangeColor('sidebarTextColor')} />
            )}
          </Col>
          <Col sm="3">
            <Button disabled={showNoPermission} onClick={() => this.updateColor('sidebarTextColor')}>
              Save
              {(saveSpinner === 'sidebarTextColor') ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ):(null)}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col sm="4">
            Content background color
          </Col>
          <Col sm="5">
            {(changeColor === 'contentBackgroundColor') ? (
              <ChromePicker color={ data.contentBackgroundColor || '#FFF' } onChange={ (color) => this.handleChangeColor(color, 'contentBackgroundColor') }/>
            ):(
              <div style={{ background: data.contentBackgroundColor || '#FFF' }} className='draggable-edit-color-button' onClick={() => this.toggleChangeColor('contentBackgroundColor')} />
            )}
          </Col>
          <Col sm="3">
            <Button disabled={showNoPermission} onClick={() => this.updateColor('contentBackgroundColor')}>
              Save
              {(saveSpinner === 'contentBackgroundColor') ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ):(null)}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col sm="4">
            Header background color
          </Col>
          <Col sm="5">
            {(changeColor === 'headerBackgroundColor') ? (
              <ChromePicker color={ data.headerBackgroundColor || '#FFF' } onChange={ (color) => this.handleChangeColor(color, 'headerBackgroundColor') }/>
            ):(
              <div style={{ background: data.headerBackgroundColor || '#FFF' }} className='draggable-edit-color-button' onClick={() => this.toggleChangeColor('headerBackgroundColor')} />
            )}
          </Col>
          <Col sm="3">
            <Button disabled={showNoPermission} onClick={() => this.updateColor('headerBackgroundColor')}>
              Save
              {(saveSpinner === 'headerBackgroundColor') ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ):(null)}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col sm="4">
            Perks tab color
          </Col>
          <Col sm="5">
            {(changeColor === 'perksBackgroundColor') ? (
              <ChromePicker color={data.perksBackgroundColor || '#FFF'} onChange={(color) => this.handleChangeColor(color, 'perksBackgroundColor')} />
            ) : (
                <div style={{ background: data.perksBackgroundColor || '#FFF' }} className='draggable-edit-color-button' onClick={() => this.toggleChangeColor('perksBackgroundColor')} />
            )}
          </Col>
          <Col sm="3">
            <Button disabled={showNoPermission} onClick={() => this.updateColor('perksBackgroundColor')}>
              Save
              {(saveSpinner === 'perksBackgroundColor') ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ) : (null)}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col sm="4">
            Perks text color
          </Col>
          <Col sm="5">
            {(changeColor === 'perksTextColor') ? (
              <ChromePicker color={data.perksTextColor || '#FFF'} onChange={(color) => this.handleChangeColor(color, 'perksTextColor')} />
            ) : (
                <div style={{ background: data.perksTextColor || '#FFF' }} className='draggable-edit-color-button' onClick={() => this.toggleChangeColor('perksTextColor')} />
            )}
          </Col>
          <Col sm="3">
            <Button disabled={showNoPermission} onClick={() => this.updateColor('perksTextColor')}>
              Save
              {(saveSpinner === 'perksTextColor') ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ) : (null)}
            </Button>
          </Col>
        </Row>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  branding: state.contentManagement.branding || {},
  siteLogo: state.contentManagement.branding ? state.contentManagement.branding.logo : '',
  favicon: state.contentManagement.branding ? state.contentManagement.branding.favicon : '',
});

const mapDispatchToProps = {
  fetchData: getBrandingData,
  updateData: updatePendingTabData,
  updateBranding: updateBrandingData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BrandingTab));
